import type { TableIdentifier } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { IntlShape } from "react-intl"

export const PRIMARY_COLUMN_SIZE = 240

export const XL_COLUMN_SIZE = 320
export const TOOL_NAME_COLUMN_SIZE = XL_COLUMN_SIZE
export const VENDOR_NAME_COLUMN_SIZE = XL_COLUMN_SIZE

export const LG_COLUMN_SIZE = 240

export const MD_COLUMN_SIZE = 200
export const USER_PERSON_COLUMN_SIZE = MD_COLUMN_SIZE

export const SM_COLUMN_SIZE = 160

export const DATE_COLUMN_SIZE = 136
export const XS_COLUMN_SIZE = 112
export const MONEY_COLUMN_SIZE = SM_COLUMN_SIZE
export const STATUS_COLUMN_SIZE = XS_COLUMN_SIZE
export const TYPE_COLUMN_SIZE = XS_COLUMN_SIZE
export const SIMPLE_COUNT_COLUMN_SIZE = XS_COLUMN_SIZE

export const ACTIONS_COLUMN_SIZE = 80

export const displayNameForTableId = (tableId: TableIdentifier, intl: IntlShape): string => {
  switch (tableId) {
    case "tools":
      return intl.formatMessage({
        id: "table.header.tools",
        description: "Table header for tools table",
        defaultMessage: "Tools",
      })
    case "persons":
      return intl.formatMessage({
        defaultMessage: "People",
        description: "Table header for persons table",
        id: "table.header.persons",
      })
    case "vendors":
      return intl.formatMessage({
        defaultMessage: "Vendors",
        description: "Table header for vendors table",
        id: "table.header.vendors",
      })
    case "tool-logins":
      return intl.formatMessage({
        defaultMessage: "Activity",
        description: "Table header for tool logins table",
        id: "table.header.tool-logins",
      })
    case "tool-users":
      return intl.formatMessage({
        defaultMessage: "Users",
        description: "Table header for tool users table",
        id: "table.header.tool-users",
      })
    case "legal-agreements":
      return intl.formatMessage({
        defaultMessage: "Agreements",
        description: "Table header for legal agreements table",
        id: "table.header.legal-agreements-table",
      })
    case "workflow-runs":
      return intl.formatMessage({
        defaultMessage: "Requests",
        description: "Table header for requests table",
        id: "table.header.requests",
      })
    case "transactions":
      return intl.formatMessage({
        defaultMessage: "Transactions",
        description: "Table header for transactions table",
        id: "table.header.transactions",
      })
    case "credit-cards":
      return intl.formatMessage({
        defaultMessage: "Credit Cards",
        description: "Table header for credit cards table",
        id: "table.header.credit-cards",
      })
    default:
      unreachable(tableId)
  }
}
