import type { LegalAgreementListItem } from "@brm/schema-types/types.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { Box, Button, Flex, Grid, GridItem, IconButton, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { Link } from "../../components/Link.js"
import { VendorLogo } from "../../components/icons/Logo.js"
import { ChevronLeftIcon } from "../../components/icons/icons.js"
import { pathForLegalAgreement } from "../../util/json-schema.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import StartWorkflowModal from "../workflows/run/start/StartWorkflowModal.js"

export default function AgreementCalendarDisplay({
  agreement,
  onClickBack,
}: {
  agreement: LegalAgreementListItem
  onClickBack?: () => void
}) {
  const intl = useIntl()
  const location = useLocation()
  const startWorkflowDisclosure = useDisclosure()

  const endDate = agreement.end_date ? Temporal.PlainDate.from(agreement.end_date) : null
  const decisionDate = agreement.decision_date ? Temporal.PlainDate.from(agreement.decision_date) : null

  const optOutPeriod = agreement.auto_renewal_opt_out_period || "P0D"

  let optOutDate = null

  if (decisionDate && endDate && agreement.auto_renews) {
    const optOutDuration = Temporal.Duration.from(optOutPeriod)
    optOutDate = endDate.subtract(optOutDuration)
  }

  return (
    <>
      <VStack align="stretch" spacing={4}>
        <Flex alignItems="center" gap={2}>
          {onClickBack && (
            <IconButton
              variant="ghost"
              size="sm"
              onClick={onClickBack}
              icon={<ChevronLeftIcon />}
              autoFocus
              aria-label={intl.formatMessage({
                id: "renewalCalendar.backButton",
                defaultMessage: "Back",
                description: "Aria label for the back button in the agreement calendar display",
              })}
            />
          )}
          <Box>
            <VendorLogo boxSize={8} logo={getPublicImageGcsUrl(agreement.vendor?.image_asset?.gcs_file_name)} />
          </Box>
          <Text fontWeight="bold" isTruncated>
            <FormattedMessage
              id="renewalCalendar.agreementName"
              defaultMessage="{agreementName}"
              description="Displays the full name of the agreement in the popover"
              values={{ agreementName: agreement.display_name }}
            />
          </Text>
        </Flex>
        <Grid templateColumns="1fr 1fr" gap={2} alignItems="start">
          {agreement.auto_renews && (
            <>
              <GridItem>
                <FormattedMessage
                  id="renewalCalendar.autoRenewsLabel"
                  defaultMessage="Auto Renews:"
                  description="Label for auto-renewal status"
                />
              </GridItem>
              <GridItem>
                <FormattedMessage
                  id="renewalCalendar.autoRenews"
                  defaultMessage="Yes"
                  description="Indicates that the agreement automatically renews"
                />
              </GridItem>
            </>
          )}
          {agreement.auto_renews && optOutDate && (
            <>
              <GridItem>
                <FormattedMessage
                  id="renewalCalendar.optOutByLabel"
                  defaultMessage="Opt out by:"
                  description="Label for opt-out date"
                />
              </GridItem>
              <GridItem>{optOutDate.toLocaleString()}</GridItem>
            </>
          )}
          {!agreement.auto_renews && endDate && (
            <>
              <GridItem>
                <FormattedMessage
                  id="renewalCalendar.expiresLabel"
                  defaultMessage="Expires:"
                  description="Label for expiration date"
                />
              </GridItem>
              <GridItem>{endDate.toLocaleString()}</GridItem>
            </>
          )}
          {decisionDate && (
            <>
              <GridItem>
                <FormattedMessage
                  id="renewalCalendar.decisionDateLabel"
                  defaultMessage="Decision due:"
                  description="Label for decision due date"
                />
              </GridItem>
              <GridItem>{decisionDate.toLocaleString()}</GridItem>
            </>
          )}
          {agreement.total_contract_value && (
            <>
              <GridItem>
                <FormattedMessage
                  id="renewalCalendar.agreementValueLabel"
                  defaultMessage="Total Value:"
                  description="Label for total contract value"
                />
              </GridItem>
              <GridItem>{formatCurrency(agreement.total_contract_value, intl)}</GridItem>
            </>
          )}
          <GridItem mt={2} mr={4}>
            <Link
              to={{
                pathname: pathForLegalAgreement(agreement.id),
              }}
              state={{ prevLocation: location }}
              color="brand.700"
              fontWeight="semibold"
              fontSize="sm"
            >
              <FormattedMessage
                id="renewalCalendar.viewAgreement"
                defaultMessage="View Agreement"
                description="Link text to view the full agreement details"
              />
            </Link>
          </GridItem>
          {agreement.tools && agreement.vendor && (
            <GridItem mt={2}>
              <Button variant="link" onClick={startWorkflowDisclosure.onOpen}>
                <Text color="brand.700" fontWeight="semibold" fontSize="sm" whiteSpace="nowrap">
                  <FormattedMessage
                    id="agreement.startRenewal"
                    description="Text on a link to start a renewal request of a legal agreement"
                    defaultMessage="Start renewal"
                  />
                </Text>
              </Button>
            </GridItem>
          )}
        </Grid>
      </VStack>
      {startWorkflowDisclosure.isOpen && (
        <StartWorkflowModal {...startWorkflowDisclosure} initialLegalAgreementId={agreement.id} />
      )}
    </>
  )
}
