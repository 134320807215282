import type { ImageProps } from "@chakra-ui/react"
import { Image } from "@chakra-ui/react"
import { iconTheme } from "../../../packages/theme/src/components/icon.js"
import braimLogo from "../../assets/braim.svg"

/**
 * This component is called Icon instead of logo because it is intended to be used like our other Icon components.
 * It shares the same default heigh as our icons which is set to 1lh to get an inline logo effect.
 */
export default function BraimIcon(props: Omit<ImageProps, "src">) {
  return <Image src={braimLogo} opacity={0.85} alt="Braim AI Logo" {...iconTheme.baseStyle} {...props} />
}
