import type {
  DisplayableIntegrationType,
  IntegrationProvider,
  MergeIntegrationAccountingType,
  MergeIntegrationHRISType,
} from "@brm/schema-types/types.js"
import type { ComponentWithAs, IconProps } from "@chakra-ui/react"
import {
  ADPWorkforceNowIcon,
  BambooHRIcon,
  BrexIcon,
  ChartHopIcon,
  DeelIcon,
  GmailIcon,
  GoogleIcon,
  GustoIcon,
  HiBobIcon,
  JustworksIcon,
  MercuryIcon,
  MergeIcon,
  NamelyIcon,
  NetsuiteIcon,
  OktaIcon,
  PaylocityIcon,
  PlaidIcon,
  QuickbooksIcon,
  RampIcon,
  RipplingIcon,
  SageIntacctIcon,
  SlackIcon,
  UKGIcon,
  WorkdayIcon,
  ZenefitsIcon,
} from "./provider-icons.js"

// One day we will migrate integration table to have the ProviderType and the AuthType as separate columns.
export const displayableIntegrationTypeIcons: Record<DisplayableIntegrationType, ComponentWithAs<"svg", IconProps>> = {
  google: GoogleIcon,
  okta: OktaIcon,
  slack: SlackIcon,
  brex: BrexIcon,
  ramp: RampIcon,
  quickbooks: QuickbooksIcon,
  bamboohr: BambooHRIcon,
  justworks: JustworksIcon,
  gusto: GustoIcon,
  rippling: RipplingIcon,
  hibob: HiBobIcon,
  workday: WorkdayIcon,
  paylocity: PaylocityIcon,
  deel: DeelIcon,
  namely: NamelyIcon,
  zenefits: ZenefitsIcon,
  netsuite: NetsuiteIcon,
  // The hris type enum must match with Merge's hris type enum
  // https://docs.merge.dev/guides/merge-link/single-integration/
  "sage-intacct": SageIntacctIcon,
  "quickbooks-online": QuickbooksIcon,
  "adp-workforce-now": ADPWorkforceNowIcon,
  "ukg-pro": UKGIcon,
  "ukg-ready": UKGIcon,
  "ukg-pro-workforce-management": UKGIcon,
  charthop: ChartHopIcon,
}

// TODO remove this map once we migrate integration's type to separate ProviderType and AuthType columns
export const providerIcons: Record<
  IntegrationProvider | MergeIntegrationHRISType | MergeIntegrationAccountingType,
  ComponentWithAs<"svg", IconProps>
> = {
  brex_apikey: BrexIcon,
  brex_oauth: BrexIcon,
  google_oauth: GoogleIcon,
  gmail_oauth: GmailIcon,
  mercury_apikey: MercuryIcon,
  okta_apikey: OktaIcon,
  okta_oauth: OktaIcon,
  plaid_access_token: PlaidIcon,
  quickbooks_oauth: QuickbooksIcon,
  slack_oauth: SlackIcon,
  ramp_client_creds: RampIcon,
  ramp_oauth: RampIcon,
  merge_hris_link_token: MergeIcon,
  merge_accounting_link_token: MergeIcon,
  bamboohr: BambooHRIcon,
  justworks: JustworksIcon,
  gusto: GustoIcon,
  rippling: RipplingIcon,
  hibob: HiBobIcon,
  workday: WorkdayIcon,
  paylocity: PaylocityIcon,
  deel: DeelIcon,
  namely: NamelyIcon,
  zenefits: ZenefitsIcon,
  netsuite: NetsuiteIcon,
  // The hris type enum must match with Merge's hris type enum
  // https://docs.merge.dev/guides/merge-link/single-integration/
  "sage-intacct": SageIntacctIcon,
  "quickbooks-online": QuickbooksIcon,
  "adp-workforce-now": ADPWorkforceNowIcon,
  "ukg-pro": UKGIcon,
  "ukg-ready": UKGIcon,
  "ukg-pro-workforce-management": UKGIcon,
  charthop: ChartHopIcon,
}
