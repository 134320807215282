import { colors } from "@brm/theme"
// eslint-disable-next-line no-restricted-imports
import { createTheme } from "@mantine/core"

export const mantineTheme = createTheme({
  colors: {
    brand: [
      // Mantine colors need 10 values
      colors.brand[50],
      "#D8F5F3",
      colors.brand[100],
      colors.brand[200],
      colors.brand[300],
      colors.brand[400],
      colors.brand[500],
      colors.brand[600],
      colors.brand[700],
      "#1C5956",
    ],
  },
})
