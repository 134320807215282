import type { CreditCardMinimal } from "@brm/schema-types/types.js"
import { CreditCardIntervalSchema } from "@brm/schemas"
import { getEnumMemberTitle } from "@brm/util/schema.js"
import { Box, Button, HStack, Icon, IconButton, Spinner, Stack, Text, Tooltip } from "@chakra-ui/react"
import type React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { FormattedCurrency } from "../../../components/FormattedCurrency.js"
import { IconButtonWithTooltip } from "../../../components/IconButtonWithTooltip.js"
import { CheckIcon, CreditCardIcon, PlusIcon, XIcon } from "../../../components/icons/icons.js"
import OverflownText from "../../../components/OverflownText.js"

interface IssueCreditCardProps {
  creditCard?: CreditCardMinimal
  isReadOnly: boolean
  onCreate: () => void
  onDelete: () => void
}

export const IssueCreditCard: React.FC<IssueCreditCardProps> = ({ creditCard, onCreate, onDelete, isReadOnly }) => {
  const intl = useIntl()
  if (!creditCard && !isReadOnly) {
    return (
      <Tooltip
        shouldWrapChildren
        label={intl.formatMessage({
          id: "workflowRunStepHeader.createRampCreditCard.tooltip",
          defaultMessage: "Issue a credit card upon finance step approval",
          description: "Tooltip text for the button to create a Ramp credit card in the workflow run step header",
        })}
      >
        <Button onClick={onCreate} size="sm" variant="outline" colorScheme="gray" leftIcon={<Icon as={PlusIcon} />}>
          {intl.formatMessage({
            id: "workflowRunStepHeader.createRampCreditCard",
            defaultMessage: "Issue Credit Card",
            description: "Button text to create a Ramp credit card in the workflow run step header",
          })}
        </Button>
      </Tooltip>
    )
  }

  if (!creditCard) {
    return null
  }

  return (
    <Stack spacing={2}>
      <Text fontSize="sm">
        <FormattedMessage
          id="workflowRunStepHeader.creditCardDescription"
          defaultMessage="This credit card will be issued upon finance step approval."
          description="Description of when the credit card will be issued in the workflow run step header"
        />
      </Text>
      <Stack
        justifyContent="start"
        alignItems="flex-start"
        padding={2}
        gap={1}
        borderWidth={1}
        borderRadius="md"
        borderColor="gray.200"
        paddingBottom={3}
        paddingX={4}
        maxW="250px"
      >
        {!isReadOnly && creditCard.card_task_status !== "terminating" && creditCard.card_task_status !== "creating" && (
          <Box position="relative" width="100%">
            <IconButtonWithTooltip
              onClick={onDelete}
              position="absolute"
              size="sm"
              top={-1}
              right={-3}
              variant="ghost"
              icon={<Icon as={XIcon} />}
              label={
                creditCard.card_task_status === "success"
                  ? intl.formatMessage({
                      id: "workflowRunStepHeader.creditCardDetails",
                      defaultMessage: "Terminate credit card",
                      description:
                        "Label for the button to cancel the credit card termination in the workflow run step header",
                    })
                  : intl.formatMessage({
                      id: "workflowRunStepHeader.creditCardDetails",
                      defaultMessage: "Cancel credit card issuance",
                      description:
                        "Label for the button to cancel the credit card issuance in the workflow run step header",
                    })
              }
            />
          </Box>
        )}
        <HStack width="90%">
          <Icon as={CreditCardIcon} boxSize={5} />
          <OverflownText fontSize="sm" fontWeight="medium">
            {creditCard?.display_name ?? "Credit Card"}
          </OverflownText>
          {creditCard.card_task_status === "creating" || creditCard.card_task_status === "terminating" ? (
            <Tooltip
              label={intl.formatMessage(
                {
                  id: "workflowRunStepHeader.creditCardProcessing",
                  defaultMessage: "{status} credit card",
                  description: "Tooltip for the spinner indicating credit card is being processed",
                },
                {
                  status:
                    creditCard.card_task_status === "creating"
                      ? intl.formatMessage({
                          id: "workflowRunStepHeader.creditCardIssuing",
                          defaultMessage: "Issuing",
                          description: "Status of the credit card issuance",
                        })
                      : intl.formatMessage({
                          id: "workflowRunStepHeader.creditCardTerminating",
                          defaultMessage: "Terminating",
                          description: "Status of the credit card termination",
                        }),
                }
              )}
            >
              <Spinner size="sm" color={creditCard.card_task_status === "creating" ? "green" : "error"} />
            </Tooltip>
          ) : creditCard.card_task_status === "success" ? (
            <Tooltip
              label={intl.formatMessage({
                id: "workflowRunStepHeader.creditCardIssued",
                defaultMessage: "Card issued. See details",
                description: "Tooltip for the check icon indicating successful credit card issuance",
              })}
            >
              <IconButton
                icon={<Icon as={CheckIcon} boxSize={4} />}
                aria-label={intl.formatMessage({
                  id: "workflowRunStepHeader.creditCardIssuedAriaLabel",
                  defaultMessage: "Credit card issued",
                  description: "Aria label for the check icon button indicating successful credit card issuance",
                })}
                size="sm"
                colorScheme="green"
                variant="ghost"
                onClick={() => {
                  window.open("https://app.ramp.com/cards/virtual-cards", "_blank")
                }}
              />
            </Tooltip>
          ) : null}
        </HStack>
        {creditCard.spend_limit && creditCard.spend_limit_interval && (
          <Text fontSize="sm">
            <FormattedMessage
              id="workflowRunStepHeader.creditCardSpendLimit"
              defaultMessage="Spend limit: {spendLimit}/{spendInterval}"
              description="Spend limit information for the assigned credit card in the workflow run step header"
              values={{
                spendLimit: <FormattedCurrency currencyAmount={creditCard.spend_limit} />,
                spendInterval: getEnumMemberTitle(creditCard.spend_limit_interval, CreditCardIntervalSchema),
              }}
            />
          </Text>
        )}
      </Stack>
    </Stack>
  )
}
