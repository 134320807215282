import type { FlexboxProps, LayoutProps, SpaceProps } from "@chakra-ui/react"
import { Circle, Icon, Image } from "@chakra-ui/react"
import { useState, type FunctionComponent } from "react"
import { AgreementIcon, LoginIcon, RequestIcon, ToolIcon, VendorIcon } from "./icons.js"

export interface LogoProps extends LayoutProps, SpaceProps, FlexboxProps {
  logo?: string | null
}

export const Logo: FunctionComponent<
  LogoProps & {
    fallbackIcon?: React.ComponentType
    fallbackIconColor?: string
    fallbackIconBackgroundColor?: string
  }
> = ({ logo, boxSize = 6, fallbackIcon, fallbackIconColor, fallbackIconBackgroundColor, ...props }) => {
  const [error, setError] = useState<boolean>(false)
  return logo && !error ? (
    <Image
      src={logo}
      boxSize={boxSize}
      objectFit="contain"
      borderRadius="100%"
      onError={() => setError(true)}
      minWidth={boxSize}
      {...props}
    />
  ) : (
    <Circle bgColor={fallbackIconBackgroundColor ?? "gray.200"} size={boxSize} {...props}>
      <Icon color={fallbackIconColor ?? "gray.400"} as={fallbackIcon} width="70%" height="70%" />
    </Circle>
  )
}

/**
 * Renders the logo of a tool, or a consistent fallback icon if no logo is available.
 */
export const ToolLogo: FunctionComponent<LogoProps> = (props) => <Logo {...props} fallbackIcon={ToolIcon} />

/**
 * Renders the logo of a vendor, or a consistent fallback icon if no logo is available.
 */
export const VendorLogo: FunctionComponent<LogoProps> = (props) => <Logo {...props} fallbackIcon={VendorIcon} />

/**
 * Renders the logo of a request, or a consistent fallback icon if no logo is available.
 */
export const RequestLogo: FunctionComponent<LogoProps> = (props) => <Logo {...props} fallbackIcon={RequestIcon} />

/**
 * Renders the logo of a login, or a consistent fallback icon if no logo is available.
 */
export const LoginLogo: FunctionComponent<LogoProps> = (props) => <Logo {...props} fallbackIcon={LoginIcon} />

/**
 * Renders the logo of a login, or a consistent fallback icon if no logo is available.
 */
export const LegalAgreementLogo: FunctionComponent<LogoProps> = (props) => (
  <Logo {...props} fallbackIcon={AgreementIcon} />
)
