import { hasPermission } from "@brm/schema-helpers/role.js"
import { Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { NavLink, Outlet } from "react-router-dom"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import { NavButton } from "../../components/Nav/NavButton.js"
import {
  CriteriaIcon,
  IntegrationIcon,
  MailIcon,
  NotificationsDisabledIcon,
  ProfileSettingsIcon,
  RequestSettingsIcon,
  SecurityIcon,
  UsersIcon,
} from "../../components/icons/icons.js"

export default function Settings() {
  const intl = useIntl()

  const { data: whoami } = useGetUserV1WhoamiQuery()

  return (
    <Flex as="section" minHeight="0">
      <Flex
        position="sticky"
        top={0}
        height="100vh"
        width="250px"
        bg="bg-surface"
        boxShadow={useColorModeValue("sm", "sm-dark")}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
      >
        <Stack justify="space-between" spacing="1" width="full">
          <Stack spacing="8" shouldWrapChildren>
            <Stack>
              <Text color="subtle" fontWeight="medium">
                <FormattedMessage
                  id="settings.menu.group.my-account"
                  description="Settings menu grouping label for tabs related to the user`s account"
                  defaultMessage="My Account"
                />
              </Text>
              <Stack spacing="1">
                <NavButton
                  as={NavLink}
                  label={intl.formatMessage({
                    id: "settings.menu.profile",
                    description: "Settings menu link to view user profile settings",
                    defaultMessage: "Profile",
                  })}
                  icon={ProfileSettingsIcon}
                  to="/settings/profile"
                />
                <NavButton
                  as={NavLink}
                  label={intl.formatMessage({
                    id: "settings.menu.contractCollector",
                    description: "Settings menu link to view contract collector settings",
                    defaultMessage: "Contract Collector",
                  })}
                  icon={MailIcon}
                  to="/settings/contract-collector"
                />
                <NavButton
                  as={NavLink}
                  label={intl.formatMessage({
                    id: "settings.menu.notifications",
                    description: "Settings menu link to view notification settings",
                    defaultMessage: "Notifications",
                  })}
                  icon={NotificationsDisabledIcon}
                  to="/settings/notification"
                />
              </Stack>
            </Stack>
            <Stack>
              <Text color="subtle" fontWeight="medium">
                <FormattedMessage
                  id="settings.menu.group.organization"
                  description="Settings menu grouping label for organization pages"
                  defaultMessage="Organization"
                />
              </Text>
              <Stack spacing="1">
                <NavButton
                  as={NavLink}
                  label={intl.formatMessage({
                    id: "settings.menu.users",
                    description: "Settings menu link to view users",
                    defaultMessage: "Users",
                  })}
                  icon={UsersIcon}
                  to="/settings/users"
                />
                {hasPermission(whoami?.roles, "organization:update") && (
                  <NavButton
                    as={NavLink}
                    label={intl.formatMessage({
                      id: "settings.menu.security",
                      description: "Settings menu link to view security settings",
                      defaultMessage: "Security",
                    })}
                    icon={SecurityIcon}
                    to="/settings/security"
                  />
                )}
                {hasPermission(whoami?.roles, "field_config:read") && (
                  <NavButton
                    as={NavLink}
                    label={intl.formatMessage({
                      id: "settings.menu.criteria",
                      description: "Settings menu link to view criteria",
                      defaultMessage: "Criteria",
                    })}
                    icon={CriteriaIcon}
                    to="/settings/criteria"
                  />
                )}
                {hasPermission(whoami?.roles, "integration:read") && (
                  <NavButton
                    as={NavLink}
                    label={intl.formatMessage({
                      id: "settings.menu.integrations",
                      description: "Settings menu link to view integrations",
                      defaultMessage: "Integrations",
                    })}
                    icon={IntegrationIcon}
                    to="/settings/integrations"
                  />
                )}
                {hasPermission(whoami?.roles, "workflow_def:read") && (
                  <NavButton
                    as={NavLink}
                    label={intl.formatMessage({
                      id: "settings.menu.requests",
                      description: "Settings menu link to view requests",
                      defaultMessage: "Requests",
                    })}
                    icon={RequestSettingsIcon}
                    to="/settings/requests"
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Flex>
      <Outlet />
    </Flex>
  )
}
