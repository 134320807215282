import type { PersonStatus } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Badge, Flex, Icon, Text } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { EmptySymbol } from "../../components/EmptySymbol.js"
import { CircleCheckIcon, CircleIcon, DotsHorizontalIcon } from "../../components/icons/icons.js"
import { displayPersonStatus } from "./util.js"

interface Props {
  status: PersonStatus | null
}

export default function PersonStatusBadge({ status }: Props) {
  const intl = useIntl()

  switch (status) {
    case "active_user":
    case "active_employee":
      return (
        <Badge colorScheme="green" textTransform="capitalize">
          <Flex gap={1} alignItems="center">
            <Icon as={CircleCheckIcon} />
            <Text>{displayPersonStatus(status, intl)}</Text>
          </Flex>
        </Badge>
      )
    case "inactive_user":
    case "inactive_employee":
      return (
        <Badge colorScheme="gray" textTransform="capitalize">
          <Flex gap={1} alignItems="center">
            <Icon as={CircleIcon} />
            <Text>{displayPersonStatus(status, intl)}</Text>
          </Flex>
        </Badge>
      )
    case "pending_user":
    case "pending_employee":
      return (
        <Badge colorScheme="yellow" textTransform="capitalize">
          <Flex gap={1} alignItems="center">
            <Icon as={DotsHorizontalIcon} />
            <Text>{displayPersonStatus(status, intl)}</Text>
          </Flex>
        </Badge>
      )
    case null:
      return <EmptySymbol />
    default:
      return unreachable(status)
  }
}
