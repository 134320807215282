import type { WorkflowDef } from "@brm/schema-types/types.js"
import { formatDate } from "@brm/util/format-date-time.js"
import { displayPersonName } from "@brm/util/names.js"
import { Button, Divider, Flex, HStack, Icon, Stack, Text } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetWorkflowV1DefinitionsQuery } from "../../../app/services/generated-api.js"
import CardWithHoverActions from "../../../components/CardWithHoverActions.js"
import { NextIcon, PlusIcon } from "../../../components/icons/icons.js"
import { Link } from "../../../components/Link.js"
import { SettingsContainer } from "../../settings/SettingsContainer.js"
import { SettingsHeader } from "../../settings/SettingsHeader.js"

export default function WorkflowDefinitionList() {
  const { data: workflowDefinitions } = useGetWorkflowV1DefinitionsQuery()
  if (!workflowDefinitions) {
    return null
  }

  const defsByKind = Object.groupBy(workflowDefinitions, (def) => def.kind)

  return (
    <SettingsContainer>
      <SettingsHeader>
        <FormattedMessage
          defaultMessage="Requests"
          description="Requests configuration page heading"
          id="requests.definitions.heading"
        />
      </SettingsHeader>
      <Divider />
      <Stack mt={4} gap={5}>
        <Stack alignItems="flex-start">
          {defsByKind.purchase ? (
            <Stack>
              {defsByKind.purchase.map((def) => (
                <WorkflowDefinitionCard key={def.id} workflowDef={def} />
              ))}
            </Stack>
          ) : (
            <Button
              as={Link}
              to="/requests/definitions/create/purchase"
              variant="ghost"
              leftIcon={<Icon as={PlusIcon} />}
            >
              <FormattedMessage
                defaultMessage="New Purchase Request"
                description="Requests configuration page button to create a new purchase request"
                id="requests.definitions.button.newPurchaseRequest"
              />
            </Button>
          )}
        </Stack>
        <Stack alignItems="flex-start">
          {defsByKind.renewal ? (
            <Stack>
              {defsByKind.renewal.map((def) => (
                <WorkflowDefinitionCard key={def.id} workflowDef={def} />
              ))}
            </Stack>
          ) : (
            <Button
              as={Link}
              to="/requests/definitions/create/renewal"
              variant="ghost"
              leftIcon={<Icon as={PlusIcon} />}
            >
              <FormattedMessage
                defaultMessage="New Renewal Request"
                description="Requests configuration page button to create a new renewal request"
                id="requests.definitions.button.newRenewalRequest"
              />
            </Button>
          )}
        </Stack>
      </Stack>
    </SettingsContainer>
  )
}

function WorkflowDefinitionCard({ workflowDef }: { workflowDef: WorkflowDef }) {
  const intl = useIntl()
  return (
    <Link to={`/requests/definitions/${workflowDef.id}`} variant="noUnderline">
      <CardWithHoverActions cardBodyProps={{ px: 3 }}>
        <HStack justifyContent="space-between" width={645}>
          <HStack gap={4}>
            <Flex flexDirection="column" textDecoration="none">
              <Text fontWeight="semibold">
                {workflowDef.kind === "purchase" ? (
                  <FormattedMessage
                    id="requests.definition.purchase.displayName"
                    description="Title on workflow definition card for purchase requests"
                    defaultMessage="Purchase Request"
                  />
                ) : (
                  <FormattedMessage
                    id="requests.definition.renewal.displayName"
                    description="Title on workflow definition card for renewal requests"
                    defaultMessage="Renewal Request"
                  />
                )}
              </Text>
              <Text>
                <FormattedMessage
                  id="requests.definition.lastUpdatedBy"
                  description="Text on the workflow definition display card that describes when this definition was last updated and by who"
                  defaultMessage="Last updated by {user} on {date}"
                  values={{
                    user: displayPersonName(workflowDef.last_updated_by, intl),
                    date: formatDate(intl, workflowDef.updated_at),
                  }}
                />
              </Text>
            </Flex>
          </HStack>
          <Icon as={NextIcon} />
        </HStack>
      </CardWithHoverActions>
    </Link>
  )
}
