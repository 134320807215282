import type { Tool } from "@brm/schema-types/types.js"
import { Text, type SystemStyleObject } from "@chakra-ui/react"
import { memo } from "react"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import { Link } from "../../Link.js"
import { ToolLogo } from "../../icons/Logo.js"

interface Props {
  tool: Pick<Tool, "id" | "display_name" | "image_asset">
  textContentStyle?: SystemStyleObject
}

export default memo(
  function ToolCell({ tool, textContentStyle }: Props) {
    const toolLogo = getPublicImageGcsUrl(tool.image_asset?.gcs_file_name)
    return (
      <Link
        to={`/tools/${tool.id}`}
        flexShrink={1}
        height="100%"
        display="flex"
        alignItems="center"
        maxWidth="100%"
        gap={2}
      >
        <ToolLogo logo={toolLogo} />
        <Text as="span" fontWeight="medium" maxW="100%" isTruncated sx={textContentStyle}>
          {tool.display_name}
        </Text>
      </Link>
    )
  },
  (prevProps, nextProps) =>
    prevProps.tool.id === nextProps.tool.id &&
    prevProps.tool.display_name === nextProps.tool.display_name &&
    prevProps.tool.image_asset?.gcs_file_name === nextProps.tool.image_asset?.gcs_file_name
)
