import { hasPermission } from "@brm/schema-helpers/role.js"
import { getCurrentApprovalStep } from "@brm/schema-helpers/workflow.js"
import { displayPersonName } from "@brm/util/names.js"
import { chakra, Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { useCallback, useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { SetRequired } from "type-fest"
import { useHover } from "usehooks-ts"
import {
  useGetUserV1WhoamiQuery,
  usePutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalMutation,
} from "../../app/services/generated-api.js"
import { canApproveWorkflowRunStep } from "../../features/workflows/run/utils.js"
import { log } from "../../util/logger.js"
import { CheckCircleIcon, CheckIcon, RefreshIcon } from "../icons/icons.js"
import { RelativeDateTimeDisplay } from "../Timestamp.js"
import FieldApprovalConfirmationModal from "./FieldApprovalConfirmationModal.js"
import type { InputContainerProps } from "./InputContainer.js"
import StepApprovalConfirmationModal from "./StepApprovalConfirmationModal.js"
import type { DynamicFormFieldApproval } from "./types.js"

/**
 * Renders a checkmark that can be clicked to approve a field if viewing user is the approver of this field/step
 * If the field is already approved, renders a button that allows the user to change the approval status and unlock
 * the field. If the user is not the approver, a confirmation modal will be shown when the button is clicked
 */
export default function FieldApprovalButton({
  formField,
  objectId,
  workflowRunStep,
  fieldApproval,
  label,
  fieldFilledOut,
  workflowRun,
}: SetRequired<
  Pick<
    InputContainerProps,
    "formField" | "objectId" | "workflowRunStep" | "workflowRun" | "fieldApproval" | "label" | "fieldFilledOut"
  >,
  "workflowRunStep" | "workflowRun"
>) {
  const intl = useIntl()
  const toast = useToast()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const approvalConfirmationModal = useDisclosure()
  const stepApprovalModal = useDisclosure()

  const [updateFieldApproval, { isLoading: fieldApprovalUpdateLoading }] =
    usePutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalMutation()

  const userCanApproveField = Boolean(
    (whoami?.id &&
      getCurrentApprovalStep(workflowRunStep.approval_steps)?.approvers.some((a) => a.user.id === whoami.id)) ||
      hasPermission(whoami?.roles, "workflow:approve:any")
  )

  const reopenField = useCallback(async () => {
    if (!objectId) {
      return
    }
    if (userCanApproveField) {
      try {
        await updateFieldApproval({
          id: workflowRunStep.id,
          objectType: formField.object_type,
          objectId,
          fieldName: formField.field_name,
          workflowRunStepFieldApprovalStatus: "change_requested",
        }).unwrap()
      } catch (err) {
        toast({
          description: intl.formatMessage({
            id: "request.field.change_requested.error",
            description: "Toast error message when approver unapproving a field fails",
            defaultMessage: "There was an error unapproving this field",
          }),
          status: "error",
        })
        log.error("Failed to unapprove a field", err)
      }
    } else {
      approvalConfirmationModal.onOpen()
    }
  }, [
    updateFieldApproval,
    workflowRunStep.id,
    formField.object_type,
    formField.field_name,
    objectId,
    approvalConfirmationModal,
    intl,
    toast,
    userCanApproveField,
  ])

  const fieldValidForApproval: boolean =
    // Only show approval button for submitted workflow run steps
    workflowRunStep.status === "submitted" &&
    // Only show approval button for workflow run steps that have approval steps
    Boolean(workflowRunStep.approval_steps[0]) &&
    // Only show approval button for workflow run steps that are filled out or not required
    (fieldFilledOut || !formField.is_required)

  if (!objectId) {
    return null
  }

  const fieldIsApproved = fieldApproval?.fieldIsApproved

  if (fieldValidForApproval) {
    if (userCanApproveField) {
      return (
        <>
          {fieldIsApproved ? (
            <UnapproveFieldButton fieldApprovalUpdateLoading={fieldApprovalUpdateLoading} onClick={reopenField} />
          ) : (
            <Tooltip
              label={intl.formatMessage({
                defaultMessage: "Approve field",
                id: "request.field.approve.tooltip",
                description: "Tooltip text to approve a field",
              })}
            >
              <IconButton
                size="sm"
                variant="ghost"
                icon={<Icon as={CheckIcon} color="brand.600" />}
                aria-label={intl.formatMessage({
                  defaultMessage: "Approve field",
                  id: "request.field.approve.aria.label",
                  description: "Button ARIA label to approve a field",
                })}
                isDisabled={fieldApprovalUpdateLoading}
                onClick={async () => {
                  try {
                    const stepResponse = await updateFieldApproval({
                      id: workflowRunStep.id,
                      objectType: formField.object_type,
                      objectId,
                      fieldName: formField.field_name,
                      workflowRunStepFieldApprovalStatus: "approved",
                    }).unwrap()
                    if (
                      stepResponse.field_counts.approved === stepResponse.field_counts.total &&
                      canApproveWorkflowRunStep(whoami, workflowRunStep, workflowRun)
                    ) {
                      stepApprovalModal.onOpen()
                    }
                  } catch (err) {
                    toast({
                      description: intl.formatMessage({
                        id: "request.field.approve.error",
                        description: "Toast error message when approver approving a field fails",
                        defaultMessage: "There was an error approving this field",
                      }),
                      status: "error",
                    })
                    log.error("Failed to approve a field", err)
                  }
                }}
              />
            </Tooltip>
          )}
          {stepApprovalModal.isOpen && (
            <StepApprovalConfirmationModal workflowRunStep={workflowRunStep} {...stepApprovalModal} />
          )}
        </>
      )
    }
    // User does not have permission to approve the field
    if (fieldIsApproved) {
      return (
        <>
          <Tooltip
            label={<FieldApprovalText fieldApproval={fieldApproval} stepApprovedAt={workflowRunStep.completed_at} />}
            placement="top-end"
          >
            <IconButton
              icon={<Icon as={CheckCircleIcon} color="brand.600" />}
              size="sm"
              variant="ghost"
              aria-label={intl.formatMessage({
                defaultMessage: "Reopen field",
                id: "request.field.reopen.aria.label",
                description: "Button ARIA label to reopen a field",
              })}
              isDisabled={fieldApprovalUpdateLoading}
              onClick={reopenField}
            />
          </Tooltip>
          {approvalConfirmationModal.isOpen && (
            <FieldApprovalConfirmationModal
              workflowRunStepId={workflowRunStep.id}
              objectType={formField.object_type}
              objectId={objectId}
              fieldName={formField.field_name}
              fieldLabel={label}
              {...approvalConfirmationModal}
            />
          )}
        </>
      )
    }
  }

  if (fieldApproval?.status === "approved") {
    return (
      <Tooltip
        label={<FieldApprovalText fieldApproval={fieldApproval} stepApprovedAt={workflowRunStep.completed_at} />}
        shouldWrapChildren
      >
        <Icon as={CheckCircleIcon} color="brand.600" />
      </Tooltip>
    )
  }
  if (workflowRunStep.status === "approved") {
    if (!workflowRunStep.completed_at) {
      log.error("Approved step with no completion timestamp", new Error("Approved step with no completion timestamp"), {
        stepId: workflowRunStep.id,
      })
      return null
    }
    // If the step is approved, show an unactionable check mark
    return (
      <Tooltip
        label={intl.formatMessage(
          {
            defaultMessage: "Approved, {timestamp}",
            id: "request.step.approved.tooltip",
            description: "Tooltip text to approve a step",
          },
          {
            timestamp: <RelativeDateTimeDisplay dateTime={workflowRunStep.completed_at} />,
          }
        )}
        shouldWrapChildren
      >
        <Icon as={CheckCircleIcon} color="brand.600" />
      </Tooltip>
    )
  }
  return null
}

function FieldApprovalText({
  fieldApproval,
  stepApprovedAt,
}: {
  fieldApproval: DynamicFormFieldApproval
  stepApprovedAt: string | null
}) {
  const intl = useIntl()
  return (
    <chakra.span>
      {fieldApproval && fieldApproval.updated_at ? (
        <FormattedMessage
          id="field.approved.tooltip.with_approver"
          description="Tooltip text on hover of a button indicating that a field has been approved with the name of the approver"
          defaultMessage="Approved by {approverName}, {updatedAt}"
          values={{
            approverName: displayPersonName(fieldApproval.user, intl),
            updatedAt: <RelativeDateTimeDisplay dateTime={fieldApproval.updated_at} />,
          }}
        />
      ) : stepApprovedAt ? (
        // This icon and tooltip is shown if the field is implicitly approved by approving the whole step.
        // The step may be approved by a different user than the approver (super admins) so in that case show
        // a more generic message
        <FormattedMessage
          id="field.approved.tooltip.withTimestamp"
          description="Tooltip text on hover of a button indicating that a field has been approved"
          defaultMessage="Approved, {updatedAt}"
          values={{
            updatedAt: <RelativeDateTimeDisplay dateTime={stepApprovedAt} />,
          }}
        />
      ) : (
        <FormattedMessage
          id="field.approved.tooltip"
          description="Tooltip text on hover of a button indicating that a field has been approved"
          defaultMessage="Approved"
        />
      )}
    </chakra.span>
  )
}

function UnapproveFieldButton({
  fieldApprovalUpdateLoading,
  onClick,
}: {
  fieldApprovalUpdateLoading: boolean
  onClick: () => void
}) {
  const ref = useRef<HTMLButtonElement>(null)
  const isHovering = useHover(ref)
  const intl = useIntl()
  return (
    <Tooltip
      label={intl.formatMessage({
        defaultMessage: "Unapprove field",
        id: "request.field.unapprove.tooltip",
        description: "Tooltip text to unapprove a field",
      })}
      placement="top-end"
    >
      <IconButton
        ref={ref}
        icon={isHovering ? <Icon as={RefreshIcon} /> : <Icon as={CheckCircleIcon} color="brand.600" />}
        size="sm"
        variant="ghost"
        aria-label={intl.formatMessage({
          defaultMessage: "Reopen field",
          id: "request.field.reopen.aria.label",
          description: "Button ARIA label to reopen a field",
        })}
        isDisabled={fieldApprovalUpdateLoading}
        onClick={onClick}
      />
    </Tooltip>
  )
}
