import type { AgentPicker, AgentTagElement } from "@brm/schema-types/types.js"
import { Transforms } from "slate"
import type { CustomEditor } from "../types.js"
import { RICH_TEXT_SPACE } from "./common.js"

/** Agent tagging support */
export const withAgentTags = (editor: CustomEditor) => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = (element) => {
    return element.type === "agent-tag" || isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === "agent-tag" || isVoid(element)
  }

  editor.markableVoid = (element) => {
    return element.type === "agent-tag" || markableVoid(element)
  }

  return editor
}

export const insertAgentTag = (editor: CustomEditor, agent: AgentPicker) => {
  const agentTag: AgentTagElement = {
    type: "agent-tag",
    children: [{ text: "" }],
    display_name: agent.display_name,
    agent_id: agent.id,
  }
  Transforms.insertNodes(editor, [agentTag, RICH_TEXT_SPACE])
  Transforms.move(editor)
}
