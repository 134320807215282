import type {
  DeprecatedDocumentBackgroundExtractionResponse,
  DocumentExtractionLegalAgreementInput,
  DocumentMinimal,
  LegalAgreementWithMinimalRelations,
} from "@brm/schema-types/types.js"
import { useToast } from "@chakra-ui/react"
import { forwardRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { To } from "react-router-dom"
import { useParams } from "react-router-dom"
import { usePostDocumentV1ByIdDeprecatedBackgroundExtractMutation } from "../../app/services/generated-api.js"
import type { DocumentChangeHandler, DocumentUploadProps } from "../../components/Document/DocumentUpload.js"
import { DocumentUpload } from "../../components/Document/DocumentUpload.js"

type Props = Omit<DocumentUploadProps, "onChange"> & {
  onDocumentChange: DocumentChangeHandler
  onExtractionEnqueued?: (extractionEnqueued: DeprecatedDocumentBackgroundExtractionResponse) => void
  workflowRunStepId?: string
  fieldName?: string
  legalAgreement?: Pick<LegalAgreementWithMinimalRelations, "id" | "vendor" | "tools">
  legalAgreementInput?: DocumentExtractionLegalAgreementInput
  /** Gets where to navigate to when the document is clicked on */
  getLinkDestination?: (document: DocumentMinimal) => To
  selectedDocument?: Pick<DocumentMinimal, "id">
}

const ExtractLegalDocumentUpload = forwardRef<HTMLButtonElement, Props>(function ExtractLegalDocumentUpload(
  { onDocumentChange, onExtractionEnqueued, workflowRunStepId, legalAgreement, legalAgreementInput, ...props }: Props,
  ref
) {
  const toast = useToast()
  const intl = useIntl()

  const [initializeExtraction] = usePostDocumentV1ByIdDeprecatedBackgroundExtractMutation()
  const { code } = useParams<{ code: string }>()

  return (
    <DocumentUpload
      {...props}
      legalAgreement={legalAgreement}
      value={props.value}
      ref={ref}
      onChange={async (documents, type, document) => {
        onDocumentChange(documents, type, document)
        if (type === "add") {
          try {
            const extractionResult = await initializeExtraction({
              id: document.id,
              deprecatedDocumentBackgroundExtractionRequest: workflowRunStepId
                ? { link_code: code, deprecated_extraction_type: "workflow_run" }
                : legalAgreement || legalAgreementInput
                  ? {
                      deprecated_extraction_type: "user_upload",
                      agreement_id: legalAgreement?.id,
                      agreement_input: legalAgreementInput,
                    }
                  : {
                      link_code: code,
                      deprecated_extraction_type: "text",
                    },
            }).unwrap()
            onExtractionEnqueued?.(extractionResult)
            toast({
              description: intl.formatMessage({
                id: "document.extraction.legal.initialize.success",
                description: "Toast success title when initializing legal extraction succeeds",
                defaultMessage: "Document uploaded successfully. Starting the extraction for relevant fields.",
              }),
              status: "success",
            })
          } catch (_) {
            toast({
              status: "warning",
              description: (
                <FormattedMessage
                  defaultMessage="Could not initialize extraction for the document {fileName}."
                  description="The toast description for an error when initializing legal extraction fails"
                  id="document.extraction.legal.initialize.error"
                  values={{ fileName: <code>{document.file_name}</code> }}
                />
              ),
            })
          }
        }
      }}
    />
  )
})

export default ExtractLegalDocumentUpload
