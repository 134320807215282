import { isCustomizableObjectType } from "@brm/schema-helpers/object-type.js"
import type {
  CustomizableObjectType,
  DocumentMinimal,
  ExtractedFieldConfig,
  FieldConfig,
  FieldConfigMinimal,
} from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { dereferenceSchema, getTitle } from "@brm/util/schema.js"
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react"
import type { JSONSchema, JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { pascalCase } from "change-case"
import { useCallback, useEffect, useMemo, useState, type FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import type { ReadonlyDeep } from "type-fest"
import { isNotUndefined } from "typed-assert"
import {
  useGetSchemaV1ByObjectTypeFieldsQuery,
  usePutSchemaV1FieldsDocumentExtractionJobMutation,
} from "../../../app/services/generated-api.js"
import { ExtractionIcon } from "../../../components/ExtractionHighlight.js"
import { IconButtonWithTooltip } from "../../../components/IconButtonWithTooltip.js"
import { FilterIcon, PlusIcon, UploadIcon, XIcon } from "../../../components/icons/icons.js"
import { RenderedMarkdown } from "../../../components/RenderedMarkdown.js"
import { CriterionInspector } from "./CriterionInspector.js"
import { CriterionProvenance } from "./CriterionProvenance.js"
import { FormCriteriaReviewModal } from "./FormCriteriaReviewModal.js"
import { FormUploadModal } from "./FormUploadModal.js"

interface CriteriaTab {
  title: string
  customizableType: CustomizableObjectType
  pathSegment: string
}

const CriterionListItem: FunctionComponent<{
  criterion: FieldConfig
  defs: Record<string, ReadonlyDeep<JSONSchema>>
  onSelect: () => void
  isActive: boolean
}> = ({ criterion, defs, onSelect, isActive }) => {
  const dereferencedConfig = useMemo(() => {
    const schema = dereferenceSchema(criterion.field_schema as JSONSchemaObject, defs) as JSONSchemaObject
    return { ...criterion, field_schema: schema }
  }, [criterion, defs])

  const title = getTitle(criterion.field_name, dereferencedConfig.field_schema)
  const description = dereferencedConfig.field_schema.uiDescription ?? dereferencedConfig.field_schema.description

  const categorySchema = FieldCategorySchema.anyOf.find((category) => category.const === criterion.category)

  const isDisabled = criterion.is_enabled === false
  const disabledBg = "gray.50"
  const disabledColor = "gray.800"

  return (
    <Box
      as="button"
      onClick={onSelect}
      textAlign="left"
      width="100%"
      p={2}
      borderRadius="md"
      _hover={{ bg: "gray.100" }}
      bg={isActive ? "gray.100" : isDisabled ? disabledBg : "transparent"}
      opacity={isDisabled ? 0.6 : 1}
    >
      <Flex flexDir="column" gap={0.5} minW={0}>
        <Flex alignItems="center" gap={1} minW={0}>
          <Heading
            size="xxs"
            isTruncated
            mr={1}
            id={criterion.field_name}
            color={isDisabled ? disabledColor : "inherit"}
          >
            {title}
          </Heading>
          {criterion.category && (
            <Badge colorScheme={categorySchema?.colorScheme} variant="subtleOutlined">
              {getTitle(criterion.category, categorySchema)}
            </Badge>
          )}
          {criterion.is_custom && (
            <Tooltip
              label={
                <Box textAlign="center">
                  <CriterionProvenance fieldConfig={criterion} />
                </Box>
              }
            >
              <Badge colorScheme="blue" variant="subtleOutlined">
                <FormattedMessage
                  defaultMessage="Custom"
                  description="Badge for custom criterion"
                  id="settings.criteria.badge.custom"
                />
              </Badge>
            </Tooltip>
          )}
          {isDisabled && (
            <Badge colorScheme="gray" variant="subtle">
              <FormattedMessage
                defaultMessage="Disabled"
                description="Badge for disabled criterion"
                id="settings.criteria.badge.disabled"
              />
            </Badge>
          )}
        </Flex>
        <Box noOfLines={1} color={isDisabled ? disabledColor : "gray.600"} fontSize="sm">
          {description && <RenderedMarkdown content={description} />}
        </Box>
      </Flex>
    </Box>
  )
}

export default function CriteriaSettings() {
  const intl = useIntl()
  const navigate = useNavigate()
  const toast = useToast()

  const { objectType: kebabCaseObjectType } = useParams()
  isNotUndefined(kebabCaseObjectType, "Missing object_type route param")

  const navItems: CriteriaTab[] = [
    {
      title: intl.formatMessage({
        id: "criteria.settings.nav.tool",
        defaultMessage: "Tool",
        description: "Navigation item for tool criteria settings",
      }),
      customizableType: "Tool",
      pathSegment: "tool",
    },
    {
      title: intl.formatMessage({
        id: "criteria.settings.nav.vendor",
        defaultMessage: "Vendor",
        description: "Navigation item for vendor criteria settings",
      }),
      customizableType: "Vendor",
      pathSegment: "vendor",
    },
    {
      title: intl.formatMessage({
        id: "criteria.settings.nav.agreement",
        defaultMessage: "Agreement",
        description: "Navigation item for legal agreement criteria settings",
      }),
      customizableType: "LegalAgreement",
      pathSegment: "legal-agreement",
    },
  ]

  const formUploadModal = useDisclosure()
  const formCriteriaReviewModal = useDisclosure()
  const criteriaReextractionModal = useDisclosure()

  const [extractedFields, setExtractedFields] = useState<{
    fields: ExtractedFieldConfig[]
    document: DocumentMinimal
  }>()

  const objectType = pascalCase(kebabCaseObjectType)
  invariant(isCustomizableObjectType(objectType), `Invalid object_type: ${objectType}`)

  const { data: criteria } = useGetSchemaV1ByObjectTypeFieldsQuery({ objectType })
  const [startFieldExtractionJob] = usePutSchemaV1FieldsDocumentExtractionJobMutation()

  const [selectedCriterion, setSelectedCriterion] = useState<FieldConfig | null>(null)
  const [newlyCreatedFieldName, setNewlyCreatedFieldName] = useState<string | null>(null)

  const [filterText, setFilterText] = useState("")
  const [selectedCategory, setSelectedCategory] = useState<string>("all")

  // For validating field name conflicts
  const fieldNames = useMemo(() => new Set(criteria?.fields.map((field) => field.field_name)), [criteria?.fields])

  const categories = [
    {
      id: "all",
      displayName: intl.formatMessage({
        id: "settings.criteria.category.all",
        defaultMessage: "All",
        description: "Display name for All category in criteria settings",
      }),
    },
    {
      id: "compliance",
      displayName: intl.formatMessage({
        id: "settings.criteria.category.compliance",
        defaultMessage: "Compliance",
        description: "Display name for Compliance category in criteria settings",
      }),
    },
    {
      id: "finance",
      displayName: intl.formatMessage({
        id: "settings.criteria.category.finance",
        defaultMessage: "Finance",
        description: "Display name for Finance category in criteria settings",
      }),
    },
    {
      id: "it",
      displayName: intl.formatMessage({
        id: "settings.criteria.category.it",
        defaultMessage: "IT",
        description: "Display name for IT category in criteria settings",
      }),
    },
    {
      id: "legal",
      displayName: intl.formatMessage({
        id: "settings.criteria.category.legal",
        defaultMessage: "Legal",
        description: "Display name for Legal category in criteria settings",
      }),
    },
  ]

  const filteredCriteria = useMemo(() => {
    if (!criteria) return []
    const filtered = criteria.fields.filter(
      (criterion) =>
        getTitle(criterion.field_name, criterion.field_schema as JSONSchemaObject)
          .toLowerCase()
          .includes(filterText.toLowerCase()) &&
        (selectedCategory === "all" || criterion.category === selectedCategory)
    )
    return filtered.sort((a, b) => {
      const titleA = getTitle(a.field_name, a.field_schema as JSONSchemaObject).toLowerCase()
      const titleB = getTitle(b.field_name, b.field_schema as JSONSchemaObject).toLowerCase()

      if (a.is_enabled === false && b.is_enabled !== false) return 1
      if (a.is_enabled !== false && b.is_enabled === false) return -1
      return titleA.localeCompare(titleB)
    })
  }, [criteria, filterText, selectedCategory])

  const criteriaNeedingReextraction = useMemo(() => {
    return filteredCriteria.filter((criterion) => criterion.needs_reextraction)
  }, [filteredCriteria])

  const showCategoryTabs = objectType !== "LegalAgreement"

  const handleCriterionDeleted = useCallback(() => {
    setSelectedCriterion(null)
  }, [])

  const [isCreatingNewCriterion, setIsCreatingNewCriterion] = useState(false)

  const handleCriterionSelect = useCallback((criterion: FieldConfig) => {
    setSelectedCriterion(criterion)
    setIsCreatingNewCriterion(false)
  }, [])

  const handleCriterionCreated = useCallback((newCriterion: FieldConfigMinimal) => {
    setIsCreatingNewCriterion(false)
    setNewlyCreatedFieldName(newCriterion.field_name)
  }, [])

  // Effect to set the newly created criterion as selected once it's available in the criteria list
  useEffect(() => {
    if (newlyCreatedFieldName && criteria) {
      const fullCriterion = criteria.fields.find((c) => c.field_name === newlyCreatedFieldName)
      if (fullCriterion) {
        setSelectedCriterion(fullCriterion)
        setNewlyCreatedFieldName(null)
      }
    }
  }, [criteria, newlyCreatedFieldName])

  return (
    <>
      <Flex direction="column" minHeight={0} paddingTop={6}>
        <Heading as="h1" size="sm" pl={8}>
          <FormattedMessage
            id="settings.criteria.header"
            description="Settings page header for criteria settings"
            defaultMessage="Criteria"
          />
        </Heading>
        <Divider />
        <Flex flexDirection="row" flex={1} minHeight={0} pl={8}>
          {/* Left-hand side navigation */}
          <Box width="200px" borderRight="1px solid" borderColor="gray.200">
            <VStack spacing={2} align="stretch" pr={4} pt={4}>
              {navItems.map(({ customizableType, pathSegment, title }) => (
                <Button
                  key={customizableType}
                  variant={objectType === customizableType ? "solid" : "ghost"}
                  justifyContent="flex-start"
                  onClick={() => {
                    setSelectedCriterion(null)
                    setIsCreatingNewCriterion(false) // Reset creation state
                    setFilterText("")
                    setSelectedCategory("all")
                    navigate(`../${pathSegment}`)
                  }}
                >
                  {title}
                </Button>
              ))}
            </VStack>
          </Box>

          {/* Main content area */}
          <Flex flexDirection="column" flex={1} minHeight={0} pl={4} pr={4} pt={4}>
            <Stack minHeight={0} spacing={4} maxW="960px" flex={1}>
              <Flex gap={2}>
                <Box>
                  <Text color="gray.600">
                    <FormattedMessage
                      id="settings.criteria.description"
                      description="Description of the criteria settings page"
                      defaultMessage="Criteria are the data and information your organization tracks about tools and vendors. Criteria are integrated into requests, and also appear on tools, and vendors. Use Global Criteria to leverage the BRM network’s collective capabilities, and our intelligence. Don’t see Global Criteria you like, or want to use, opt for Custom Criteria that are unique and private to your organization."
                    />
                  </Text>
                </Box>
                <Flex float="right" gap={1}>
                  {criteriaNeedingReextraction.length > 0 && (
                    <IconButtonWithTooltip
                      variant="outline"
                      icon={<ExtractionIcon />}
                      onClick={criteriaReextractionModal.onOpen}
                      label={intl.formatMessage({
                        id: "settings.criteria.reextraction_modal.tooltip",
                        description: "Tooltip for criteria re-extraction button",
                        defaultMessage: "Legal Agreement Criteria has changed",
                      })}
                    />
                  )}
                  <IconButtonWithTooltip
                    {...formUploadModal.getButtonProps()}
                    variant="outline"
                    icon={<Icon as={UploadIcon} />}
                    onClick={formUploadModal.onOpen}
                    label={intl.formatMessage({
                      defaultMessage: "Upload document to generate criteria",
                      description: "Tooltip label for upload document button",
                      id: "settings.criteria.upload_form_tooltip",
                    })}
                  />
                  <IconButtonWithTooltip
                    variant="outline"
                    icon={<Icon as={PlusIcon} />}
                    onClick={() => {
                      setSelectedCriterion(null)
                      setIsCreatingNewCriterion(true)
                    }}
                    label={intl.formatMessage({
                      defaultMessage: "Add new criterion",
                      description: "Tooltip label for add new criterion button",
                      id: "settings.criteria.add_new_criterion_tooltip",
                    })}
                  />
                  {formUploadModal.isOpen && (
                    <FormUploadModal
                      isOpen={formUploadModal.isOpen}
                      onClose={formUploadModal.onClose}
                      onExtracted={(fields, document) => {
                        setExtractedFields({ fields, document })
                        formUploadModal.onClose()
                        formCriteriaReviewModal.onOpen()
                      }}
                    />
                  )}
                  {formCriteriaReviewModal.isOpen && extractedFields && (
                    <FormCriteriaReviewModal
                      document={extractedFields.document}
                      extractedFields={extractedFields.fields}
                      isOpen={formCriteriaReviewModal.isOpen}
                      onClose={formCriteriaReviewModal.onClose}
                    />
                  )}
                </Flex>
              </Flex>

              {criteria && (
                <>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FilterIcon} color="gray.300" />
                    </InputLeftElement>
                    <Input
                      placeholder={intl.formatMessage({
                        id: "settings.criteria.filter.placeholder",
                        defaultMessage: "Filter criteria",
                        description: "Placeholder text for criteria filter input",
                      })}
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    {filterText && (
                      <InputRightElement onClick={() => setFilterText("")} cursor="pointer">
                        <Icon as={XIcon} />
                      </InputRightElement>
                    )}
                  </InputGroup>
                  {showCategoryTabs && (
                    <Tabs
                      index={categories.findIndex((cat) => cat.id === selectedCategory)}
                      onChange={(index) => setSelectedCategory(categories[index]?.id ?? "all")}
                    >
                      <TabList>
                        {categories.map((category) => (
                          <Tab key={category.id}>{category.displayName}</Tab>
                        ))}
                      </TabList>
                    </Tabs>
                  )}
                  <Stack overflowY="auto" minHeight={0} flexShrink={1} spacing={2} pb={4}>
                    {filteredCriteria.map((criterion) => (
                      <CriterionListItem
                        key={`${criterion.field_name}:${criterion.is_custom}`}
                        criterion={criterion}
                        defs={criteria.$defs}
                        onSelect={() => handleCriterionSelect(criterion)}
                        isActive={selectedCriterion?.field_name === criterion.field_name}
                      />
                    ))}
                  </Stack>
                </>
              )}
            </Stack>
          </Flex>

          {/* Inspector Panel */}
          <Flex flexDirection="column" width="350px" borderLeft="1px solid" borderColor="gray.200">
            <Box padding={4} overflowY="auto">
              {isCreatingNewCriterion ? (
                <CriterionInspector
                  objectType={objectType}
                  fieldConfig={undefined}
                  defs={criteria?.$defs ?? {}}
                  onCriterionDeleted={() => setIsCreatingNewCriterion(false)}
                  onCriterionCreated={handleCriterionCreated}
                  fieldNames={fieldNames}
                />
              ) : selectedCriterion ? (
                <CriterionInspector
                  key={selectedCriterion.field_name}
                  objectType={objectType}
                  fieldConfig={selectedCriterion}
                  defs={criteria?.$defs ?? {}}
                  onCriterionDeleted={handleCriterionDeleted}
                />
              ) : (
                <Text color="gray.500">
                  <FormattedMessage
                    id="settings.criteria.inspector.placeholder"
                    description="Placeholder text for the criteria inspector panel"
                    defaultMessage="Select a criterion to view its details or click the + button to add a new one"
                  />
                </Text>
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>

      {/* Criteria Re-extraction Modal */}
      {criteriaReextractionModal.isOpen && (
        <Modal isOpen={criteriaReextractionModal.isOpen} onClose={criteriaReextractionModal.onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <FormattedMessage
                id="settings.criteria.reextraction_modal.title"
                description="Title for criteria re-extraction modal"
                defaultMessage="Extract Criteria"
              />
            </ModalHeader>
            <ModalBody>
              <Text>
                <FormattedMessage
                  id="settings.criteria.reextraction_modal.message"
                  description="Message in criteria re-extraction modal"
                  defaultMessage="The following fields have changed and can be re-extracted:"
                />
              </Text>
              <Stack spacing={2} marginY={2}>
                {criteriaNeedingReextraction.map((criteria) => (
                  <Text key={criteria.field_name} fontWeight="bold">
                    {getTitle(criteria.field_name, criteria.field_schema as JSONSchemaObject)}
                  </Text>
                ))}
              </Stack>
              <FormattedMessage
                defaultMessage="Starting an extraction will update the changed fields to all existing legal agreements. Any agreement that has new extracted values will need to be re-verified."
                description="Message in criteria re-extraction modal"
                id="settings.criteria.reextraction_modal.message"
              />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={2}>
                <Button variant="outline" onClick={criteriaReextractionModal.onClose}>
                  <FormattedMessage
                    id="settings.criteria.reextraction_modal.skip"
                    description="Dismiss button label in re-extraction modal"
                    defaultMessage="Close"
                  />
                </Button>
                <Button
                  colorScheme="brand"
                  onClick={async () => {
                    try {
                      await startFieldExtractionJob({
                        body: { fields: criteriaNeedingReextraction },
                      })
                      toast({
                        status: "success",
                        description: intl.formatMessage({
                          id: "settings.criteria.reextraction_modal.reextract.success",
                          description: "Toast shown when extraction is started",
                          defaultMessage: "Extraction started",
                        }),
                      })
                    } catch {
                      toast({
                        status: "error",
                        description: intl.formatMessage({
                          id: "settings.criteria.reextraction_modal.reextract.error",
                          description: "Toast shown when extraction fails",
                          defaultMessage: "Failed to start extraction",
                        }),
                      })
                    }
                    criteriaReextractionModal.onClose()
                  }}
                >
                  <FormattedMessage
                    id="settings.criteria.reextraction_modal.reextract"
                    description="Re-extraction button label in re-extraction modal"
                    defaultMessage="Begin Extraction"
                  />
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
