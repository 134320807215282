import type { WorkflowRun } from "@brm/schema-types/types.js"
import { capitalize } from "@brm/util/string.js"
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  chakra,
  type UseModalProps,
} from "@chakra-ui/react"
import { type FunctionComponent } from "react"
import { useAsyncAbortable } from "react-async-hook"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { isNotVoid } from "typed-assert"
import { useGetUserV1WhoamiQuery } from "../../../app/services/generated-api.js"
import { Link } from "../../../components/Link.js"
import { askBetsy } from "../../betsy/ask-betsy.js"

export const ChurnConfirmationDialog: FunctionComponent<
  UseModalProps & { onDraftEmailClick: () => void; workflowRun: WorkflowRun }
> = ({ onDraftEmailClick, workflowRun, ...modalProps }) => {
  return (
    <Modal {...modalProps} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            id="workflowRunOverview.churnConfirmationDialog.header"
            description="Header for the churn confirmation dialog modal"
            defaultMessage="{kind} rejected"
            values={{ kind: capitalize(workflowRun.kind) }}
          />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage
            id="workflowRunOverview.churnConfirmationDialog.body"
            description="Ask user if they want to send a cancellation notice"
            defaultMessage="Would you like to send a cancellation notice to {vendorName}?"
            values={{ vendorName: workflowRun.vendor?.display_name }}
          />
        </ModalBody>
        <ModalHeader display="flex" gap={1} justifyContent="end">
          <Button
            colorScheme="brand"
            onClick={() => {
              onDraftEmailClick()
              modalProps.onClose()
            }}
          >
            <FormattedMessage
              id="workflowRunOverview.churnConfirmationDialog.draftEmail"
              description="Label for the draft email button"
              defaultMessage="Draft email"
            />
          </Button>
          <Button onClick={modalProps.onClose}>
            <FormattedMessage
              id="workflowRunOverview.churnConfirmationDialog.later"
              description="Label for the later button"
              defaultMessage="Later"
            />
          </Button>
        </ModalHeader>
      </ModalContent>
    </Modal>
  )
}

export const ChurnEmailModal: FunctionComponent<UseModalProps & { workflowRun: WorkflowRun }> = ({
  workflowRun,
  ...props
}) => {
  const { data: whoami } = useGetUserV1WhoamiQuery()

  const vendor = workflowRun.vendor
  isNotVoid(vendor)

  interface FormState {
    email_address: string
    email_draft: string
    cancellation_reasons: string
    aggressiveness: number
  }
  const form = useForm<FormState>({
    defaultValues: {
      email_address: "",
      email_draft: "",
      aggressiveness: 5,
      cancellation_reasons: "",
    } satisfies FormState,
  })

  useAsyncAbortable(
    async (signal, vendor, form) => {
      form.setValue("email_address", "")
      await askBetsy(
        {
          query: [
            {
              type: "paragraph",
              children: [
                {
                  text: `What is the email address we could contact to cancel our agreement with ${vendor}? Respond only with the email address or NULL if you don't know.`,
                },
              ],
            },
          ],
          conversation_id: crypto.randomUUID(),
          messages: [],
          log_message: false,
        },
        (message) => {
          let newValue = form.getValues("email_address") + message
          if (newValue.toUpperCase().trim() === "NULL") {
            newValue = ""
          }
          form.setValue("email_address", newValue)
        },
        signal
      )
    },
    [vendor.display_name, form] as const
  )

  const emailDraftStatus = useAsyncAbortable(
    async (signal, whoami, form, aggressiveness, cancellationReasons) => {
      if (!whoami) {
        return
      }
      form.setValue("email_draft", "")
      await askBetsy(
        {
          query: [
            {
              type: "paragraph",
              children: [
                {
                  text: `Write an email to cancel our agreement with ${vendor.display_name}.
${cancellationReasons ? `The reasons we are cancelling are:\n${cancellationReasons}\n` : "Do not include the reasons for the cancellation."}
Do not say anything you don't know for sure is factually correct.
On a scale from 1 to 10, your aggressiveness level should be ${aggressiveness}, where 1 is very polite because we had a very positive experience and regret the cancellation, and 10 being highly aggressive because we had a very negative experience.
Your name is ${whoami?.first_name} ${whoami?.last_name} from ${whoami?.organization.display_name}.
Return only the email body, no subject or other introductory text.`,
                },
              ],
            },
          ],
          conversation_id: crypto.randomUUID(),
          messages: [],
          log_message: false,
        },
        (message) => {
          form.setValue("email_draft", form.getValues("email_draft") + message)
        },
        signal
      )
    },
    [whoami, form, form.watch("aggressiveness"), form.watch("cancellation_reasons")] as const
  )

  const mailToUri = `mailto:${form.watch("email_address")}?subject=Cancellation&body=${encodeURIComponent(form.watch("email_draft"))}`

  return (
    <Modal {...props} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            defaultMessage="Cancel agreement"
            description="Title of the modal to cancel an agreement"
            id="agreement.cancel.title"
          />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={4}>
          <Controller
            name="email_address"
            control={form.control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid}>
                <FormLabel>
                  <FormattedMessage
                    id="churnEmailModal.vendorEmailAddress"
                    description="Label for vendor email address"
                    defaultMessage="Vendor email address"
                  />
                </FormLabel>
                <Input {...field} type="email" />
              </FormControl>
            )}
          />
          <Controller
            name="cancellation_reasons"
            control={form.control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid}>
                <FormLabel>
                  <FormattedMessage
                    id="churnEmailModal.cancellationReasons"
                    description="Label for cancellation reasons input"
                    defaultMessage="Are there reasons for cancellation you want to include?"
                  />
                </FormLabel>
                <Textarea
                  placeholder={`- Too expensive
- Support wasn’t responsive
- Experienced outages
- ...`}
                  rows={4}
                  autoFocus
                  {...field}
                />
                <FormHelperText>
                  <FormattedMessage
                    id="churnEmailModal.bulletList"
                    description="Helper text for cancellation reasons input"
                    defaultMessage="Provide a quick bullet list and we’ll reword it for you to include in the email."
                  />
                </FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name="aggressiveness"
            control={form.control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid}>
                <FormLabel>
                  <FormattedMessage
                    id="churnEmailModal.aggressiveness"
                    description="Label for aggressiveness input"
                    defaultMessage="Aggressiveness"
                  />
                </FormLabel>
                <chakra.input type="range" width="100%" min={0} max={10} {...field} />
              </FormControl>
            )}
          />
          <Controller
            name="email_draft"
            control={form.control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid}>
                <FormLabel>
                  <FormattedMessage
                    id="churnEmailModal.emailToSend"
                    description="Label for email draft input"
                    defaultMessage="Email to send"
                  />
                </FormLabel>
                <Textarea rows={20} {...field} />
              </FormControl>
            )}
          />
          <Button as={Link} colorScheme="brand" to={mailToUri} isDisabled={emailDraftStatus.loading}>
            <FormattedMessage
              id="churnEmailModal.sendEmail"
              description="Label for send email button"
              defaultMessage="Send email"
            />
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
