import type { LegalAgreementListItem } from "@brm/schema-types/types.js"
import { formatDate } from "@brm/util/format-date-time.js"
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  useClipboard,
  useDisclosure,
  useToast,
  VStack,
  type StyleProps,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { Select } from "chakra-react-select"
import React, { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetUserV1CalendarKeyQuery, usePostLegalV1AgreementsListQuery } from "../../app/services/generated-api.js"
import { ChevronLeftIcon, ChevronRightIcon, CopyIcon } from "../../components/icons/icons.js"
import { Link } from "../../components/Link.js"
import PageWrapper from "../../components/PageWrapper.js"
import { PAGE_PADDING_X, PAGE_PADDING_Y } from "../../util/constant.js"
import FullTextSearch from "../search/FullTextSearch.js"
import AgreementCalendarListItem from "./AgreementCalendarListItem.js"
import AgreementOverflow from "./AgreementOverflow.js"

// RenewalCalendar component
const RenewalCalendar: React.FC<{
  currentMonth: Temporal.PlainDate
  agreements: LegalAgreementListItem[]
}> = ({ currentMonth, agreements }) => {
  const today = Temporal.Now.plainDateISO()

  const generateCalendarDays = () => {
    const daysInMonth = currentMonth.daysInMonth
    const firstDayOfMonth = currentMonth.with({ day: 1 }).dayOfWeek % 7 // Sunday is 0, Saturday is 6

    const calendarDays = []

    const dayItemStyle: StyleProps = {
      borderWidth: "0.5px",
      borderColor: "gray.200",
      p: 2,
      display: "flex",
      minWidth: 0,
      flexDirection: "column" as const,
    }

    // Add days from previous month
    for (let i = 0; i < firstDayOfMonth; i++) {
      const day = currentMonth.subtract({ months: 1 }).daysInMonth - firstDayOfMonth + i + 1
      calendarDays.push(
        <GridItem key={`prev-${day}`} {...dayItemStyle} opacity={0.5}>
          <Text fontWeight="bold">{day}</Text>
        </GridItem>
      )
    }

    // Add days of current month
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = currentMonth.with({ day })
      const dayAgreements =
        agreements.filter(
          (agreement) => agreement.decision_date && Temporal.PlainDate.from(agreement.decision_date).equals(currentDate)
        ) || []
      const isToday = currentDate.equals(today)

      const agreementsToShow = dayAgreements.slice(0, 2)
      const remainingAgreements = dayAgreements.slice(2)

      calendarDays.push(
        <GridItem key={day} {...dayItemStyle}>
          <Flex justifyContent="center" alignItems="center" width="24px" height="24px" mb={1}>
            <Text
              fontWeight="bold"
              color={isToday ? "white" : undefined}
              bg={isToday ? "brand.500" : undefined}
              borderRadius="full"
              width="24px"
              height="24px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {day}
            </Text>
          </Flex>
          <VStack align="stretch" spacing={1} overflow="auto" flex={1}>
            {agreementsToShow.map((agreement) => (
              <AgreementCalendarListItem key={agreement.id} agreement={agreement} />
            ))}
            {remainingAgreements.length > 0 && (
              <AgreementOverflow allAgreements={dayAgreements} overflowCount={remainingAgreements.length} />
            )}
          </VStack>
        </GridItem>
      )
    }

    // Add days from next month
    const remainingDays = (7 - ((firstDayOfMonth + daysInMonth) % 7)) % 7
    for (let i = 1; i <= remainingDays; i++) {
      calendarDays.push(
        <GridItem key={`next-${i}`} {...dayItemStyle} opacity={0.5}>
          <Text fontWeight="bold">{i}</Text>
        </GridItem>
      )
    }

    return calendarDays
  }

  const weekdays = [
    <FormattedMessage
      key="sunday"
      id="calendar.sunday"
      defaultMessage="Sun"
      description="Abbreviated name for Sunday in calendar header"
    />,
    <FormattedMessage
      key="monday"
      id="calendar.monday"
      defaultMessage="Mon"
      description="Abbreviated name for Monday in calendar header"
    />,
    <FormattedMessage
      key="tuesday"
      id="calendar.tuesday"
      defaultMessage="Tue"
      description="Abbreviated name for Tuesday in calendar header"
    />,
    <FormattedMessage
      key="wednesday"
      id="calendar.wednesday"
      defaultMessage="Wed"
      description="Abbreviated name for Wednesday in calendar header"
    />,
    <FormattedMessage
      key="thursday"
      id="calendar.thursday"
      defaultMessage="Thu"
      description="Abbreviated name for Thursday in calendar header"
    />,
    <FormattedMessage
      key="friday"
      id="calendar.friday"
      defaultMessage="Fri"
      description="Abbreviated name for Friday in calendar header"
    />,
    <FormattedMessage
      key="saturday"
      id="calendar.saturday"
      defaultMessage="Sat"
      description="Abbreviated name for Saturday in calendar header"
    />,
  ]

  return (
    <Grid
      templateColumns="repeat(7, 1fr)"
      templateRows={`auto repeat(${Math.ceil(((currentMonth.with({ day: 1 }).dayOfWeek % 7) + currentMonth.daysInMonth) / 7)}, 1fr)`}
      height="100%"
      flex={1}
      borderWidth="0.5px"
      borderColor="gray.200"
    >
      {weekdays.map((day, index) => (
        <GridItem key={index} textAlign="center" fontWeight="bold" p={2} borderWidth="0.5px" borderColor="gray.200">
          {day}
        </GridItem>
      ))}
      {generateCalendarDays()}
    </Grid>
  )
}

// RenewalAgenda component
const RenewalAgenda: React.FC<{
  agreements: LegalAgreementListItem[]
}> = ({ agreements }) => {
  const intl = useIntl()

  let currentDate: string | React.ReactNode | null = null
  let currentMonth: string | null = null

  return (
    <VStack align="stretch" spacing={2} overflowY="auto">
      {agreements.map((agreement, index) => {
        const agreementDate = agreement.decision_date ? Temporal.PlainDate.from(agreement.decision_date) : null

        const formattedDate = agreementDate
          ? formatDate(intl, agreementDate.toString(), {
              weekday: "short",
              month: "short",
              day: "numeric",
            })
          : intl.formatMessage({
              id: "renewalCalendar.noDate",
              defaultMessage: "N/A",
              description: "Displayed when no date is available",
            })

        const formattedMonth = agreementDate
          ? formatDate(intl, agreementDate.toString(), {
              month: "long",
              year: "numeric",
            })
          : null

        const isNewDate = formattedDate !== currentDate
        const isNewMonth = formattedMonth !== currentMonth
        currentDate = formattedDate
        currentMonth = formattedMonth

        return (
          <React.Fragment key={agreement.id}>
            {isNewMonth && (
              <>
                {index !== 0 && <Divider />}
                <Text mt={4} fontSize="xl" fontWeight="bold">
                  {formattedMonth}
                </Text>
                <Divider />
              </>
            )}
            {isNewDate && !isNewMonth && <Divider />}
            <Flex alignItems="center">
              {isNewDate && (
                <Box width="150px" flexShrink={0} pl={4}>
                  <Text fontWeight="bold">{formattedDate}</Text>
                </Box>
              )}
              {!isNewDate && <Box width="150px" flexShrink={0} />}
              <AgreementCalendarListItem agreement={agreement} />
            </Flex>
          </React.Fragment>
        )
      })}
    </VStack>
  )
}

const RenewalCalendarPage: React.FC = () => {
  const [currentMonth, setCurrentMonth] = useState(Temporal.Now.plainDateISO())
  const [view, setView] = useState<"calendar" | "agenda">("calendar")
  const intl = useIntl()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const calendarKeyQuery = useGetUserV1CalendarKeyQuery()
  const toast = useToast()

  const calendarUrl = calendarKeyQuery.data
    ? `${import.meta.env.VITE_API_BASE_URL}/calendar/v1/renewals/${calendarKeyQuery.data?.calendar_key}`
    : ""

  const { onCopy } = useClipboard(calendarUrl)

  const getMonthDates = (date: Temporal.PlainDate) => {
    const startDate = date.with({ day: 1 }).toString()
    const endDate = date.with({ day: date.daysInMonth }).add({ days: 1 }).toString()
    return { startDate, endDate }
  }

  const { startDate, endDate } = getMonthDates(currentMonth)
  const prevMonth = currentMonth.subtract({ months: 1 })
  const nextMonth = currentMonth.add({ months: 1 })

  const { startDate: prevStartDate, endDate: prevEndDate } = getMonthDates(prevMonth)
  const { startDate: nextStartDate, endDate: nextEndDate } = getMonthDates(nextMonth)

  const currentMonthQuery = usePostLegalV1AgreementsListQuery(
    {
      listQueryStringParams: {
        filter: [
          [
            {
              column: "decision_date",
              fields: {
                comparator: "between",
                minValue: startDate,
                maxValue: endDate,
              },
            },
          ],
        ],
      },
    },
    {
      skip: view !== "calendar",
    }
  )

  const agendaQuery = usePostLegalV1AgreementsListQuery(
    {
      listQueryStringParams: {
        sort: {
          by: "decision_date",
          direction: "ASC",
        },
        filter: [
          [
            {
              column: "decision_date",
              fields: {
                comparator: "gte",
                value: currentMonth.with({ day: 1 }).toString(),
              },
            },
          ],
        ],
        offset: 0,
        limit: 500, // for now it's safe to assume nobody renews more than 500 agreements in a month
      },
    },
    {
      skip: view !== "agenda",
    }
  )

  // Prime the cache for previous and next months only in calendar view
  const _prevMonthQuery = usePostLegalV1AgreementsListQuery(
    {
      listQueryStringParams: {
        filter: [
          [
            {
              column: "decision_date",
              fields: {
                comparator: "between",
                minValue: prevStartDate,
                maxValue: prevEndDate,
              },
            },
          ],
        ],
      },
    },
    {
      skip: view !== "calendar",
    }
  )

  const _nextMonthQuery = usePostLegalV1AgreementsListQuery(
    {
      listQueryStringParams: {
        filter: [
          [
            {
              column: "decision_date",
              fields: {
                comparator: "between",
                minValue: nextStartDate,
                maxValue: nextEndDate,
              },
            },
          ],
        ],
      },
    },
    {
      skip: view !== "calendar",
    }
  )

  const isLoading =
    (view === "calendar" && (currentMonthQuery.isLoading || currentMonthQuery.isFetching)) ||
    (view === "agenda" && (agendaQuery.isLoading || agendaQuery.isFetching))

  const error = view === "calendar" ? currentMonthQuery.error : agendaQuery.error
  const agreements = view === "calendar" ? currentMonthQuery.data?.items : agendaQuery.data?.items

  return (
    <PageWrapper>
      <Flex direction="column" height="100vh" overflow="hidden" mr={`-${PAGE_PADDING_X}`} mb={`-${PAGE_PADDING_Y}`}>
        <Flex pt={0} pb={3} gap={2} borderBottomWidth="1px">
          <Heading as="h1" size="xs" whiteSpace="nowrap">
            <FormattedMessage
              id="renewalCalendar.title"
              defaultMessage="Renewals"
              description="Title for the Renewal Calendar page"
            />
          </Heading>
          <FullTextSearch order={2} ml="auto" />
        </Flex>
        <Flex direction="column" flex={1} overflow="hidden">
          <Box position="sticky" top={0} bg="white" zIndex={1} pr={PAGE_PADDING_X}>
            <Flex justifyContent="space-between" alignItems="center" mt={4} px={4} py={2}>
              <Flex alignItems="center" minWidth="300px" flex={1}>
                <ButtonGroup mr={4}>
                  <IconButton
                    aria-label={intl.formatMessage({
                      id: "renewalCalendar.previousMonth",
                      defaultMessage: "Previous month",
                      description: "Label for the button to go to the previous month",
                    })}
                    icon={<ChevronLeftIcon />}
                    onClick={() => setCurrentMonth((prevMonth) => prevMonth.subtract({ months: 1 }))}
                    variant="ghost"
                  />
                  <Button onClick={() => setCurrentMonth(Temporal.Now.plainDateISO())}>
                    <FormattedMessage
                      id="renewalCalendar.today"
                      defaultMessage="Today"
                      description="Label for the button to go to today’s date"
                    />
                  </Button>
                  <IconButton
                    aria-label={intl.formatMessage({
                      id: "renewalCalendar.nextMonth",
                      defaultMessage: "Next month",
                      description: "Label for the button to go to the next month",
                    })}
                    icon={<ChevronRightIcon />}
                    onClick={() => setCurrentMonth((prevMonth) => prevMonth.add({ months: 1 }))}
                    variant="ghost"
                  />
                </ButtonGroup>

                <Select
                  defaultValue={{
                    value: "calendar",
                    label: intl.formatMessage({
                      id: "renewalCalendar.calendarView",
                      defaultMessage: "Calendar",
                      description: "Label for the Calendar view option",
                    }),
                  }}
                  onChange={(newValue) => setView(newValue?.value as "calendar" | "agenda")}
                  options={[
                    {
                      value: "calendar",
                      label: intl.formatMessage({
                        id: "renewalCalendar.calendarView",
                        defaultMessage: "Calendar",
                        description: "Label for the Calendar view option",
                      }),
                    },
                    {
                      value: "agenda",
                      label: intl.formatMessage({
                        id: "renewalCalendar.agendaView",
                        defaultMessage: "Agenda",
                        description: "Label for the Agenda view option",
                      }),
                    },
                  ]}
                />
              </Flex>
              {view === "calendar" && (
                <Flex justifyContent="center" flex={1}>
                  <Text fontSize="xl" fontWeight="bold" textAlign="center">
                    <FormattedMessage
                      id="renewalCalendar.currentMonth"
                      defaultMessage="{month}"
                      description="Current month display in the Renewal Calendar"
                      values={{
                        month: formatDate(intl, currentMonth.toString(), { month: "long", year: "numeric" }),
                      }}
                    />
                  </Text>
                </Flex>
              )}
              <Flex flex={1} justifyContent="flex-end">
                <Button onClick={onOpen}>
                  <FormattedMessage
                    id="renewalCalendar.addToCalendar"
                    defaultMessage="Add to Calendar"
                    description="Button text for adding renewals to a Calendar"
                  />
                </Button>
              </Flex>
            </Flex>
          </Box>
          <Box flex={1} overflow="auto" pr={PAGE_PADDING_X} pb={PAGE_PADDING_Y}>
            <Flex direction="column" height="100%">
              {isLoading ? (
                <Flex justifyContent="center" alignItems="center" flex={1}>
                  <Spinner />
                </Flex>
              ) : error ? (
                <Flex justifyContent="center" alignItems="center" flex={1}>
                  <Text>
                    <FormattedMessage
                      id="renewalCalendar.errorLoading"
                      defaultMessage="Error loading agreements"
                      description="Error message displayed when agreements fail to load"
                    />
                  </Text>
                </Flex>
              ) : view === "calendar" ? (
                <Box flex={1} display="flex" flexDirection="column">
                  <RenewalCalendar currentMonth={currentMonth} agreements={agreements || []} />
                </Box>
              ) : (
                <Box flex={1}>
                  <RenewalAgenda agreements={agreements || []} />
                </Box>
              )}
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id="renewalCalendar.modalTitle"
              defaultMessage="Add Renewal Calendar"
              description="Title for the modal to add renewal calendar"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {calendarKeyQuery.isLoading ? (
              <Spinner />
            ) : calendarKeyQuery.isError ? (
              <Text color="red.500">
                <FormattedMessage
                  id="renewalCalendar.errorLoadingCalendarKey"
                  defaultMessage="Error loading calendar key"
                  description="Error message when calendar key fails to load"
                />
              </Text>
            ) : (
              <VStack spacing={6} align="stretch">
                <Box>
                  <Text fontWeight="bold" mb={1}>
                    <FormattedMessage
                      id="renewalCalendar.simpleInstructions"
                      defaultMessage="Default Calendar App"
                      description="Header for simple calendar addition instructions"
                    />
                  </Text>
                  <Text mb={2}>
                    <FormattedMessage
                      id="renewalCalendar.simpleInstructionsText"
                      defaultMessage="Click the button below to add the Renewal Calendar to your default calendar app. This is recommended for most users."
                      description="Instructions for adding calendar URL to default calendar app"
                    />
                  </Text>
                  <Button as="a" href={`webcal://${calendarUrl.replace(/^https?:\/\//u, "")}`} colorScheme="brand">
                    <FormattedMessage
                      id="renewalCalendar.addToCalendarButton"
                      defaultMessage="Add to Calendar"
                      description="Button text for adding calendar using webcal protocol"
                    />
                  </Button>
                </Box>
                <Divider />
                <Box>
                  <Text fontWeight="bold" mb={2}>
                    <FormattedMessage
                      id="renewalCalendar.customInstructions"
                      defaultMessage="Other Calendar Apps"
                      description="Header for custom calendar addition instructions"
                    />
                  </Text>
                  <Text mb={2}>
                    <FormattedMessage
                      id="renewalCalendar.customInstructionsText"
                      defaultMessage="The following URL can be added to any calendar product that supports iCal, such as {googleCalendarLink}, {appleCalendarLink}, {microsoftOutlookLink} and {outlookComLink}."
                      description="Instructions for adding calendar URL to various calendar applications"
                      values={{
                        googleCalendarLink: (
                          <Link color="brand.700" to="https://support.google.com/calendar/answer/37100" isExternal>
                            <FormattedMessage
                              id="renewalCalendar.googleCalendar"
                              defaultMessage="Google Calendar"
                              description="Link text for Google Calendar support page"
                            />
                          </Link>
                        ),
                        appleCalendarLink: (
                          <Link
                            color="brand.700"
                            to="https://support.apple.com/guide/calendar/subscribe-to-calendars-icl1022/mac"
                            isExternal
                          >
                            <FormattedMessage
                              id="renewalCalendar.appleCalendar"
                              defaultMessage="Apple Calendar"
                              description="Link text for Apple Calendar support page"
                            />
                          </Link>
                        ),
                        microsoftOutlookLink: (
                          <Link
                            color="brand.700"
                            to="https://support.microsoft.com/en-us/office/import-calendars-into-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379"
                            isExternal
                          >
                            <FormattedMessage
                              id="renewalCalendar.microsoftOutlook"
                              defaultMessage="Microsoft Outlook"
                              description="Link text for Microsoft Outlook support page"
                            />
                          </Link>
                        ),
                        outlookComLink: (
                          <Link
                            color="brand.700"
                            to="https://support.microsoft.com/en-us/office/import-or-subscribe-to-a-calendar-in-outlook-com-or-outlook-on-the-web-cff1429c-5af6-41ec-a5b4-74f2c278e98c"
                            isExternal
                          >
                            <FormattedMessage
                              id="renewalCalendar.outlookCom"
                              defaultMessage="Outlook.com"
                              description="Link text for Outlook.com support page"
                            />
                          </Link>
                        ),
                      }}
                    />
                  </Text>
                  <Box
                    position="relative"
                    width="100%"
                    onClick={() => {
                      onCopy()
                      toast({
                        title: intl.formatMessage({
                          id: "renewalCalendar.urlCopied",
                          defaultMessage: "Calendar URL copied",
                          description: "Toast message shown after copying the calendar URL",
                        }),
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      })
                    }}
                    cursor="pointer"
                  >
                    <Textarea value={calendarUrl} isReadOnly pr="40px" pointerEvents="none" />
                    <Box position="absolute" right="8px" top="50%" transform="translateY(-50%)" pointerEvents="none">
                      <Icon as={CopyIcon} color="gray.500" />
                    </Box>
                  </Box>
                </Box>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>
              <FormattedMessage
                id="renewalCalendar.close"
                defaultMessage="Close"
                description="Button to close the modal"
              />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </PageWrapper>
  )
}

export default RenewalCalendarPage
