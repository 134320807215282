import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import TimelineSidebarContent from "../../../../components/Timeline/TimelineSidebarContent.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "../utils.js"
import DetailsSidebarContentContainer from "./DetailsSidebarContentContainer.js"

interface Props {
  workflowRunId: string
  toolId?: string
  vendorId?: string
  legalAgreementId?: string
  getOrganizationActorWhenActorMissing: GetOrganizationActorProps["getOrganizationActorWhenActorMissing"]
  getLogoToShowByOrganizationId: GetLogoForOrganizationProps["getLogoToShowByOrganizationId"]
}

export default function WorkflowRunSidebar({
  workflowRunId,
  toolId,
  vendorId,
  legalAgreementId,
  getOrganizationActorWhenActorMissing,
  getLogoToShowByOrganizationId,
}: Props) {
  const intl = useIntl()

  return (
    <Tabs variant="line" flexShrink={1} minHeight={0} display="flex" flexDirection="column" p={0}>
      <TabList>
        <Tab>
          {intl.formatMessage({
            id: "workflow.run.sidebar.tab.details",
            description: "Label for the details tab in workflow run sidebar",
            defaultMessage: "Details",
          })}
        </Tab>
        <Tab>
          {intl.formatMessage({
            id: "workflow.run.sidebar.tab.activity",
            description: "Label for the request activity tab in workflow run sidebar",
            defaultMessage: "Request Activity",
          })}
        </Tab>
      </TabList>
      <TabPanels display="flex" p={0} flexShrink={1} minHeight={0} overflowY="auto">
        <TabPanel display="flex" flex={1} flexDirection="column" px={0}>
          <DetailsSidebarContentContainer toolId={toolId} vendorId={vendorId} legalAgreementId={legalAgreementId} />
        </TabPanel>
        <TabPanel display="flex" flex={1} flexDirection="column" px={0}>
          <TimelineSidebarContent
            timelineProps={{
              filterParams: { workflowRunId },
            }}
            getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
