import type { CurrencyAmount, FrequencyOrOneTime, IssueCreditCardRequest } from "@brm/schema-types/types.js"
import { CreditCardIntervalSchema } from "@brm/schemas"
import type { EnumTypeSchema } from "@brm/util/schema.js"
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import type { ReadonlyObjectDeep } from "type-fest/source/readonly-deep.js"
import { usePostWorkflowV1StepRunsByWorkflowRunStepIdCardsMutation } from "../../../app/services/generated-api.js"
import { CurrencyAmountInputGroup } from "../../../components/Form/CurrencyAmountInput.js"
import { EnumSelect } from "../../../components/Form/EnumSelect.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { log } from "../../../util/logger.js"
import { convertDurationToCreditCardInterval } from "../util.js"

interface IssueCreditCardModalProps {
  isOpen: boolean
  onClose: () => void
  workflowRunStepId: string
  requestedSpend?: {
    requestedSpendAmount?: CurrencyAmount
    requestedSpendInterval?: FrequencyOrOneTime
  }
}

export default function CreditCardSpendModal({
  isOpen,
  onClose,
  workflowRunStepId,
  requestedSpend,
}: IssueCreditCardModalProps) {
  const intl = useIntl()
  const toast = useToast()
  const [issueCreditCard, { isLoading }] = usePostWorkflowV1StepRunsByWorkflowRunStepIdCardsMutation()
  const [uncontrolledComponentResetId, setUncontrolledComponentResetId] = useState(0)
  const incrementUncontrolledComponentResetId = useCallback(() => setUncontrolledComponentResetId((id) => id + 1), [])
  const defaultValues = useMemo(
    () => ({
      spend_limit: requestedSpend?.requestedSpendAmount,
      spend_limit_interval: requestedSpend?.requestedSpendInterval
        ? convertDurationToCreditCardInterval(requestedSpend.requestedSpendInterval)
        : undefined,
      display_name: undefined,
    }),
    [requestedSpend]
  )
  const { control, handleSubmit, reset } = useForm<IssueCreditCardRequest>({
    defaultValues,
  })

  useEffect(() => {
    reset(defaultValues, { keepDirtyValues: true })
    incrementUncontrolledComponentResetId()
  }, [defaultValues, reset, incrementUncontrolledComponentResetId])

  const onSubmit = async (data: IssueCreditCardRequest) => {
    try {
      await issueCreditCard({
        workflowRunStepId,
        issueCreditCardRequest: {
          ...data,
          display_name: data.display_name?.trim(),
        },
      }).unwrap()
      onClose()
    } catch (err) {
      log.error("Error issuing credit card", err)
      toast({
        status: "error",
        description: getAPIErrorMessage(err) ?? (
          <FormattedMessage
            id="workflowRunStepHeader.createRampCreditCard.modal.error"
            defaultMessage="An error occurred issuing the credit card"
            description="Error message when issuing a credit card"
          />
        ),
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            id="workflowRunStepHeader.createRampCreditCard.modal.title"
            defaultMessage="Issue credit card"
            description="Title of the modal to issue a credit card upon finance step approval"
          />
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack spacing={4}>
              <FormControl isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="workflowRunStepHeader.createRampCreditCard.modal.displayName"
                    defaultMessage="Card Display Name"
                    description="Label for display name input in credit card issuance form"
                  />
                </FormLabel>
                <FormHelperText paddingBottom={2}>
                  <FormattedMessage
                    id="workflowRunStepHeader.createRampCreditCard.modal.displayNameHelper"
                    defaultMessage="Enter a name to identify this credit card"
                    description="Helper text for display name input in credit card issuance form"
                  />
                </FormHelperText>
                <Controller
                  name="display_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={intl.formatMessage({
                        id: "workflowRunStepHeader.createRampCreditCard.modal.displayNamePlaceholder",
                        defaultMessage: "Enter card display name",
                        description: "Placeholder for display name input in credit card issuance form",
                      })}
                    />
                  )}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="workflowRunStepHeader.createRampCreditCard.modal.currencyAmount"
                    defaultMessage="Spend Amount"
                    description="Label for currency and amount input in credit card issuance form"
                  />
                </FormLabel>
                <FormHelperText paddingBottom={2}>
                  <FormattedMessage
                    id="workflowRunStepHeader.createRampCreditCard.modal.currencyAmount"
                    defaultMessage="Transactions exceeding this limit within the spend interval will be declined. "
                    description="Helper text for spending restrictions section"
                  />
                </FormHelperText>
                <Controller
                  name="spend_limit"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CurrencyAmountInputGroup
                      value={value ?? null}
                      onChange={onChange}
                      key={uncontrolledComponentResetId}
                    />
                  )}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>
                  <FormattedMessage
                    id="workflowRunStepHeader.createRampCreditCard.modal.interval"
                    defaultMessage="Spend Interval"
                    description="Label for spend interval selection in credit card issuance form"
                  />
                </FormLabel>
                <FormHelperText paddingBottom={2}>
                  <FormattedMessage
                    id="workflowRunStepHeader.createRampCreditCard.modal.currencyAmount"
                    defaultMessage="This is the time period over which the spend amount applies."
                    description="Helper text for spending restrictions section"
                  />
                </FormHelperText>
                <Controller
                  name="spend_limit_interval"
                  control={control}
                  render={({ field }) => (
                    <EnumSelect
                      {...field}
                      schema={
                        CreditCardIntervalSchema as unknown as EnumTypeSchema<ReadonlyObjectDeep<JSONSchemaObject>>
                      }
                      allowNull={false}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter paddingTop={4}>
            <Button variant="ghost" mr={3} onClick={onClose}>
              <FormattedMessage
                id="workflowRunStepHeader.createRampCreditCard.modal.cancel"
                defaultMessage="Cancel"
                description="Cancel button text in the modal to issue a credit card"
              />
            </Button>
            <Button colorScheme="brand" type="submit" isLoading={isLoading} spinner={<Spinner boxSize={4} />}>
              <FormattedMessage
                id="workflowRunStepHeader.createRampCreditCard.modal.confirm"
                defaultMessage="Confirm"
                description="Confirm button text in the modal to issue a credit card"
              />
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
