import type { LegalAgreementListItem } from "@brm/schema-types/types.js"
import { Button, Popover, PopoverBody, PopoverContent, PopoverTrigger, Stack, chakra } from "@chakra-ui/react"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import AgreementCalendarDisplay from "./AgreementCalendarDisplay.js"
import AgreementCalendarListItem from "./AgreementCalendarListItem.js"

interface AgreementOverflowProps {
  allAgreements: LegalAgreementListItem[]
  overflowCount: number
}

export default function AgreementOverflow({ allAgreements, overflowCount }: AgreementOverflowProps) {
  const [selectedAgreement, setSelectedAgreement] = useState<LegalAgreementListItem | null>(null)
  return (
    <Popover placement="bottom-start" onClose={() => setSelectedAgreement(null)}>
      <PopoverTrigger>
        <Button
          variant="unstyled"
          color="brand.600"
          h="unset"
          p={1}
          _hover={{ backgroundColor: "gray.100" }}
          isTruncated
          textAlign="start"
        >
          <FormattedMessage
            id="renewalCalendar.moreAgreements"
            defaultMessage="{count} more <light>(show all)</light>"
            description="Text showing the number of overflow agreements, which can be clicked to show all agreements"
            values={{
              count: overflowCount,
              light: (chunks: React.ReactNode) => <chakra.span color="gray.400">{chunks}</chakra.span>,
            }}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody as={Stack}>
          {selectedAgreement ? (
            <AgreementCalendarDisplay agreement={selectedAgreement} onClickBack={() => setSelectedAgreement(null)} />
          ) : (
            allAgreements.map((agreement, index) => (
              <AgreementCalendarListItem
                key={agreement.id}
                // Auto focus the first item when the popover is opened
                autoFocus={index === 0}
                agreement={agreement}
                onClick={() => setSelectedAgreement(agreement)}
              />
            ))
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
