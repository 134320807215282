import { hasPermission } from "@brm/schema-helpers/role.js"
import type { LegalAgreementWithDocuments, ToolDetails, VendorDetails } from "@brm/schema-types/types.js"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetLegalV1AgreementsByIdQuery,
  useGetToolV1ByIdQuery,
  useGetUserV1WhoamiQuery,
  useGetVendorV1ByIdQuery,
} from "../../../../app/services/generated-api.js"
import PersonCell from "../../../../components/DataTable/CellRenderer/PersonCell.js"
import VendorCell from "../../../../components/DataTable/CellRenderer/VendorCell.js"
import { EmptySymbol } from "../../../../components/EmptySymbol.js"
import { LinkExternalIcon } from "../../../../components/icons/icons.js"
import { Link } from "../../../../components/Link.js"
import Spinner from "../../../../components/spinner.js"
import { smallChartHeight } from "../../../charts/constants.js"
import { SpendChartCard } from "../../../charts/SpendChartCard.js"
import { getDefaultSpendChartXAxisProps, getDefaultSpendChartYAxisProps } from "../../../charts/utils.js"
import AgreementAutoRenews from "../../../legal/AgreementAutoRenews.js"
import AgremeentSigner from "../../../legal/AgreementSigner.js"
import AgreementStartEndDate from "../../../legal/AgreementStartEndDate.js"
import AgreementTcv from "../../../legal/AgreementTcv.js"
import ToolDepartmentPie from "../../../tool/charts/ToolDepartmentPie.js"
import ToolStatusBadge from "../../../tool/status/ToolStatusBadge.js"
import VendorStatusBadge from "../../../vendor/status/VendorStatusBadge.js"
import { displayTransactionCategory } from "../../../vendor/vendor-list/util.js"

interface Props {
  toolId?: string
  vendorId?: string
  legalAgreementId?: string
}

export default function DetailsSidebarContentContainer({ toolId, vendorId, legalAgreementId }: Props) {
  const { data: tool } = useGetToolV1ByIdQuery(toolId ? { id: toolId } : skipToken)
  const { data: vendor } = useGetVendorV1ByIdQuery(vendorId ? { id: vendorId } : skipToken)
  const { data: legalAgreement } = useGetLegalV1AgreementsByIdQuery(
    legalAgreementId ? { id: legalAgreementId } : skipToken
  )
  return <DetailsSidebarContent tool={tool} vendor={vendor} legalAgreement={legalAgreement} />
}

interface DetailsSidebarContentProps {
  tool?: ToolDetails
  vendor?: VendorDetails
  legalAgreement?: LegalAgreementWithDocuments
}

function DetailsSidebarContent({ tool, vendor, legalAgreement }: DetailsSidebarContentProps) {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  if (!tool && !vendor && !legalAgreement) {
    return (
      <Center flex={1}>
        <Spinner size="md" />
      </Center>
    )
  }

  const canViewToolSpend =
    (tool?.owner && tool.owner.user_id === whoami?.id) || hasPermission(whoami?.roles, "spend:read")
  const canViewVendorSpend =
    (vendor?.owner && vendor.owner.user_id === whoami?.id) || hasPermission(whoami?.roles, "spend:read")

  return (
    <Stack pr={4} gap={4}>
      {tool && <ToolDetailsSidebarContent tool={tool} />}
      {vendor && !tool && <VendorDetailsSidebarContent vendor={vendor} />}
      <Divider />
      <Accordion allowMultiple>
        {legalAgreement && (
          <AccordionItem border="none">
            <AccordionButton px={0} display="flex" justifyContent="space-between" alignItems="center">
              <Text fontWeight="semibold">
                <FormattedMessage
                  id="workflows.run.sidebar.details.agreementDetails"
                  defaultMessage="Agreement Details"
                  description="Label for agreement details section in details sidebar"
                />
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pt={2} px={0}>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem>
                  <AgreementStartEndDate startDate={legalAgreement.start_date} endDate={legalAgreement.end_date} />
                </GridItem>
                <GridItem>
                  <AgreementTcv totalContractValue={legalAgreement.total_contract_value} />
                </GridItem>
                <GridItem>
                  <AgremeentSigner
                    is_signed={legalAgreement.is_signed}
                    effective_status={legalAgreement.effective_status}
                    buyer_signer_name={legalAgreement.buyer_signer_name}
                  />
                </GridItem>
                {typeof legalAgreement?.auto_renews === "boolean" && (
                  <GridItem>
                    <AgreementAutoRenews autoRenews={legalAgreement.auto_renews} />
                  </GridItem>
                )}
              </Grid>
              <HStack justifyContent="space-between" mt={4}>
                <Text fontWeight="medium">{legalAgreement.display_name}</Text>
                <Link
                  to={`/agreements/${legalAgreement.id}`}
                  color="brand.700"
                  fontWeight="semibold"
                  alignItems="center"
                  display="flex"
                  gap={1}
                >
                  <FormattedMessage
                    id="workflows.run.sidebar.details.viewAgreement"
                    defaultMessage="View details"
                    description="Label for view agreement details button in details sidebar"
                  />
                  <Icon as={LinkExternalIcon} />
                </Link>
              </HStack>
            </AccordionPanel>
          </AccordionItem>
        )}
        {tool && canViewToolSpend && (
          <AccordionItem border="none">
            <AccordionButton px={0} display="flex" justifyContent="space-between" alignItems="center">
              <Text fontWeight="semibold">
                <FormattedMessage
                  id="workflows.run.sidebar.details.spend"
                  defaultMessage="Spend"
                  description="Label for spend section in details sidebar"
                />
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pt={2} px={0}>
              <SpendChartCard
                entityParams={{ type: "tool", id: tool.id }}
                chartHeight={smallChartHeight}
                axisProps={[
                  { ...getDefaultSpendChartYAxisProps(intl), hideTicks: true, numTicks: 0 },
                  {
                    ...getDefaultSpendChartXAxisProps(intl),
                    hideTicks: true,
                    labelProps: { display: "none" },
                    numTicks: 0,
                  },
                ]}
                xyChartProps={{ margin: { left: 0, top: 20, right: 0, bottom: 40 } }}
                spendChartTypes={["line", "stacked-bar"]}
                minWidth="unset"
                boxSizing="border-box"
                height="unset"
                showNoDataState
              />
            </AccordionPanel>
          </AccordionItem>
        )}
        {vendor && !tool && canViewVendorSpend && (
          <AccordionItem border="none">
            <AccordionButton px={0} display="flex" justifyContent="space-between" alignItems="center">
              <Text fontWeight="semibold">
                <FormattedMessage
                  id="workflows.run.sidebar.details.spend"
                  defaultMessage="Spend"
                  description="Label for spend section in details sidebar"
                />
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pt={2} px={0}>
              <SpendChartCard
                entityParams={{ type: "vendor", id: vendor.id }}
                chartHeight={smallChartHeight}
                axisProps={[
                  { ...getDefaultSpendChartYAxisProps(intl), hideTicks: true, numTicks: 0 },
                  {
                    ...getDefaultSpendChartXAxisProps(intl),
                    hideTicks: true,
                    labelProps: { display: "none" },
                    numTicks: 0,
                  },
                ]}
                xyChartProps={{ margin: { left: 0, top: 20, right: 0, bottom: 40 } }}
                spendChartTypes={["line", "stacked-bar"]}
                minWidth="unset"
                boxSizing="border-box"
                height="unset"
                showNoDataState
              />
            </AccordionPanel>
          </AccordionItem>
        )}
        {tool && (
          <AccordionItem border="none">
            <AccordionButton px={0} display="flex" justifyContent="space-between" alignItems="center">
              <Text fontWeight="semibold">
                <FormattedMessage
                  id="workflows.run.sidebar.details.users"
                  defaultMessage="Users"
                  description="Label for users section in details sidebar"
                />
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pt={2} px={0}>
              <ToolDepartmentPie
                toolId={tool.id}
                departmentUserCounts={Object.values(tool.department_users)}
                totalUsers={tool.total_people ?? 0}
                chartSources={[]}
                width="unset"
                showNoDataState
              />
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>
    </Stack>
  )
}

function ToolDetailsSidebarContent({ tool }: { tool: ToolDetails }) {
  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" templateRows="repeat(3, auto)" gap={4}>
        <GridItem display="flex" flexDirection="column">
          <Text fontWeight="semibold" mb={2}>
            <FormattedMessage
              id="workflows.run.sidebar.details.toolOwner"
              defaultMessage="Tool Owner"
              description="Label for tool owner in details sidebar"
            />
          </Text>
          {tool?.owner ? <PersonCell person={tool.owner} size="sm" /> : <EmptySymbol />}
        </GridItem>

        <GridItem display="flex" flexDirection="column">
          <Text fontWeight="semibold" mb={2}>
            <FormattedMessage
              id="workflows.run.sidebar.details.vendor"
              defaultMessage="Vendor"
              description="Label for vendor in details sidebar"
            />
          </Text>
          <VendorCell vendor={tool.vendor} size="sm" />
        </GridItem>

        <GridItem display="flex" flexDirection="column">
          <Text fontWeight="semibold" mb={2}>
            <FormattedMessage
              id="workflows.run.sidebar.details.toolCategories"
              defaultMessage="Tool Categories"
              description="Label for tool categories in details sidebar"
            />
          </Text>
          {tool?.categories && tool.categories.length > 0 ? (
            <Text>{tool?.categories?.map((category) => category.display_name).join(", ")}</Text>
          ) : (
            <EmptySymbol />
          )}
        </GridItem>

        <GridItem display="flex" flexDirection="column">
          <Text fontWeight="semibold" mb={2}>
            <FormattedMessage
              id="workflows.run.sidebar.details.integratesWith"
              defaultMessage="Integrates With"
              description="Label for integrations in details sidebar"
            />
          </Text>
          {tool?.integrates_with && tool.integrates_with.length > 0 ? (
            <Text>{tool?.integrates_with?.map((toolListing) => toolListing.display_name).join(", ")}</Text>
          ) : (
            <EmptySymbol />
          )}
        </GridItem>

        <GridItem display="flex" flexDirection="column" alignItems="start">
          <Text fontWeight="semibold" mb={2}>
            <FormattedMessage
              id="workflows.run.sidebar.details.toolStatus"
              defaultMessage="Tool Status"
              description="Label for tool status in details sidebar"
            />
          </Text>
          {tool?.status ? <ToolStatusBadge status={tool.status} /> : <EmptySymbol />}
        </GridItem>
      </Grid>
      <Flex flexDirection="column" gap={2}>
        <Text fontWeight="semibold">
          <FormattedMessage
            id="workflows.run.sidebar.details.description"
            defaultMessage="Description"
            description="Label for description in details sidebar"
          />
        </Text>
        {tool?.description ? <Text>{tool?.description}</Text> : <EmptySymbol />}
      </Flex>
      <Flex flexDirection="column" gap={2}>
        <Text fontWeight="semibold">
          <FormattedMessage
            id="workflows.run.sidebar.details.website"
            defaultMessage="Website"
            description="Label for website in details sidebar"
          />
        </Text>
        {tool?.website ? (
          <Link to={tool.website} color="brand.700" fontWeight="semibold" alignItems="center" display="flex" gap={1}>
            {tool.website} <Icon as={LinkExternalIcon} />
          </Link>
        ) : (
          <EmptySymbol />
        )}
      </Flex>
    </>
  )
}

function VendorDetailsSidebarContent({ vendor }: { vendor: VendorDetails }) {
  const intl = useIntl()
  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" templateRows="repeat(3, auto)" gap={4}>
        <GridItem display="flex" flexDirection="column">
          <Text fontWeight="semibold" mb={2}>
            <FormattedMessage
              id="workflows.run.sidebar.details.vendorOwner"
              defaultMessage="Vendor Owner"
              description="Label for vendor owner in details sidebar"
            />
          </Text>
          {vendor?.owner ? <PersonCell person={vendor.owner} size="sm" /> : <EmptySymbol />}
        </GridItem>

        <GridItem display="flex" flexDirection="column">
          <Text fontWeight="semibold" mb={2}>
            <FormattedMessage
              id="workflows.run.sidebar.details.vendorCategories"
              defaultMessage="Vendor Categories"
              description="Label for vendor categories in details sidebar"
            />
          </Text>
          {vendor?.vendor_categories && vendor.vendor_categories.length > 0 ? (
            <Text>
              {vendor?.vendor_categories?.map((category) => displayTransactionCategory(category, intl)).join(", ")}
            </Text>
          ) : (
            <EmptySymbol />
          )}
        </GridItem>

        <GridItem display="flex" flexDirection="column" alignItems="start">
          <Text fontWeight="semibold" mb={2}>
            <FormattedMessage
              id="workflows.run.sidebar.details.vendorStatus"
              defaultMessage="Vendor Status"
              description="Label for vendor status in details sidebar"
            />
          </Text>
          <VendorStatusBadge status={vendor.status} />
        </GridItem>
      </Grid>
      <Flex flexDirection="column" gap={2}>
        <Text fontWeight="semibold">
          <FormattedMessage
            id="workflows.run.sidebar.details.description"
            defaultMessage="Description"
            description="Label for description in details sidebar"
          />
        </Text>
        {vendor?.description ? <Text>{vendor.description}</Text> : <EmptySymbol />}
      </Flex>
      <Flex flexDirection="column" gap={2}>
        <Text fontWeight="semibold">
          <FormattedMessage
            id="workflows.run.sidebar.details.website"
            defaultMessage="Website"
            description="Label for website in details sidebar"
          />
        </Text>
        {vendor?.website ? (
          <Link to={vendor.website} color="brand.700" fontWeight="semibold" alignItems="center" display="flex" gap={1}>
            {vendor.website} <Icon as={LinkExternalIcon} />
          </Link>
        ) : (
          <EmptySymbol />
        )}
      </Flex>
    </>
  )
}
