import type {
  FieldMetadataWithSuggestions,
  FormFieldConfigWithInternalOnly,
  LegalClausesFieldsMetadata,
  ObjectType,
  Suggestion,
  WorkflowRunFieldGatherer,
  WorkflowRunStep,
  WorkflowRunStepWithContext,
} from "@brm/schema-types/types.js"
import { isEmpty, isObject } from "@brm/util/type-guard.js"
import type { FormLabelProps } from "@chakra-ui/react"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CircularProgress,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Stack,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { excludeKeys } from "filter-obj"
import { useCallback, useEffect, type FunctionComponent, type ReactNode, type RefObject } from "react"
import { FormattedMessage } from "react-intl"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import type { Except } from "type-fest"
import {
  useGetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameQuery,
  useGetUserV1WhoamiQuery,
} from "../../app/services/generated-api.js"
import { CollapsibleTimelineWithInput } from "../../features/timeline/CollapsibleTimelineWithInput.js"
import MockedCommentSection from "../../features/workflows/MockedCommentSection.js"
import {
  type GetLogoForOrganizationProps,
  type GetOrganizationActorProps,
  type WorkflowRunWithExternalFlag,
} from "../../features/workflows/run/utils.js"
import { fieldHasNewOption } from "../../util/form.js"
import { scrollIntoView } from "../../util/scroll.js"
import { useDocumentVisibility } from "../../util/visibility.js"
import OverflownText from "../OverflownText.js"
import { RenderedMarkdown } from "../RenderedMarkdown.js"
import { CheckIcon, UsersIcon } from "../icons/icons.js"
import FieldGathererSelector from "./FieldGathererSelector.js"
import FieldSource from "./FieldSource.js"
import { useBetsyDealEval } from "./betsy-deal-eval.js"
import type { DynamicFormFieldApproval } from "./types.js"

export interface InputContainerProps {
  formField: FormFieldConfigWithInternalOnly
  label: string
  description?: string
  labelProps?: FormLabelProps
  children: ReactNode

  /** The workflow run, for purposes of fetching the field timeline. */
  workflowRun?: WorkflowRunWithExternalFlag

  /** The ID of the workflow run, for purposes of posting comments and updating the approval status for a step's field. */
  workflowRunStep?: WorkflowRunStep | WorkflowRunStepWithContext

  /** The dot-separated path of the field in the context object. */
  path: (string | number)[]
  /** Data source of the field's current value, used to render an indicator next to the field label */
  fieldMetadata?: FieldMetadataWithSuggestions | LegalClausesFieldsMetadata

  /** The type of the parent object of the field. */
  objectType: ObjectType | undefined

  /** The ID of the parent object of the field. */
  objectId: string | undefined

  /** States if the field is a custom or standard field. */
  isCustomField: boolean

  getValue: (path: string) => unknown

  /** The name of the field. */
  fieldName: string | undefined
  fieldFilledOut: boolean

  /** The current number of comments on the field. */
  commentCount: number | undefined

  fieldGatherer?: WorkflowRunFieldGatherer

  fieldApproval?: DynamicFormFieldApproval

  renderFieldWrapper?: (wrapperProps: Except<InputContainerProps, "renderFieldWrapper">) => ReactNode
  renderFieldSource?: () => ReactNode
  /** If this is non empty, the accept button will be rendered next to the field name */
  onVerifyField?: () => void
  isDirty?: boolean

  /** Ref of the field wrapped. */
  fieldRef?: RefObject<HTMLElement>
  value: unknown
  suggestions: Suggestion[]
  errorMessage?: string
}

/**
 * InputContainer is a wrapper component for form fields that provides consistent layout,
 * error handling, and metadata display across all input components.
 */
export const InputContainer: FunctionComponent<
  InputContainerProps & GetLogoForOrganizationProps & GetOrganizationActorProps
> = (props) => {
  const {
    formField,
    label,
    description,
    children,
    labelProps,
    fieldMetadata,
    workflowRun,
    workflowRunStep,
    objectType,
    objectId,
    getValue,
    fieldName,
    path,
    commentCount,
    fieldApproval,
    fieldGatherer,
    renderFieldWrapper,
    renderFieldSource,
    getLogoToShowByOrganizationId,
    getOrganizationActorWhenActorMissing,
    onVerifyField,
    isDirty,
    isCustomField,
    fieldRef,
    value,
    errorMessage,
  } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const documentVisibility = useDocumentVisibility()
  const { data: whoami } = useGetUserV1WhoamiQuery()

  const { data: timelineEventsResult, isLoading: isTimelineEventsLoading } =
    useGetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameQuery(
      (workflowRun?.is_external === false || whoami) && objectType && objectId && fieldName && workflowRun
        ? {
            objectType,
            objectId,
            fieldName,
            workflowRunId: workflowRun.id,
          }
        : skipToken,
      { pollingInterval: documentVisibility === "visible" ? 60_000 : undefined, refetchOnFocus: true }
    )

  const isTarget =
    searchParams.get("type") === objectType && searchParams.get("field") === fieldName && !searchParams.get("event")
  const isEditField = searchParams.get("edit_field") === "true"
  const fieldIsApproved = fieldApproval?.fieldIsApproved
  const fieldIsSharedWithSeller = !formField.is_internal_only
  const workflowHasLink = !!workflowRun && workflowRun.links.length > 0

  const betsyDealEval = useBetsyDealEval(workflowRun, getValue, path)
  const renderedFieldSource = renderFieldSource?.()
  // Some values such as clauses are objects and may have null sub values
  const isValueEmpty = isObject(value) ? isEmpty(excludeKeys(value, (_, v) => isEmpty(v))) : isEmpty(value)

  const workflowRunStepIsApprovedOrCompleted =
    workflowRunStep && (workflowRunStep.status === "completed" || workflowRunStep.status === "approved")
  const newOption = fieldHasNewOption(fieldMetadata, fieldApproval)

  const openField = useCallback(() => {
    fieldRef?.current?.focus()
    fieldRef?.current?.click()
  }, [fieldRef])

  const deleteEventSearchParam = () => {
    setSearchParams(
      (current) => {
        current.delete("event")
        return current
      },
      { replace: true }
    )
  }

  useEffect(() => {
    if (isEditField && isTarget) {
      openField()
      searchParams.delete("edit_field")
      navigate({
        search: searchParams.toString(),
        hash: location.hash,
      })
    }
  }, [fieldRef, isEditField, isTarget, location.hash, navigate, openField, searchParams, setSearchParams])

  return (
    <Stack position="relative" flex={1} gap={1} ref={isTarget ? scrollIntoView : undefined}>
      <HStack justifyContent="space-between">
        <Box flexGrow="1" flexShrink={1} minW={0}>
          <HStack justifyContent="space-between" flexShrink={1} minW={0}>
            <HStack gap={2} flexShrink={1} minW={0} py={1}>
              {description ? (
                <Tooltip label={description} hasArrow maxWidth="400px">
                  <FormLabel
                    mr={0}
                    mb={0}
                    fontSize="md"
                    display="flex"
                    flexShrink={1}
                    minW={0}
                    cursor="help"
                    {...labelProps}
                  >
                    <OverflownText flexShrink={1} minWidth={0}>
                      {label}
                    </OverflownText>
                  </FormLabel>
                </Tooltip>
              ) : (
                <FormLabel mr={0} mb={0} fontSize="md" display="flex" flexShrink={1} minW={0} {...labelProps}>
                  <OverflownText flexShrink={1} minWidth={0}>
                    {label}
                  </OverflownText>
                </FormLabel>
              )}
              {/* Once a field is approved, it will not show new values */}
              {fieldMetadata && (
                <>
                  {fieldMetadata.is_extracting && (
                    <Tooltip
                      label={
                        <FormattedMessage
                          id="field.source.extractingIcon.tooltip"
                          description="Tooltip text on BRM extracting icon field source indicator"
                          defaultMessage="New data is being extracted for this field"
                        />
                      }
                    >
                      <CircularProgress size="1.1em" color="purple.500" isIndeterminate={true} />
                    </Tooltip>
                  )}
                  {newOption && newOption.isNew ? (
                    <Tag
                      gap={1}
                      size="sm"
                      backgroundColor={`${newOption.colorScheme}.50`}
                      color="gray.700"
                      variant="outline"
                      borderColor={`${newOption.colorScheme}.600`}
                      onClick={openField}
                      as={Button}
                      height="fit-content"
                    >
                      <FieldSource fieldMetadata={newOption.fieldSource ?? fieldMetadata} boxSize={4} />
                      <FormattedMessage
                        id="components.DynamicFormFieldWrapper.newOptionTag"
                        description="New value found tag for a field"
                        defaultMessage="New value"
                      />
                    </Tag>
                  ) : (
                    <FieldSource fieldMetadata={fieldMetadata} boxSize={4} />
                  )}
                </>
              )}
              {workflowRun && !workflowRun.is_external && fieldMetadata?.type !== "link" && fieldIsSharedWithSeller && (
                <Box flex={1}>
                  <Tooltip
                    label={
                      <HStack>
                        <Icon as={UsersIcon} color="gray.500" />
                        <Text whiteSpace="nowrap">
                          {workflowHasLink ? (
                            <FormattedMessage
                              defaultMessage="Field shared with seller"
                              description="Tooltip label for fields shared with seller"
                              id="workflowRun.form.field.sharedWithSeller"
                            />
                          ) : (
                            <FormattedMessage
                              defaultMessage="Field will be shared with seller"
                              description="Tooltip label for fields that will be shared with the sellers"
                              id="workflowRun.form.field.willBeSharedWithSellers"
                            />
                          )}
                        </Text>
                      </HStack>
                    }
                    hasArrow
                    shouldWrapChildren
                  >
                    <Icon as={UsersIcon} color="gray.500" />
                  </Tooltip>
                </Box>
              )}
            </HStack>
            {/* onVerifyField today is only set for Agreements */}
            {onVerifyField &&
              (fieldMetadata?.verified ? (
                <Icon as={CheckIcon} color="success.500" margin={1} />
              ) : isValueEmpty && !isDirty ? null : (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={onVerifyField}
                  paddingY={0}
                  marginY={1}
                  leftIcon={<Icon as={CheckIcon} color="success.500" />}
                >
                  <FormattedMessage defaultMessage="Accept" description="Accept field" id="dynamicForm.accept" />
                </Button>
              ))}
          </HStack>
        </Box>
        {/* This is only for workflows today so it will not collide with the accept button above */}
        {workflowRun &&
          !fieldIsApproved &&
          !workflowRunStepIsApprovedOrCompleted &&
          workflowRunStep &&
          objectId &&
          fieldName &&
          objectType && (
            <FieldGathererSelector
              canEdit={!!whoami}
              workflowRunId={workflowRun.id}
              gatherer={fieldGatherer}
              objectId={objectId}
              fieldName={fieldName}
              objectType={objectType}
              getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
              shouldShowSellers={fieldIsSharedWithSeller}
              isCustomField={isCustomField}
              workflowRunStepId={workflowRunStep.id}
            />
          )}
      </HStack>
      <Stack flexGrow={1} flexShrink={1} minWidth={0} gap={1}>
        {renderFieldWrapper ? renderFieldWrapper(props) : children}
        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        {/** Hide field source to avoid duplicative information */}
        {renderedFieldSource && (!workflowRun || fieldMetadata?.type === "document") && (
          <FormHelperText mt={0}>{renderedFieldSource}</FormHelperText>
        )}
        {betsyDealEval && (
          <Alert status="info">
            <AlertIcon alignSelf="flex-start" />
            <AlertDescription>
              <RenderedMarkdown content={betsyDealEval} />
            </AlertDescription>
          </Alert>
        )}
      </Stack>
      {/* For now there is only FieldTimeline for workflow schema forms */}
      {workflowRun &&
        whoami &&
        objectType &&
        objectId &&
        fieldName &&
        workflowRunStep &&
        timelineEventsResult &&
        !isTimelineEventsLoading && (
          <CollapsibleTimelineWithInput
            fieldTimelineProps={{
              timelineEvents: timelineEventsResult,
            }}
            timelineCommentInputProps={{
              objectType,
              objectId,
              fieldName,
              workflowRunId: workflowRun.id,
              workflowRunStepId: workflowRunStep.id,
              showPrivacyControls: fieldIsSharedWithSeller,
              label,
              path,
            }}
            commentCount={commentCount}
            onOpen={deleteEventSearchParam}
            defaultIsOpen={true}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
          />
        )}

      {!whoami && workflowRun && workflowRun.is_external && (
        // Logged out sellers see mock comments
        <MockedCommentSection />
      )}
    </Stack>
  )
}
