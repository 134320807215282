import { getCurrentApprovalStep } from "@brm/schema-helpers/workflow.js"
import type {
  CreditCardMinimal,
  CurrencyAmount,
  FrequencyOrOneTime,
  WorkflowRunStepWithContext,
} from "@brm/schema-types/types.js"
import { Flex, Heading, Spacer, Stack, useDisclosure } from "@chakra-ui/react"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useCallback } from "react"
import { useSearchParams } from "react-router-dom"
import {
  useDeleteWorkflowV1StepRunsByWorkflowRunStepIdCardsAndCreditCardIdMutation,
  useGetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdQuery,
  useGetUserV1WhoamiQuery,
} from "../../../app/services/generated-api.js"
import { CollapsibleTimelineWithInput } from "../../timeline/CollapsibleTimelineWithInput.js"
import { IssueCreditCard } from "./IssueCreditCard.js"
import CreditCardSpendModal from "./IssueCreditCardModal.js"
import { WorkflowRunStepStatusBadge } from "./step/status/WorkflowRunStepStatusBadge.js"
import WorkflowRunStepAvatars from "./step/WorkflowRunStepAvatars.js"
import { WorkflowRunStepProgressBar } from "./step/WorkflowRunStepStepsCompletedBar.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "./utils.js"

export default function WorkflowRunStepHeader({
  workflowRunStep,
  getLogoToShowByOrganizationId,
  getOrganizationActorWhenActorMissing,
  requestedSpend,
  assignedCreditCard,
}: {
  workflowRunStep: WorkflowRunStepWithContext
  requestedSpend?: {
    requestedSpendAmount?: CurrencyAmount
    requestedSpendInterval?: FrequencyOrOneTime
  }
  assignedCreditCard?: CreditCardMinimal
} & GetLogoForOrganizationProps &
  GetOrganizationActorProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { createRampCreditCardEnabled } = useFlags()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const isStepLevelCommentSelected =
    searchParams.get("event") && searchParams.get("step") && !searchParams.get("object") && !searchParams.get("field")

  const onCloseStepLevelComments = useCallback(() => {
    if (isStepLevelCommentSelected) {
      const newSearch = new URLSearchParams(searchParams)
      newSearch.delete("event")
      setSearchParams(newSearch)
    }
  }, [isStepLevelCommentSelected, searchParams, setSearchParams])

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const [deleteCreditCard] = useDeleteWorkflowV1StepRunsByWorkflowRunStepIdCardsAndCreditCardIdMutation()
  const { data: stepTimelineEventsResult, isLoading } =
    useGetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdQuery(
      { workflowRunId: workflowRunStep.workflow_run_id, workflowRunStepId: workflowRunStep.id },
      {
        pollingInterval: 60_000,
        refetchOnFocus: true,
      }
    )
  const currentApprovalStep = getCurrentApprovalStep(workflowRunStep.approval_steps)
  const isApprover = currentApprovalStep?.approvers.some((approver) => approver.user.id === whoami?.id)

  return (
    <Flex px={4} py={2} borderBottomWidth={1} minW={0}>
      <CreditCardSpendModal
        isOpen={isOpen}
        onClose={onClose}
        workflowRunStepId={workflowRunStep.id}
        requestedSpend={requestedSpend}
      />
      <Flex flexDirection="column" flexGrow={1} minW={0} gap={2}>
        <Flex gap={2} alignItems="center">
          <Stack minW={0}>
            <Flex minW={0} flexWrap="wrap" columnGap={2} alignItems="center">
              <Heading
                as="h2"
                size="xs"
                fontSize="xl"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                minW={0}
              >
                {workflowRunStep.display_name}
              </Heading>
              <WorkflowRunStepStatusBadge step={workflowRunStep} />
            </Flex>
            {createRampCreditCardEnabled &&
              (workflowRunStep.type === "finance" || workflowRunStep.type === "close") && (
                <IssueCreditCard
                  creditCard={assignedCreditCard}
                  isReadOnly={!isApprover}
                  onCreate={onOpen}
                  onDelete={async () => {
                    if (!assignedCreditCard) {
                      return
                    }
                    await deleteCreditCard({
                      workflowRunStepId: workflowRunStep.id,
                      creditCardId: assignedCreditCard.id,
                    })
                  }}
                />
              )}
            <WorkflowRunStepAvatars step={workflowRunStep} />
          </Stack>
          <Spacer />
          {workflowRunStep.field_counts.total > 0 && (
            <WorkflowRunStepProgressBar
              stepStatus={workflowRunStep.status}
              fieldCounts={workflowRunStep.field_counts}
            />
          )}
        </Flex>
        {!isLoading && (
          <CollapsibleTimelineWithInput
            fieldTimelineProps={{
              timelineEvents: stepTimelineEventsResult || [],
            }}
            timelineCommentInputProps={{
              objectType: "WorkflowRunStep",
              showPrivacyControls: true,
              objectId: workflowRunStep.id,
              workflowRunId: workflowRunStep.workflow_run_id,
              workflowRunStepId: workflowRunStep.id,
              label: workflowRunStep.display_name,
            }}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
            commentCount={workflowRunStep.comment_count}
            onClose={onCloseStepLevelComments}
            defaultIsOpen={Boolean(
              searchParams.get("event") &&
                searchParams.get("step") &&
                !searchParams.get("object") &&
                !searchParams.get("field")
            )}
          />
        )}
      </Flex>
    </Flex>
  )
}
