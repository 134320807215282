import {
  type FieldCategory,
  type ToolDetails,
  type ToolPatchInput,
  type ToolPatchOutput,
} from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { getTitle } from "@brm/util/schema.js"
import { HStack, Heading, Spacer, Stack, useToast } from "@chakra-ui/react"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useCallback, useMemo } from "react"
import type { DefaultValues } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { usePatchToolV1ByIdMutation } from "../../app/services/generated-api.js"
import DynamicForm, { type DynamicFormProps } from "../../components/DynamicForm/DynamicForm.js"
import { FORM_MAX_WIDTH } from "../../components/DynamicForm/utils.js"
import { useObjectSchema } from "../../util/use-schema.js"
import GatherDataMenuButton from "../dynamo/GatherDataMenuButton.js"
import DynamoTelemetry from "../dynamo/TelemetryCard.js"
import ToolComplianceStatusBadge from "./status/ToolComplianceStatusBadge.js"
import ToolFinanceStatusBadge from "./status/ToolFinanceStatusBadge.js"
import ToolItStatusBadge from "./status/ToolItStatusBadge.js"

interface Props {
  tool: ToolDetails
  category: FieldCategory | undefined
  formFields: DynamicFormProps<ToolPatchOutput>["formFields"] | undefined
}

export default function ToolCategory({ tool, category, formFields }: Props) {
  const toast = useToast()
  const intl = useIntl()
  const { dynamoTelemetryEnabled } = useFlags()

  const toolSchema = useObjectSchema("Tool")

  const [updateTool] = usePatchToolV1ByIdMutation()

  const title = useMemo(() => {
    const fieldCategorySchema = FieldCategorySchema.anyOf.find((schema) => schema.const === category)
    return getTitle(
      category ??
        intl.formatMessage({
          defaultMessage: "General",
          description: "General category title",
          id: "general",
        }),
      fieldCategorySchema
    )
  }, [category, intl])

  const submit = useCallback(
    async (inputs: ToolPatchInput) => {
      try {
        await updateTool({ id: tool.id, toolPatch: inputs }).unwrap()
        toast({
          description: intl.formatMessage(
            {
              id: "tool.category.saved",
              defaultMessage: "{category} saved",
              description: "Tool category saved toast message",
            },
            { category: title }
          ),
          status: "success",
        })
      } catch (err) {
        toast({
          description: intl.formatMessage(
            {
              id: "tool.category.error",
              defaultMessage: "Error saving {category}",
              description: "Tool category error toast message",
            },
            { category: title }
          ),
          status: "error",
        })
        throw err
      }
    },
    [intl, title, toast, tool.id, updateTool]
  )

  const documentDownloadURL = useCallback(
    (path: (string | number)[]) =>
      new URL(`/tool/v1/${tool.id}/${path.join("/")}/content`, import.meta.env.VITE_API_BASE_URL).href,
    [tool.id]
  )
  if (!formFields || !toolSchema) {
    return null
  }
  return (
    <HStack justifyContent="center">
      <Stack maxWidth={FORM_MAX_WIDTH} flex={1}>
        <HStack justifyContent="space-between">
          <Heading size="xs">{title}</Heading>
          <Spacer />
          {category === "compliance" ? (
            <GatherDataMenuButton toolListingId={tool.tool_listing_id} toolId={tool.id} category={category} />
          ) : null}
          {category === "it" ? (
            <ToolItStatusBadge status={tool.it_status} editToolId={tool.id} />
          ) : category === "compliance" ? (
            <ToolComplianceStatusBadge status={tool.compliance_status} editToolId={tool.id} />
          ) : category === "finance" ? (
            <ToolFinanceStatusBadge status={tool.finance_status} editToolId={tool.id} />
          ) : null}
        </HStack>
        {formFields.length === 0 ? (
          <FormattedMessage
            defaultMessage="No editable fields in this category"
            description="Helper message when there are no editable fields in this category"
            id="tool.category.no-editable-fields"
          />
        ) : (
          <>
            {category === "compliance" && dynamoTelemetryEnabled ? (
              <DynamoTelemetry toolListingId={tool.tool_listing_id} />
            ) : null}

            <DynamicForm<ToolPatchOutput>
              initialFormValues={tool as DefaultValues<ToolPatchOutput>}
              rootSchema={toolSchema}
              formFields={formFields}
              onSubmit={submit}
              documentDownloadURL={documentDownloadURL}
              isEditing={true}
            />
          </>
        )}
      </Stack>
    </HStack>
  )
}
