import { Heading, ListItem, OrderedList, Text, UnorderedList, chakra } from "@chakra-ui/react"
import { type RenderElementProps } from "slate-react"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { Document } from "./Document.js"
import { AgentTag } from "./AgentTag.js"
import { FieldTag } from "./FieldTag.js"
import { Image } from "./Image.js"
import InlineLink from "./InlineLink.js"
import { Mention } from "./Mention.js"
import { StandardObjectTag } from "./StandardObjectTag.js"
import type { ExtendedElementProps } from "./types.js"

export const Element = (
  props: RenderElementProps &
    ExtendedElementProps &
    GetLogoForOrganizationProps & {
      getDocumentUrl?: (documentId: string) => string
    }
) => {
  const { isReadOnly: isReadOnly, getLogoToShowByOrganizationId, getDocumentUrl, ...baseElementProps } = props
  const { attributes, children, element } = baseElementProps
  switch (element.type) {
    case "link": {
      return <InlineLink url={element.url} isReadOnly={isReadOnly} {...baseElementProps} />
    }
    case "image":
      return <Image {...baseElementProps} element={element} isReadOnly={isReadOnly} />
    case "document":
      return (
        <Document {...baseElementProps} element={element} isReadOnly={isReadOnly} getDocumentUrl={getDocumentUrl} />
      )
    case "mention":
      return (
        <Mention
          {...baseElementProps}
          element={element}
          getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
        />
      )
    case "block-quote":
      return (
        <chakra.blockquote
          ml={1}
          pl={2}
          opacity={0.85}
          borderLeftWidth="2px"
          borderLeftColor="gray.300"
          {...attributes}
        >
          {children}
        </chakra.blockquote>
      )
    case "bulleted-list":
      return (
        <UnorderedList marginInlineStart={6} {...attributes}>
          {children}
        </UnorderedList>
      )
    case "numbered-list":
      return (
        <OrderedList marginInlineStart={6} {...attributes}>
          {children}
        </OrderedList>
      )
    case "heading-one":
    case "heading-two":
      return (
        <Heading fontSize="lg" size="xs" {...attributes}>
          {children}
        </Heading>
      )
    case "list-item":
      return <ListItem {...attributes}>{children}</ListItem>
    case "field-tag":
      return <FieldTag {...baseElementProps} element={element} />
    case "agent-tag":
      return <AgentTag {...baseElementProps} element={element} />
    case "standard-object":
      return <StandardObjectTag {...baseElementProps} element={element} />
    default:
      return <Text {...attributes}>{children}</Text>
  }
}
