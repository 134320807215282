import type { DateString, FieldMetadata } from "@brm/schema-types/types.js"
import { DEFAULT_MIN_DATE_STRING } from "@brm/type-helpers/date.js"
import { plainDateToLegacyDate } from "@brm/util/date-time.js"
import { Box, Button, HStack, Stack } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { useMemo, type FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { getDefaultDateShortcuts, type DateShortcut } from "../../util/form.js"
import FieldSource from "../DynamicForm/FieldSource.js"
import type { ValueWithSource } from "../DynamicForm/types.js"
import OverflownText from "../OverflownText.js"
import Calendar from "./Calendar.js"

interface CalendarWithDatePanelProps {
  suggestions?: ValueWithSource<Temporal.PlainDate>[]
  dateShortcuts?: DateShortcut[]
  value: DateString | null
  onChange: (value: DateString | null, fieldSource?: FieldMetadata) => void
  onClose?: () => void
  minDate?: DateString
  maxDate?: DateString
  isDismissible?: boolean
  onCleared?: () => void
}

const CalendarWithDatePanel: FC<CalendarWithDatePanelProps> = ({
  value,
  suggestions,
  dateShortcuts,
  minDate = DEFAULT_MIN_DATE_STRING,
  maxDate,
  isDismissible,
  onChange,
  onClose,
  onCleared,
}) => {
  const intl = useIntl()
  const today = Temporal.Now.plainDateISO().toString()
  const shortcuts = useMemo(() => dateShortcuts ?? getDefaultDateShortcuts(intl), [dateShortcuts, intl])
  const date = value ? Temporal.PlainDate.from(value) : undefined

  // eslint-disable-next-line no-restricted-globals
  const legacyMinDate = minDate ? new Date(minDate) : undefined
  // eslint-disable-next-line no-restricted-globals
  const legacyMaxDate = maxDate ? new Date(maxDate) : undefined

  const shortcutList = useMemo(() => {
    return (
      shortcuts
        // Remove any duplicate shortcuts
        .filter(
          (shortcut) =>
            !suggestions || !suggestions.find((suggestion) => suggestion.value.toString() === shortcut.date.toString())
        )
        .map((shortcut, i) => (
          <Button
            isActive={value === shortcut.date}
            key={`shortcuts-${i}`}
            justifyContent="start"
            fontSize="sm"
            fontWeight="medium"
            variant="ghost"
            onClick={() => onChange(shortcut.date)}
            width="full"
            marginBottom={1}
          >
            <OverflownText>{shortcut.displayName}</OverflownText>
          </Button>
        ))
    )
  }, [shortcuts, suggestions, value, onChange])

  const suggestedShortcutList = useMemo(() => {
    return suggestions?.map((suggestion, i) => (
      <Button
        isActive={value === suggestion.value.toString()}
        key={`suggested-shortcuts-${i}`}
        justifyContent="start"
        fontSize="sm"
        fontWeight="medium"
        variant="ghost"
        onClick={() => onChange(suggestion.value.toString(), suggestion.field_sources?.[0])}
        gap={1}
        width="full"
        marginBottom={1}
      >
        {suggestion.value.toLocaleString(intl.locale)}
        {suggestion?.field_sources?.[0] && <FieldSource fieldMetadata={suggestion.field_sources[0]} boxSize={3} />}
      </Button>
    ))
  }, [suggestions, value, intl.locale, onChange])

  // Today not in suggested shortcuts
  const showTodayOption =
    (!suggestions || !suggestions.some((suggestion) => suggestion.value.toString() === today)) &&
    (!minDate || today >= minDate) &&
    (!maxDate || today <= maxDate)

  return (
    <HStack minW={0} alignItems="start" gap={0} height="100%">
      {/* Height of calendar is fixed */}
      <Box padding={2} paddingBottom={0} overflowY="auto" height={isDismissible ? "335px" : "285px"} width="190px">
        {suggestedShortcutList}
        {showTodayOption && (
          <Button
            isActive={value === today}
            justifyContent="start"
            fontSize="sm"
            fontWeight="medium"
            variant="ghost"
            onClick={() => onChange(today)}
            width="full"
            marginBottom={1}
          >
            <FormattedMessage
              id="calendar.today"
              defaultMessage="Today"
              description="Button on calendar input to set the value to today"
            />
          </Button>
        )}
        {shortcutList}
      </Box>

      <Stack borderColor="gray.200" borderLeftWidth={1} gap={0}>
        <Calendar
          value={date && plainDateToLegacyDate(date)}
          onChange={(v) => {
            const matchingSuggestion = suggestions?.find((suggestion) => suggestion.value.toString() === v)
            if (matchingSuggestion) {
              onChange(v, matchingSuggestion.field_sources?.[0])
            } else {
              onChange(v)
            }
            onClose?.()
          }}
          minDate={legacyMinDate}
          maxDate={legacyMaxDate}
        />
        {isDismissible && (
          <HStack justifyContent="space-between" padding={2}>
            <Button variant="ghost" onClick={onCleared} color="gray.500">
              <FormattedMessage
                id="calendar.clear"
                defaultMessage="Clear"
                description="Clear button on calendar input"
              />
            </Button>
            <Button colorScheme="brand" onClick={onClose}>
              <FormattedMessage
                id="calendar.close"
                defaultMessage="Close"
                description="Close button on calendar input"
              />
            </Button>
          </HStack>
        )}
      </Stack>
    </HStack>
  )
}

export default CalendarWithDatePanel
