import type {
  FieldMetadata,
  FieldMetadataWithSuggestions,
  ToolListing,
  ToolOptionWithVendor,
} from "@brm/schema-types/types.js"
import {
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  type UseModalProps,
} from "@chakra-ui/react"
import { useRef, useState, type ReactElement, type ReactNode } from "react"
import { Controller, useForm, type RefCallBack } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { usePostToolV1Mutation } from "../../app/services/generated-api.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "../../components/DynamicForm/types.js"
import { ToolListingMultiPicker } from "../../components/Form/ToolListingMultiPicker.js"
import { ToolOptionPickerCreatable } from "../../components/Form/ToolOptionPicker.js"
import { VendorOptionPicker } from "../../components/Form/VendorOptionPicker.js"
import { NextIcon } from "../../components/icons/icons.js"
import { Link } from "../../components/Link.js"
import Spinner from "../../components/spinner.js"
import { getAPIErrorMessage } from "../../util/error.js"
import { getDisplayUrl } from "../../util/url.js"
import { validateURL } from "../../util/validation.js"

type ModalScreens = "toolCreateMenu" | "newToolGeneration"

function ToolOptionDetails(props: { tool_option: ToolOptionWithVendor }): ReactNode {
  const intl = useIntl()
  const { tool_option } = props
  const { website, vendor, display_name, description } = tool_option

  return (
    <>
      {vendor && (
        <FormControl marginTop={3} isReadOnly>
          <FormLabel>
            <FormattedMessage
              defaultMessage="Vendor"
              description="The label for the vendor field in the start workflow modal form"
              id="requests.start.modal.vendor.label"
            />
          </FormLabel>
          <VendorOptionPicker value={vendor} isReadOnly />
        </FormControl>
      )}

      <FormControl marginTop={3} isReadOnly>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Tool description"
            description="The label for the tool description field in the start workflow modal form"
            id="requests.start.modal.tool.description.label"
          />
        </FormLabel>
        <Textarea
          rows={10}
          value={description ?? ""}
          placeholder={intl.formatMessage(
            {
              id: "requests.start.modal.tool.description.placeholder",
              defaultMessage: "No description found for {tool}",
              description: "Placeholder shown when there is no description on a displayed tool",
            },
            { tool: display_name }
          )}
          isReadOnly
        />
      </FormControl>

      {website ? (
        <Link to={website} target="_blank" display="inline-block" color="blue.500">
          <HStack marginTop={2} gap={1}>
            <FormattedMessage
              id="requests.start.modal.tool.website"
              description="Link text to click which navigates user to the website of a tool"
              defaultMessage="Learn more about {website}"
              values={{ website: getDisplayUrl(new URL(website)) }}
            />

            <Icon as={NextIcon} />
          </HStack>
        </Link>
      ) : null}
    </>
  )
}

type NewToolModalProps = UseModalProps & {
  setToolOption: (toolOption: ToolOptionWithVendor | null) => void
  /**
   * Tools must have 1 toolOption present for this component to operate
   */
  tool: ToolOptionWithVendor
}

function NewToolModal(props: NewToolModalProps): ReactNode {
  const { isOpen, onClose, setToolOption, tool } = props
  const toast = useToast()
  const intl = useIntl()

  const { handleSubmit, control, trigger } = useForm({
    defaultValues: {
      new_tool_url: null,
      new_tool_display_name: tool?.display_name,
    },
  })

  const [createNewToolListing, { data: newToolListing }] = usePostToolV1Mutation()

  const initialFocusRef = useRef<HTMLInputElement>(null)

  const displayTool = newToolListing ? newToolListing : tool

  const [screen, setScreen] = useState<ModalScreens>("toolCreateMenu")
  const screens: Record<ModalScreens, ReactElement> = {
    toolCreateMenu: (
      <>
        <ModalBody overflowY="auto">
          {tool && (
            <FormControl marginTop={3} isReadOnly>
              <FormLabel>
                <FormattedMessage
                  defaultMessage="Tool"
                  description="The label for the vendor field in the start workflow modal form"
                  id="requests.start.modal.vendor.label"
                />
              </FormLabel>
              <ToolOptionPickerCreatable
                isReadOnly={true}
                allowCreate={false}
                value={{
                  value: displayTool,
                  field_sources: undefined,
                }}
              />
            </FormControl>
          )}
          <Text marginTop={3}>
            <FormattedMessage
              id="requests.start.modal.tool.help_message"
              description="Header for the start workflow modal tool selection section"
              defaultMessage="{name} will be added as a tool in your organization"
              values={{ name: tool?.display_name }}
            />
          </Text>
          <Controller
            name="new_tool_url"
            control={control}
            rules={{
              required: true,
              validate: validateURL(intl),
            }}
            render={(formState) => {
              const { field, fieldState } = formState

              return (
                <FormControl isInvalid={fieldState.invalid} isRequired marginTop={3}>
                  <FormLabel>
                    <FormattedMessage
                      defaultMessage="Tool URL"
                      description="The label for the vendor field in the start workflow modal form"
                      id="requests.start.modal.vendor.label"
                    />
                  </FormLabel>

                  <Input
                    inputMode="url"
                    ref={initialFocusRef}
                    isReadOnly={newToolListing ? true : false}
                    autoComplete="off"
                    value={field?.value ?? ""}
                    placeholder={intl.formatMessage({
                      id: "requests.start.modal.tool.create.url.placeholder",
                      description: "Placeholder for the tool URL input field",
                      defaultMessage: "Please provide the URL of the tool you are adding...",
                    })}
                    onChange={field.onChange}
                  />

                  <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                </FormControl>
              )
            }}
          />
          {newToolListing && <ToolOptionDetails tool_option={newToolListing} />}
        </ModalBody>
        <Divider />
        <ModalFooter justifyContent="right">
          <Flex gap={2}>
            <Button
              disabled={false}
              colorScheme="brand"
              onClick={handleSubmit(async (data) => {
                if (newToolListing) {
                  setToolOption(newToolListing)
                  onClose()
                  return
                }

                if ((await trigger("new_tool_url")) && data.new_tool_display_name) {
                  setScreen("newToolGeneration")

                  try {
                    await createNewToolListing({
                      body: {
                        user_provided_url: data.new_tool_url ?? "",
                        user_provided_display_name: data.new_tool_display_name ?? "",
                      },
                    }).unwrap()
                  } catch (err) {
                    const errorDescription = getAPIErrorMessage(err)
                    toast({
                      title: intl.formatMessage({
                        id: "requests.start.error.toast",
                        description: "Start request error toast title",
                        defaultMessage: "There was an issue creating this new tool",
                      }),
                      description: errorDescription,
                      status: "error",
                      duration: null,
                    })
                  }

                  setScreen("toolCreateMenu")
                }
              })}
              rightIcon={<Icon as={NextIcon} />}
            >
              <FormattedMessage
                id="requests.start.modal.continue"
                defaultMessage="Continue"
                description="Request start modal continue button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
    newToolGeneration: (
      <>
        <ModalBody overflowY="auto">
          <Center height="100%">
            <Flex flexDirection="column" gap={3}>
              <Center>
                <Spinner size="md" colorScheme="brand" />
              </Center>
              <FormattedMessage
                id="requests.start.modal.newTool.loading"
                defaultMessage="Loading..."
                description="Request start modal continue button text"
              />
            </Flex>
          </Center>
        </ModalBody>
        <Divider />
        <ModalFooter justifyContent="right">
          <Button colorScheme="brand" isDisabled={true} rightIcon={<Icon as={NextIcon} />}>
            <FormattedMessage
              id="requests.start.modal.continue"
              defaultMessage="Continue"
              description="Request start modal continue button text"
            />
          </Button>
        </ModalFooter>
      </>
    ),
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setToolOption(null)
        onClose()
      }}
      size="lg"
      closeOnOverlayClick={false}
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent height={680}>
        <ModalHeader>
          <Text>
            <FormattedMessage
              id="requests.start.modal.title.test"
              description="Title of the create tool modal via agreements"
              defaultMessage="Create New Tool"
            />
          </Text>
          <ModalCloseButton />
        </ModalHeader>
        <Divider />
        {screens[screen]}
      </ModalContent>
    </Modal>
  )
}

export function ToolOptionPickerCreatableWithModal(props: {
  autoFocus: boolean
  allowCreate: boolean
  value: ToolOptionWithVendor | undefined
  onChange: (tool?: ToolOptionWithVendor | null, fieldSource?: FieldMetadata) => void
  onCreate: (tool: ToolOptionWithVendor | null) => void
  suggestions?: ValueWithSource<ToolOptionWithVendor>[]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: DynamicFormFieldApproval
  inputRef: RefCallBack
}): ReactNode {
  const { onChange, value, onCreate, ...rest } = props
  const newToolModal = useDisclosure()
  return (
    <>
      {newToolModal.isOpen && value && (
        <NewToolModal
          {...newToolModal}
          setToolOption={(tool) => {
            onCreate(tool)
          }}
          tool={value}
        />
      )}
      <ToolOptionPickerCreatable
        value={value && { value }}
        onChange={(tool) => {
          onChange(tool?.value || null, tool?.field_sources?.[0])
          if (tool && tool?.value?.tool_listing_id === "new") {
            newToolModal.onOpen()
          }
        }}
        {...rest}
      />
    </>
  )
}

export function ToolListingMultiPickerWithModal(props: {
  value: Array<ToolListing> | undefined
  isReadOnly: boolean
  onChange: (listings: Array<ToolListing>) => void
  inputRef: RefCallBack
}): ReactNode {
  const { value, isReadOnly, inputRef, onChange } = props
  const newToolModal = useDisclosure()

  // in the workflow run, the state is updated via polling
  // if we do NOT use this intermediary state, then things get
  // messed up while the new tool modal is open
  const [newValue, setNewValue] = useState(value)
  const newListing = newValue ? newValue.at(-1) : null

  return (
    <>
      {newToolModal.isOpen && newValue && newListing && newListing?.display_name && (
        <NewToolModal
          {...newToolModal}
          setToolOption={(tool) => {
            if (tool === null) {
              return
            }

            newValue.pop()
            if (tool) {
              const toolListing: ToolListing = {
                id: tool.tool_listing_id,
                display_name: tool.display_name,
                image_asset: tool.image_asset,
                object_type: "ToolListing",
              }
              newValue.push(toolListing)
            }

            onChange(newValue)
          }}
          tool={{
            id: null,
            description: null,
            website: null,
            image_asset: null,
            tool_listing_id: "new",
            display_name: newListing.display_name,
            object_type: "Tool",
          }}
        />
      )}

      <ToolListingMultiPicker
        inputRef={inputRef}
        isReadOnly={isReadOnly}
        allowCreate={true}
        value={value ?? []}
        onChange={(listings) => {
          const latest = listings.at(-1)

          if (latest?.id === "new") {
            newToolModal.onOpen()
            setNewValue(Array.from(listings))
            return
          }

          onChange(Array.from(listings))
        }}
      />
    </>
  )
}
