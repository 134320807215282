import {
  useDisclosure,
  type MenuButtonProps,
  type MenuItemProps,
  type MenuListProps,
  type MenuProps,
} from "@chakra-ui/react"
import type React from "react"
import { useCallback, useEffect, useRef, useState, type MouseEventHandler, type RefObject } from "react"
import type { Except } from "type-fest"
import { BetsyContextMenuItem, BetsyModal } from "./BetsyContextMenu.js"

/**
 * Reusable hook to add a context menu to an element.
 * Should be used in combination with Chakra's `<Menu>`.
 */
export function useContextMenu<E extends Element>(props?: {
  betsyEnabled?: boolean
}): {
  /** The props that should be spread into the subject of the context menu, e.g. an interactive list item or a container. */
  subjectProps: {
    baseProps: { onContextMenu: React.MouseEventHandler; ref: RefObject<E> }
    buttonProps: {
      onClick: React.MouseEventHandler
    }
  }
  menuButtonProps: MenuButtonProps
  /** The props that should be spread into the `<Menu>` */
  menuProps: Except<MenuProps, "children">
  /** The props that should be spread into the `<MenuList>` */
  menuListProps: MenuListProps & { ref: RefObject<HTMLDivElement> }
  /** The props that should be spread into the `<MenuItem>` */
  menuItemProps: MenuItemProps
  /** Only returned if betsyEnabled = true */
  betsyProps?: {
    /** Returns the BetsyModal component */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BetsyModal: React.ReactNode
  }
  highlightedText: string | undefined
} {
  const [menuPosition, setMenuPosition] = useState<{ left: number; top: number } | null>(null)
  const [highlightedText, setHighlightedText] = useState<string | undefined>(undefined)
  const { isOpen: isBetsyModalOpen, onClose: onBetsyModalClose, onOpen: onBetsyModalOpen } = useDisclosure()
  const subjectRef = useRef<E>(null)
  const menuListRef = useRef<HTMLDivElement>(null)
  const { betsyEnabled } = props ?? {}
  const { onOpen, onClose, isOpen, getButtonProps, getDisclosureProps } = useDisclosure({
    onOpen: () => {
      // Focus the first menu item when it opens manually (this enables keyboard navigation)
      setTimeout(() => {
        menuListRef.current?.querySelector<HTMLElement>("[role^=menuitem]")?.focus()
      }, 1)
    },
  })

  const onContextMenu: MouseEventHandler<HTMLLIElement> = useCallback(
    (event) => {
      if (event.shiftKey || isBetsyModalOpen) {
        return
      }
      event.preventDefault()
      event.stopPropagation()
      setHighlightedText(window.getSelection()?.toString())
      setMenuPosition({ left: event.pageX, top: event.pageY })
      onOpen()
    },
    [isBetsyModalOpen, onOpen]
  )

  useEffect(() => {
    const handleClick = () => {
      setHighlightedText(undefined)
      onClose()
    }
    window.addEventListener("click", handleClick)
    return () => {
      window.removeEventListener("click", handleClick)
    }
  }, [onClose])

  const menuItemProps = {
    fontWeight: "medium",
    iconSpacing: 0,
  }

  return {
    subjectProps: {
      buttonProps: getButtonProps(),
      baseProps: { onContextMenu, ref: subjectRef },
    },
    menuProps: {
      onOpen,
      onClose,
      isOpen,
      initialFocusRef: menuListRef,
      gutter: 0,
    },
    menuButtonProps: {
      "aria-hidden": true,
      w: 1,
      h: 1,
      position: "absolute",
      cursor: "default",
      _focus: {
        outline: "none",
        boxShadow: "none",
      },
      ...menuPosition,
    },
    menuListProps: {
      ...getDisclosureProps(),
      ref: menuListRef,
      children: [
        betsyEnabled && <BetsyContextMenuItem key="ask-betsy" onClick={onBetsyModalOpen} {...menuItemProps} />,
      ],
    },
    menuItemProps,
    highlightedText,
    betsyProps: betsyEnabled
      ? {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          BetsyModal: (
            <BetsyModal highlightedText={highlightedText} isOpen={isBetsyModalOpen} onClose={onBetsyModalClose} />
          ),
        }
      : undefined,
  }
}
