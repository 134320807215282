import type { FieldMetadataWithSuggestions, FieldSourceInputProperties } from "@brm/schema-types/types.js"
import { Input, type InputProps } from "@chakra-ui/react"
import {
  Select,
  chakraComponents,
  type InputProps as ChakraComponentInputProps,
  type GroupBase,
} from "chakra-react-select"
import type { Ref } from "react"
import { forwardRef, useCallback, useImperativeHandle, useRef } from "react"
import { useIntl } from "react-intl"
import type { JSX } from "react/jsx-runtime"
import { isNewOption } from "../../util/form.js"
import OptionWithFieldSource from "./OptionWithFieldSource.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "./types.js"

export const NumberInput = forwardRef(function NumberInput(
  props: Omit<InputProps, "onChange" | "value"> & {
    suggestions?: ValueWithSource<number>[]
    onChange?: (value: number, fieldSource?: FieldSourceInputProperties) => void
    value: number
    fieldMetadata: FieldMetadataWithSuggestions
    fieldApproval?: DynamicFormFieldApproval
    placeholder?: string
  },
  ref: Ref<HTMLInputElement | null>
) {
  const intl = useIntl()
  const { suggestions, fieldMetadata, fieldApproval, placeholder, ...rest } = props
  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  const input = useCallback(
    (
      props: JSX.IntrinsicAttributes &
        ChakraComponentInputProps<ValueWithSource<number>, false, GroupBase<ValueWithSource<number>>>
    ) => {
      return <chakraComponents.Input {...props} type="number" />
    },
    []
  )
  if (suggestions && suggestions.length > 0) {
    return (
      <Select<ValueWithSource<number>>
        chakraStyles={{
          option: (provided, { data }) => {
            const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
            return {
              ...provided,
              ...(isNew && { backgroundColor: `${colorScheme}.50` }),
            }
          },
        }}
        onChange={(option) => {
          if (option?.value) {
            props.onChange?.(option?.value, option?.field_sources?.[0])
          }
        }}
        value={{
          value: props.value,
        }}
        placeholder={intl.formatMessage({
          id: "form.select.placeholder",
          defaultMessage: "Select an option...",
          description: "Placeholder for selection input",
        })}
        onInputChange={(value) => {
          if (value) {
            const numberValue = Number(value)
            const matchingSuggestion = suggestions?.find((suggestion) => suggestion.value === numberValue)
            props.onChange?.(numberValue, matchingSuggestion?.field_sources?.[0])
          }
        }}
        options={suggestions}
        getOptionValue={({ value }) => value.toString()}
        getOptionLabel={({ value }) => value.toString()}
        formatOptionLabel={({ value, field_sources }, { context }) => {
          return context === "menu" ? (
            <OptionWithFieldSource fieldSources={field_sources}>{value}</OptionWithFieldSource>
          ) : (
            value
          )
        }}
        noOptionsMessage={() => null}
        menuPortalTarget={document.body}
        isSearchable={true}
        openMenuOnFocus={true}
        ref={(select) => {
          inputRef.current = select?.inputRef ?? null
        }}
        components={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Input: input,
        }}
      />
    )
  }
  return (
    <Input
      {...rest}
      type="number"
      placeholder={placeholder ?? "0"}
      ref={inputRef}
      onChange={(e) => props.onChange?.(Number(e.target.value))}
    />
  )
})
