import type { LegalAgreementListItem, LegalAgreementListResponse } from "@brm/schema-types/types.js"
import { Box, Button, HStack, Heading, Icon, IconButton, Spacer, Stack, Text, Tooltip } from "@chakra-ui/react"
import { useEffect, useRef } from "react"
import { FormattedMessage, FormattedPlural, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { useSessionStorage } from "usehooks-ts"
import { Link } from "../../components/Link.js"
import OverflownText from "../../components/OverflownText.js"
import LayoutLeftFilledIcon from "../../components/icons/LayoutLeftFilledIcon.js"
import { ToolLogo } from "../../components/icons/Logo.js"
import { LayoutLeftIcon, LockIcon } from "../../components/icons/icons.js"
import { pathForLegalAgreement } from "../../util/json-schema.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { AGREEMENT_LIST_MINIMIZE_STORAGE_KEY } from "./constants.js"

export const AgreementListSidebar = ({
  selectedAgreementId,
  agreementList,
  isMinimized,
}: {
  selectedAgreementId?: string
  agreementList?: LegalAgreementListResponse
  isMinimized: boolean
}) => {
  const intl = useIntl()
  const [isHidden, setIsHidden] = useSessionStorage(AGREEMENT_LIST_MINIMIZE_STORAGE_KEY, false)

  if (!agreementList) {
    return null
  }
  const { items: agreements, total } = agreementList
  // Only show side bar when there are multiple agreements that need to be reviewed
  if (!agreements || total < 2) {
    return null
  }

  const newPublicAgreements = agreements.filter(
    (agreement) =>
      agreement.verification_status === "draft" &&
      (agreement.visibility_status === "visible" || agreement.visibility_status === "draft")
  )
  const newPrivateAgreements = agreements.filter(
    (agreement) => agreement.verification_status === "draft" && agreement.visibility_status === "private"
  )
  const inReviewAgreements = agreements.filter((agreement) => agreement.verification_status === "in_review")
  const minimizeButtonLabel = isHidden
    ? intl.formatMessage({
        id: "agreements.minimize.tooltip",
        description: "Label shown on button that can toggle minimize/expand agreements sidebar",
        defaultMessage: "Expand agreements",
      })
    : intl.formatMessage({
        id: "agreements.maximize.tooltip",
        description: "Label shown on button that can toggle minimize/expand agreements sidebar",
        defaultMessage: "Minimize agreements",
      })
  return (
    <Stack
      width={isMinimized ? "auto" : "300px"}
      px={isMinimized ? 3 : 6}
      overflowY="auto"
      paddingBottom={4}
      sx={{
        scrollbarWidth: "thin",
      }}
      borderRight={1}
      borderStyle="solid"
      borderColor="gray.200"
      flexShrink={0}
    >
      <HStack py={4} alignItems="start" justifyContent="space-between">
        {!isMinimized && (
          <Stack gap={0}>
            <Heading as="h2" size="xs" fontSize="xl">
              <FormattedMessage
                defaultMessage="Agreements"
                description="The title for the legal agreement list panel"
                id="legalAgreementEditorDrawer.list.title"
              />
            </Heading>
            <Text fontSize="xs" fontWeight="light">
              <FormattedPlural
                value={total}
                one={
                  <FormattedMessage
                    defaultMessage="1 Agreement"
                    description="The singular for agreements"
                    id="legalAgreementEditorDrawer.list.one"
                  />
                }
                other={
                  <FormattedMessage
                    defaultMessage="{count} Agreements"
                    description="Number of agreements"
                    id="legalAgreementEditorDrawer.list.other"
                    values={{ count: total }}
                  />
                }
              />
            </Text>
          </Stack>
        )}
        <Tooltip closeOnClick={false} closeOnEsc={false} label={minimizeButtonLabel}>
          <IconButton
            variant="ghost"
            size="md"
            icon={<Icon as={isHidden ? LayoutLeftIcon : LayoutLeftFilledIcon} />}
            aria-label={minimizeButtonLabel}
            onClick={() => {
              setIsHidden(!isHidden)
            }}
          />
        </Tooltip>
      </HStack>
      {isMinimized ? (
        <>
          <Spacer maxHeight={10} />
          <Stack justifyContent="center">
            {[...newPublicAgreements, ...inReviewAgreements].map((agreement) => (
              <AgreementButton
                key={agreement.id}
                isActive={selectedAgreementId === agreement.id}
                agreement={agreement}
                minimized
              />
            ))}
          </Stack>
        </>
      ) : (
        <>
          {newPrivateAgreements.length > 0 && (
            <Stack py={2} gap={2}>
              <Text size="sm" fontWeight="medium">
                <FormattedMessage
                  defaultMessage="From Email"
                  description="The title for the private agreements section"
                  id="legalAgreementEditorDrawer.list.privateAgreements"
                />{" "}
                <Tooltip
                  pointerEvents="all"
                  shouldWrapChildren
                  closeDelay={1000}
                  label={
                    <Box padding={1}>
                      <FormattedMessage
                        id="agreements.private.tooltip"
                        description="Label shown on private agreements section"
                        defaultMessage="Email agreements are visible to users and their delegates."
                      />{" "}
                      <Link to="/settings/contract-collector" textDecoration="underline" textUnderlineOffset="3px">
                        {intl.formatMessage({
                          id: "agreements.private.tooltip.link",
                          description: "Link to private agreements section",
                          defaultMessage: "Configure Delegates",
                        })}
                      </Link>
                    </Box>
                  }
                >
                  <Icon as={LockIcon} />
                </Tooltip>
              </Text>
              <Stack gap={1}>
                {newPrivateAgreements.map((agreement) => (
                  <AgreementButton
                    key={agreement.id}
                    isActive={selectedAgreementId ? selectedAgreementId === agreement.id : false}
                    agreement={agreement}
                  />
                ))}
              </Stack>
            </Stack>
          )}
          {newPublicAgreements.length > 0 && (
            <Stack py={2} gap={2}>
              <Text size="sm" fontWeight="medium">
                <FormattedMessage
                  defaultMessage="New"
                  description="The title for the new agreements section"
                  id="legalAgreementEditorDrawer.list.newAgreements"
                />
              </Text>
              <Stack gap={1}>
                {newPublicAgreements.map((agreement) => (
                  <AgreementButton
                    key={agreement.id}
                    isActive={selectedAgreementId ? selectedAgreementId === agreement.id : false}
                    agreement={agreement}
                  />
                ))}
              </Stack>
            </Stack>
          )}
          {inReviewAgreements.length > 0 && (
            <Stack py={2} gap={2}>
              <Text size="sm" fontWeight="medium">
                <FormattedMessage
                  defaultMessage="In Review"
                  description="The title for the in review agreements section"
                  id="legalAgreementEditorDrawer.list.inReviewAgreements"
                />
              </Text>
              <Stack gap={1}>
                {inReviewAgreements.map((agreement) => (
                  <AgreementButton
                    key={agreement.id}
                    isActive={selectedAgreementId ? selectedAgreementId === agreement.id : false}
                    agreement={agreement}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        </>
      )}
    </Stack>
  )
}

const AgreementButton = ({
  agreement,
  isActive,
  minimized,
}: {
  agreement: LegalAgreementListItem
  isActive: boolean
  minimized?: boolean
}) => {
  const ref = useRef<HTMLButtonElement>(null)
  const location = useLocation()

  useEffect(() => {
    if (isActive && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      })
    }
  }, [isActive])

  return minimized ? (
    <Tooltip label={<Text m={1}>{agreement.display_name}</Text>} placement="right" hasArrow>
      <IconButton
        ref={ref}
        borderRadius="full"
        aria-label={agreement.display_name}
        icon={<ToolLogo boxSize={6} logo={getPublicImageGcsUrl(agreement.tools[0]?.image_asset?.gcs_file_name)} />}
        height={8}
        isActive={isActive}
        as={Link}
        variant="hoverOutline"
        justifyContent="center"
        to={pathForLegalAgreement(agreement.id, true)}
        state={location.state}
      />
    </Tooltip>
  ) : (
    <Button
      ref={ref}
      isActive={isActive}
      alignItems="center"
      gap={2}
      as={Link}
      variant="ghost"
      justifyContent="start"
      fontWeight="medium"
      to={pathForLegalAgreement(agreement.id, true)}
      state={location.state}
    >
      <ToolLogo boxSize={6} logo={getPublicImageGcsUrl(agreement.tools[0]?.image_asset?.gcs_file_name)} />
      <OverflownText>{agreement.display_name}</OverflownText>
    </Button>
  )
}
