import type { FilterField, SavedView } from "@brm/schema-types/types.js"
import type { Filter } from "@brm/type-helpers/filters.js"
import { Stack } from "@chakra-ui/react"
import { useFlags } from "launchdarkly-react-client-sdk"
import { type PostTransactionV1ListApiArg } from "../app/services/generated-api.js"
import { TRANSACTIONS_TABLE_ID } from "../features/transaction/constants.js"
import TransactionList from "../features/transaction/TransactionList.js"
import { TABLE_DEFAULT_PARAMS } from "../util/schema-table.js"
import { useObjectSchema } from "../util/use-schema.js"
import CardList from "./CardList.js"
import { useLocalStorageTableParamsSync, useUrlTableParams } from "./DataTable/use-schema-table-params.js"

const savedViews: SavedView[] = []

export const Payments = ({ toolId, vendorId }: Pick<PostTransactionV1ListApiArg, "toolId" | "vendorId">) => {
  useLocalStorageTableParamsSync(TRANSACTIONS_TABLE_ID)
  const { createRampCreditCardEnabled } = useFlags()
  const transactionSchema = useObjectSchema("ReconciledTransaction")
  const { tableParams, updateTableParams } = useUrlTableParams<string>({
    defaultParams: TABLE_DEFAULT_PARAMS,
    objectSchema: transactionSchema,
    savedViews,
  })

  if (!tableParams || !updateTableParams) {
    return null
  }

  return (
    <Stack>
      {createRampCreditCardEnabled && (
        <CardList
          toolId={toolId}
          vendorId={vendorId}
          filterTransactions={(id) => {
            updateTableParams?.({
              filterMap: new Map([
                [
                  "card.id",
                  {
                    column: "card.id",
                    fields: { comparator: "any", includeNull: false, values: [id] },
                  } satisfies Filter<string, FilterField>,
                ],
              ]),
            })
          }}
        />
      )}
      <TransactionList
        toolId={toolId}
        vendorId={vendorId}
        tableParams={tableParams}
        updateTableParams={updateTableParams}
      />
    </Stack>
  )
}
