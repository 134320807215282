import { isDocumentOrURLStringType, isDocumentType } from "@brm/schema-helpers/schema.js"
import type { FieldChangeTimelineEvent, WorkflowRunMinimal } from "@brm/schema-types/types.js"
import { mapBy } from "@brm/util/collections.js"
import { isEnumArrayType } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { type ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { displayWorkflowKind } from "../../features/workflows/util.js"
import { isLegalClausesType, isObjectArrayType, isWorkflowRunType } from "../../util/json-schema.js"
import { log } from "../../util/logger.js"
import ArrayDiffFieldTimelineEvent from "./FieldChange/ArrayDiffFieldTimelineEvent.js"
import DocumentFieldChangeEvent from "./FieldChange/DocumentFieldChangeEvent.js"
import LegalClausesFieldChangeEvent from "./FieldChange/LegalClausesFieldChangeEvent.js"
import { ValueChangeFieldTimelineEvent } from "./FieldChange/ValueChangeFieldTimelineEvent.js"

export const FieldChangeFieldTimelineEvent = ({
  event,
  actor,
  objectLink,
  objectSchema,
  fieldSchema,
  fieldLabel,
  showFieldName,
}: {
  event: FieldChangeTimelineEvent
  actor: ReactElement
  objectLink?: ReactElement
  objectSchema: ReadonlyDeep<JSONSchema> | undefined
  fieldSchema: ReadonlyDeep<JSONSchema> | undefined
  fieldLabel: ReactElement | null
  showFieldName?: boolean
}) => {
  const fieldPath: readonly string[] = event.is_custom_field ? ["custom", event.field_name] : [event.field_name]

  if (event.new_value === null || event.new_value === undefined) {
    if (!showFieldName) {
      return objectLink ? (
        <FormattedMessage
          defaultMessage="{actor} cleared the field on {objectLink}"
          description="Subtitle of a field change event where the value has been removed"
          id="timeline.field_change.removed.subheading.withLink"
          values={{ actor, objectLink }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="{actor} cleared the field"
          description="Subtitle of a field change event where the value has been removed"
          id="timeline.field_change.removed.subheading"
          values={{ actor }}
        />
      )
    }
    return objectLink ? (
      <FormattedMessage
        defaultMessage="{actor} cleared {fieldLabel} on {objectLink}"
        description="Subtitle of a field change event where the value has been removed"
        id="timeline.field_change.removed.subheading.withField.withLink"
        values={{ actor, fieldLabel, objectLink }}
      />
    ) : (
      <FormattedMessage
        defaultMessage="{actor} cleared {fieldLabel}"
        description="Subtitle of a field change event where the value has been removed"
        id="timeline.field_change.removed.subheading.withField"
        values={{ actor, fieldLabel }}
      />
    )
  }

  if (
    isWorkflowRunType(objectSchema, event.object_value) &&
    event.field_name === ("status" satisfies keyof WorkflowRunMinimal)
  ) {
    if (!event.object_value) {
      log.error("Unexpected workflow_run_closed event with missing object_value", { event })
      return null
    }
    const requestType = displayWorkflowKind(event.object_value.kind)
    switch (event.new_value) {
      case "approved":
        return (
          <FormattedMessage
            defaultMessage="{requestType} request was approved by {actor}!"
            description="Subtitle of a workflow run closed event for an approved request on the field timeline"
            id="timeline.workflow_run_closed.detail.approved"
            values={{ requestType, actor }}
          />
        )
      case "cancelled":
        return (
          <FormattedMessage
            defaultMessage="{requestType} request was cancelled by {actor}"
            description="Subtitle of a workflow run closed event for a cancelled request on the field timeline"
            id="timeline.workflow_run_closed.detail.cancelled"
            values={{ requestType, actor }}
          />
        )

      case "rejected":
        return (
          <FormattedMessage
            defaultMessage="{requestType} request was rejected by {actor}"
            description="Subtitle of a workflow run closed event for a rejected request on the field timeline"
            id="timeline.workflow_run_closed.detail.rejected"
            values={{ requestType, actor }}
          />
        )
      case "in_progress":
        log.error("Unexpected workflow_run_closed event with value: in_progress", { event })
        return null
      default:
        log.error("Unexpected workflow_run_closed event status value", { event })
        return null
    }
  }

  // Rest of events need a field label
  if (!fieldLabel) {
    log.error("FieldChangeFieldTimelineEvent: fieldLabel expected", { event, fieldLabel })
    return null
  }

  if (fieldSchema === undefined) {
    log.error("FieldChangeFieldTimelineEvent: fieldSchema expected", { event, fieldSchema })
    return null
  }

  if (
    isEnumArrayType({ schema: fieldSchema, value: event.old_value }) &&
    isEnumArrayType({ schema: fieldSchema, value: event.new_value })
  ) {
    // Handles multi-select adding and removing fields
    const oldValues = new Set((event.old_value || []) as string[])
    const newValues = new Set((event.new_value || []) as string[])
    const addedValues = Array.from(newValues.difference(oldValues))
    const removedValues = Array.from(oldValues.difference(newValues))
    return (
      <ArrayDiffFieldTimelineEvent
        addedValues={addedValues}
        removedValues={removedValues}
        objectLink={objectLink}
        actor={actor}
        fieldSchema={fieldSchema}
        fieldLabel={fieldLabel}
        fieldPath={fieldPath}
        showFieldName={showFieldName}
      />
    )
  }

  if (isObjectArrayType(fieldSchema, event.old_value) && isObjectArrayType(fieldSchema, event.new_value)) {
    const oldValuesMap = event.old_value ? mapBy(event.old_value, (item) => item.id) : new Map<string, { id: string }>()
    const newValuesMap = event.new_value ? mapBy(event.new_value, (item) => item.id) : new Map<string, { id: string }>()
    const addedValues = Array.from(newValuesMap.values()).filter((obj) => !oldValuesMap.get(obj.id))
    const removedValues = Array.from(oldValuesMap.values()).filter((obj) => !newValuesMap.get(obj.id))
    return (
      <ArrayDiffFieldTimelineEvent
        actor={actor}
        addedValues={addedValues}
        removedValues={removedValues}
        objectLink={objectLink}
        fieldSchema={fieldSchema}
        fieldLabel={fieldLabel}
        fieldPath={fieldPath}
        showFieldName={showFieldName}
      />
    )
  }

  if (isLegalClausesType(fieldSchema, event.old_value) && isLegalClausesType(fieldSchema, event.new_value)) {
    return (
      <LegalClausesFieldChangeEvent
        oldClauses={event.old_value}
        newClauses={event.new_value}
        objectLink={objectLink}
        actor={actor}
        fieldLabel={fieldLabel}
        showFieldName={showFieldName}
      />
    )
  }

  if (isDocumentType(fieldSchema, event.new_value)) {
    return (
      <DocumentFieldChangeEvent
        actor={actor}
        fieldLabel={fieldLabel}
        objectLink={objectLink}
        showFieldName={showFieldName}
      />
    )
  }

  if (isDocumentOrURLStringType(fieldSchema, event.new_value)) {
    if (isObject(event.new_value)) {
      return (
        <DocumentFieldChangeEvent
          actor={actor}
          fieldLabel={fieldLabel}
          objectLink={objectLink}
          showFieldName={showFieldName}
        />
      )
    }
    if (typeof event.new_value === "string") {
      return (
        <ValueChangeFieldTimelineEvent
          actor={actor}
          oldValue={event.old_value}
          newValue={event.new_value}
          objectLink={objectLink}
          fieldSchema={fieldSchema}
          fieldLabel={fieldLabel}
          fieldPath={fieldPath}
          showFieldName={showFieldName}
        />
      )
    }
  }
  return (
    <ValueChangeFieldTimelineEvent
      actor={actor}
      oldValue={event.old_value}
      newValue={event.new_value}
      objectLink={objectLink}
      fieldSchema={fieldSchema}
      fieldLabel={fieldLabel}
      fieldPath={fieldPath}
      showFieldName={showFieldName}
    />
  )
}
