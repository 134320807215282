import { isToolDocumentClassification, isVendorDocumentClassification } from "@brm/schema-helpers/document.js"
import {
  type DocumentMinimal,
  type DocumentWithExtraction,
  type FieldMetadataWithSuggestions,
  type LegalAgreementWithMinimalRelations,
  type MoveDocumentAction,
} from "@brm/schema-types/types.js"
import {
  acceptedDocumentExtensions,
  acceptedDocumentExtensionsByMimeType,
  maxDocumentSize,
} from "@brm/type-helpers/document.js"
import { mapBy } from "@brm/util/collections.js"
import { Flags } from "@brm/util/flags.js"
import { isEmpty } from "@brm/util/type-guard.js"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useDisclosure,
  useToast,
  type FlexProps,
  type StyleProps,
} from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useFlags } from "launchdarkly-react-client-sdk"
import pMap from "p-map"
import type { ReactNode } from "react"
import { forwardRef, useCallback, useEffect, useRef, useState } from "react"
import { ErrorCode, useDropzone } from "react-dropzone"
import { FormattedList, FormattedMessage, useIntl } from "react-intl"
import type { To } from "react-router-dom"
import { useNavigate, useParams } from "react-router-dom"
import {
  useGetLegalV1AgreementsPickerOptionsByQueryQuery,
  usePostDocumentV1ByIdRetryExtractionMutation,
  usePostDocumentV1Mutation,
  usePutDocumentV1ByIdMutation,
} from "../../app/services/generated-api.js"
import { getNewOptionsByDocumentId } from "../../util/form.js"
import { log } from "../../util/logger.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { ListBox, ListBoxItem } from "../ListBox.js"
import { ListBoxWithInput } from "../ListBoxWithInput.js"
import { ToolLogo } from "../icons/Logo.js"
import {
  BackIcon,
  CheckIcon,
  InfoIcon,
  MoreMenuIcon,
  MoveDocumentIcon,
  PlusIcon,
  RefreshIcon,
  TrashIcon,
  UploadIcon,
} from "../icons/icons.js"
import { onDropRejected } from "../on-drop-rejected.js"
import { DocumentDisplay } from "./DocumentDisplay.js"
import { createAndUploadDocument, getContentHash } from "./util.js"

/**
 * @param documents The updated full list of all documents that were uploaded so far.
 * @param type What caused the change (whether a document was added or removed).
 * @param document The document that was added or removed to the list.
 */
export type DocumentChangeHandler = (
  documents: DocumentMinimal[],
  type: "add" | "remove",
  document: DocumentMinimal
) => void

export interface DocumentUploadProps {
  value: DocumentWithExtraction[]
  onChange?: DocumentChangeHandler
  processingDocuments?: Partial<Record<string, boolean>>
  isReadOnly?: boolean
  dropzoneText?: ReactNode
  multiple?: boolean
  maxFiles?: number
  /** Get link destination to navigate to when clicking on a document */
  getLinkDestination?: (document: DocumentMinimal) => To
  selectedDocument?: Pick<DocumentMinimal, "id">
  onMoveDocument?: (documentId: string, moveAction: MoveDocumentAction) => void
  /** Existing legalAgreement linked to the document */
  legalAgreement?: Pick<LegalAgreementWithMinimalRelations, "id" | "vendor" | "tools">
  dropzoneProps?: StyleProps
  dedupeDocumentByHash?: boolean
  onDocumentClick?: (document: DocumentMinimal) => void
  fieldsMetadataList?: FieldMetadataWithSuggestions[]
}

/**
 * Component that renders a drop zone for document uploads. When a file is dropped, a document is created through
 * the API, uploaded to GCS, marked as uploaded and finally the document API object is returned.
 */
export const DocumentUpload = forwardRef<HTMLButtonElement, DocumentUploadProps>(function DocumentUpload(
  {
    value: uploadedDocuments,
    onChange,
    processingDocuments,
    isReadOnly,
    dropzoneText,
    multiple,
    getLinkDestination,
    maxFiles = multiple ? 10 : 1,
    selectedDocument,
    onMoveDocument,
    legalAgreement,
    dedupeDocumentByHash,
    dropzoneProps,
    onDocumentClick,
    fieldsMetadataList,
  }: DocumentUploadProps,
  ref
) {
  const toast = useToast()
  const navigate = useNavigate()

  const { code } = useParams<{ code: string }>()
  const [uploadingFiles, setUploadingFiles] = useState<ReadonlyMap<File, "uploading" | "error">>(new Map())

  const [createDocument] = usePostDocumentV1Mutation()
  const [markUploaded] = usePutDocumentV1ByIdMutation()
  const [retryExtraction] = usePostDocumentV1ByIdRetryExtractionMutation()

  // Store a ref of the uploaded documents so that the onDropAccepted handler can access the latest value instead
  // of running into the stale closure problem
  const uploadedDocumentsRef = useRef(uploadedDocuments)
  useEffect(() => {
    uploadedDocumentsRef.current = uploadedDocuments
  }, [uploadedDocuments])
  const dropzone = useDropzone({
    accept: acceptedDocumentExtensionsByMimeType,
    multiple,
    maxFiles,
    maxSize: maxDocumentSize,
    noClick: true,
    noKeyboard: true,
    disabled: isReadOnly,
    onDropRejected: onDropRejected(toast, { maxFiles }),
    onDropAccepted: async (acceptedFiles) => {
      if (!acceptedFiles.length) {
        return
      }
      const documentNames = mapBy(uploadedDocuments, (d) => d.file_name)
      const fileNames = mapBy(uploadingFiles.keys(), (d) => d.name)
      const duplicateFile = acceptedFiles.find((file) => documentNames.has(file.name) || fileNames.has(file.name))
      if (duplicateFile) {
        toast({
          status: "warning",
          duration: null,
          isClosable: true,
          description: (
            <FormattedMessage
              defaultMessage="{fileName} is already uploaded. Please upload a different file."
              description="The warning toast when duplicate files are uploaded"
              id="documentUpload.dropzone.uploadWarning.duplicateFiles"
              values={{ fileName: <code>{duplicateFile?.name}</code> }}
            />
          ),
        })
      }
      await pMap(
        acceptedFiles
          // Don't allow uploading two files with the same file name
          .filter((file) => !documentNames.has(file.name) && !fileNames.has(file.name)),
        async (file) => {
          setUploadingFiles((states) => new Map([...(multiple ? states : []), [file, "uploading"] as const]))
          try {
            const contentHash = dedupeDocumentByHash ? await getContentHash(file) : undefined
            const createdDocumentResponse = await createDocument({
              documentInput: {
                link_code: code,
                file_name: file.name,
                file_size: file.size,
                mime_type: file.type,
                content_hash: contentHash,
              },
            }).unwrap()

            const uploadedDocument = await createAndUploadDocument({
              file,
              createdDocument: createdDocumentResponse,
              onUpload: async () =>
                await markUploaded({
                  id: createdDocumentResponse.id,
                  body: { status: "uploaded", link_code: code },
                }).unwrap(),
              onQuarantined: (file) => {
                setUploadingFiles((states) => new Map([...states, [file, "error"] as const]))
              },
            })

            setUploadingFiles((states) => {
              const copy = new Map(states)
              copy.delete(file)
              return copy
            })
            if (uploadedDocument) {
              onChange?.([...(multiple ? uploadedDocumentsRef.current : []), uploadedDocument], "add", uploadedDocument)
            }
          } catch (err) {
            setUploadingFiles((states) => new Map([...states, [file, "error"] as const]))
            log.error("Error uploading file", err, { file })
            toast({
              status: "error",
              duration: null,
              description: (
                <FormattedMessage
                  defaultMessage="Error uploading document {fileName}"
                  description="The title for the error toast when uploading a file fails"
                  id="documentUpload.dropzone.uploadError.title"
                  values={{ fileName: <code>{file.name}</code> }}
                />
              ),
            })
          }
        },
        { concurrency: 5 }
      )
    },
  })

  const onRemoveDocument = useCallback(
    (document: DocumentMinimal) => {
      onChange?.(
        uploadedDocuments.filter((d) => d.id !== document.id),
        "remove",
        document
      )
    },
    [onChange, uploadedDocuments]
  )

  const onRetryExtraction = useCallback(
    async (document: DocumentMinimal) => {
      await retryExtraction({ id: document.id }).unwrap()
    },
    [retryExtraction]
  )

  if (!multiple && uploadedDocuments.length > 1) {
    throw new Error("DocumentUpload: multiple is false but multiple documents were given")
  }

  return (
    <>
      <Flex direction="column" gap={3}>
        {[...uploadedDocuments, ...uploadingFiles.keys()].map((document) => {
          if (!multiple && uploadingFiles.size && !(document instanceof File)) {
            // If in single file mode and we're currently uploading a new one, don't show the existing document
            return null
          }
          const isError =
            (document instanceof File && uploadingFiles.get(document) === "error") ||
            ("object_type" in document && document.extraction_status === "failed")
          const isSelected = !(document instanceof File) && !!document?.id && document.id === selectedDocument?.id
          const isUploading =
            document instanceof File
              ? uploadingFiles.get(document) === "uploading"
              : !!processingDocuments?.[document.id]
          const newExtractedFields =
            !(document instanceof File) && fieldsMetadataList
              ? getNewOptionsByDocumentId(document.id, fieldsMetadataList)
              : []
          return (
            <DocumentItem
              newExtractedFieldCount={newExtractedFields.length ?? 0}
              isSelected={isSelected}
              legalAgreement={legalAgreement}
              to={document instanceof File ? undefined : getLinkDestination?.(document)}
              isError={isError}
              isUploading={isUploading}
              document={document}
              key={document instanceof File ? document.name : document.id}
              onRetryExtraction={() => !(document instanceof File) && onRetryExtraction(document)}
              onRemoveDocument={() => !(document instanceof File) && onRemoveDocument(document)}
              onMoveDocument={
                onMoveDocument && !(document instanceof File)
                  ? (moveAction: MoveDocumentAction) => onMoveDocument(document.id, moveAction)
                  : undefined
              }
              _hover={{
                outlineColor:
                  multiple && getLinkDestination && (isError ? "error.400" : isSelected ? "brand.600" : "brand.300"),
                backgroundColor: isError ? "error.50" : "transparent",
                cursor: multiple && "pointer",
              }}
              onClick={() => {
                if (onDocumentClick && !(document instanceof File)) {
                  onDocumentClick(document)
                }
                if (getLinkDestination && !(document instanceof File)) {
                  const documentPath = getLinkDestination(document)
                  if (typeof documentPath !== "string") {
                    navigate(documentPath)
                  }
                }
              }}
            />
          )
        })}
        {(multiple || (isEmpty(uploadedDocuments) && uploadingFiles.size === 0)) && (
          <Flex {...dropzone.getRootProps()}>
            <input {...dropzone.getInputProps()} />
            <Button
              flex={1}
              fontWeight="normal"
              height="auto"
              py={3}
              px={6}
              display="flex"
              placeContent="center"
              alignItems="center"
              color="gray.600"
              borderColor={dropzone.isDragAccept ? "brand.600" : "gray.200"}
              borderStyle="dashed"
              borderWidth={dropzone.isDragAccept ? 2 : 1}
              borderRadius="xl"
              isDisabled={isReadOnly}
              background="white"
              onClick={dropzone.open}
              ref={ref}
              boxSizing="border-box"
              {...dropzoneProps}
            >
              {dropzoneText || (
                <HStack>
                  <Icon as={UploadIcon} />
                  <Text>
                    <FormattedMessage
                      defaultMessage="<strong>Click to upload</strong>"
                      description="The label for the document upload field in the document upload dropzone"
                      id="documentUpload.dropzone.text"
                    />
                  </Text>
                </HStack>
              )}
            </Button>
          </Flex>
        )}
      </Flex>
      {dropzone.fileRejections.map((fileRejection) =>
        fileRejection.errors.map((error) => (
          <Alert status="error" key={fileRejection.file.name}>
            <AlertIcon />
            <AlertDescription>
              {error.code === ErrorCode.FileInvalidType ? (
                <FormattedMessage
                  id="documentUpload.unsupportedFileTypeError"
                  defaultMessage="File type .{fileExtension} is not supported. Please upload a file of type {supportedFileTypes}."
                  description="The error message for the document upload modal when the file type is unsupported"
                  values={{
                    fileExtension: fileRejection.file.name.split(".").pop(),
                    supportedFileTypes: <FormattedList value={acceptedDocumentExtensions} type="disjunction" />,
                  }}
                />
              ) : (
                error.message
              )}
            </AlertDescription>
          </Alert>
        ))
      )}
    </>
  )
})

/**
 * Document actions to retry extraction, delete, or move documents. Move documents is only supported in SOR surface areas (eg. agreements)
 */
const DocumentActions = ({
  onRetryExtraction,
  onRemoveDocument,
  onMoveDocument,
  legalAgreementId,
  isRetryable,
}: {
  onRetryExtraction: () => void
  onRemoveDocument: () => void
  onMoveDocument?: (moveAction: MoveDocumentAction) => void
  /** Tells the move document action which agreement is currently selected */
  legalAgreementId?: string
  isRetryable: boolean
}) => {
  const intl = useIntl()
  const [moveDocumentMenu, setMoveDocumentMenu] = useState(false)
  const [agreementSearch, setAgreementSearch] = useState("")
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data } = useGetLegalV1AgreementsPickerOptionsByQueryQuery(
    onMoveDocument ? { query: agreementSearch } : skipToken
  )

  const createAgreementLabel = intl.formatMessage({
    id: "documentUpload.dropzone.documentActions.createAgreementLabel",
    defaultMessage: "Create new agreement",
    description: "The label for the create agreement button in the document upload dropzone",
  })

  const retryExtractionLabel = intl.formatMessage({
    id: "documentUpload.dropzone.documentActions.retryExtractionLabel",
    defaultMessage: "Retry extraction",
    description: "The label for the retry extraction button in the document upload dropzone",
  })

  const moveDocumentLabel = intl.formatMessage({
    id: "documentUpload.dropzone.documentActions.moveDocumentLabel",
    defaultMessage: "Move document",
    description: "The label for the move document button in the document upload dropzone",
  })

  const removeDocumentLabel = intl.formatMessage({
    id: "documentUpload.dropzone.documentActions.removeDocumentLabel",
    defaultMessage: "Remove document",
    description: "The label for the remove document button in the document upload dropzone",
  })

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setMoveDocumentMenu(false)
      }}
      onOpen={onOpen}
      placement="bottom-end"
      autoFocus
    >
      <PopoverTrigger>
        <IconButton
          icon={<Icon as={MoreMenuIcon} color="gray.600" boxSize={5} />}
          aria-label={intl.formatMessage({
            id: "documentUpload.dropzone.documentActions.ariaLabel",
            defaultMessage: "Document actions",
            description: "The aria label for the document actions button in the document upload dropzone",
          })}
          size="xs"
          variant="ghost"
          padding={1}
          height="fit-content"
          onClick={(e) => e.stopPropagation()}
        />
      </PopoverTrigger>
      {/* Fix width for agreement search menu so it does not shift around */}
      <Portal>
        <PopoverContent width={moveDocumentMenu ? "350px" : "auto"}>
          {moveDocumentMenu && onMoveDocument ? (
            <ListBoxWithInput
              ariaLabel={intl.formatMessage({
                id: "documentUpload.dropzone.documentActions.moveToAgreement.ariaLabel",
                defaultMessage: "Move to agreement",
                description: "The aria label for the move to agreement input in the document actions list",
              })}
              value={agreementSearch}
              onChange={(e) => setAgreementSearch(e.target.value)}
              placeholder={intl.formatMessage({
                id: "documentUpload.dropzone.documentActions.moveToAgreement.input.placeholder",
                defaultMessage: "Move to agreement",
                description: "The label for the move to document input in the document actions list",
              })}
              iconButton={
                <IconButton
                  onClick={() => setMoveDocumentMenu(false)}
                  icon={<Icon as={BackIcon} color="gray.600" boxSize={5} />}
                  aria-label={intl.formatMessage({
                    id: "documentUpload.dropzone.documentActions.backArrow",
                    defaultMessage: "Back to document actions",
                    description: "The aria label for the  back arrow icon to navigate back to document actions list",
                  })}
                  variant="unstyled"
                />
              }
            >
              {data?.map((agreement) => (
                <ListBoxItem
                  key={agreement.id}
                  textValue={agreement.display_name}
                  onAction={() => {
                    if (!legalAgreementId || agreement.id !== legalAgreementId) {
                      onMoveDocument({ legal_agreement_id: agreement.id, action: "add_to_agreement" })
                    }
                    onClose()
                  }}
                  gap={2}
                  justifyContent="space-between"
                >
                  <HStack textOverflow="ellipsis" overflow="hidden">
                    <ToolLogo
                      boxSize={4}
                      logo={getPublicImageGcsUrl(agreement.tools?.[0]?.image_asset?.gcs_file_name)}
                    />
                    <Text isTruncated>{agreement.display_name}</Text>
                  </HStack>
                  {legalAgreementId && agreement.id === legalAgreementId && (
                    <Icon as={CheckIcon} color="brand.600" boxSize={5} />
                  )}
                </ListBoxItem>
              ))}
              <ListBoxItem
                key="moveDocumentMenu.createNewAgreement"
                textValue={createAgreementLabel}
                onAction={() => {
                  onMoveDocument({ display_name: agreementSearch.trim(), action: "create_agreement" })
                  onClose()
                  setMoveDocumentMenu(false)
                }}
                gap={1}
                alignItems="center"
              >
                <Icon as={PlusIcon} color="gray.600" boxSize={5} />
                {createAgreementLabel}
              </ListBoxItem>
            </ListBoxWithInput>
          ) : (
            <ListBox
              autoFocus
              maxHeight="300px"
              overflowY="auto"
              aria-label={intl.formatMessage({
                id: "documentUpload.dropzone.documentActions.listBox.ariaLabel",
                defaultMessage: "Document actions menu",
                description: "The aria label for the document actions list",
              })}
            >
              <ListBoxItem
                key="documentUpload.dropzone.documentActions.retryExtraction"
                textValue={retryExtractionLabel}
                onAction={() => {
                  onRetryExtraction()
                  onClose()
                }}
                gap={2}
                isDisabled={!isRetryable}
              >
                <Icon as={RefreshIcon} color="gray.500" />
                {retryExtractionLabel}
              </ListBoxItem>
              {onMoveDocument && (
                <ListBoxItem
                  key="documentUpload.moveDocument"
                  textValue={moveDocumentLabel}
                  onAction={() => setMoveDocumentMenu(true)}
                  gap={2}
                >
                  <Icon as={MoveDocumentIcon} color="gray.500" />
                  {moveDocumentLabel}
                </ListBoxItem>
              )}
              <ListBoxItem
                key="documentUpload.removeDocument"
                textValue={removeDocumentLabel}
                onAction={() => {
                  onRemoveDocument()
                  onClose()
                }}
                gap={2}
              >
                <Icon as={TrashIcon} color="gray.500" />
                {removeDocumentLabel}
              </ListBoxItem>
            </ListBox>
          )}
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
const DocumentItem = ({
  document,
  isSelected,
  isReadOnly,
  isUploading,
  isError,
  onRetryExtraction,
  onRemoveDocument,
  onMoveDocument,
  legalAgreement,
  onClick,
  to,
  newExtractedFieldCount,
  ...props
}: {
  document: File | DocumentWithExtraction
  isSelected?: boolean
  isReadOnly?: boolean
  isUploading?: boolean
  isError?: boolean
  onRetryExtraction: (document: DocumentMinimal) => void
  onRemoveDocument: (document: DocumentMinimal) => void
  onMoveDocument?: (moveAction: MoveDocumentAction) => Promise<void> | void
  legalAgreement?: Pick<LegalAgreementWithMinimalRelations, "id" | "vendor" | "tools">
  onClick?: (document: File | DocumentWithExtraction) => void
  to?: To
  newExtractedFieldCount?: number
} & FlexProps) => {
  const { [Flags.MOVE_DOCUMENT_TO_SOR_FLAG]: moveDocumentToSor } = useFlags()
  const [isMoving, setIsMoving] = useState(false)
  // Is only retryable if document is completed/failed
  const isRetryable =
    "object_type" in document && (document.extraction_status === "failed" || document.extraction_status === "completed")
  const vendor = legalAgreement?.vendor
  const tool = legalAgreement?.tools?.[0]

  return (
    <Stack>
      <Flex
        key={document instanceof File ? document.name : document.id}
        height="auto"
        p={3}
        gap={1}
        background={isError ? "error.50" : undefined}
        outline={`${isSelected ? "2px" : "1px"} solid`}
        outlineColor={isError ? "error.500" : isSelected ? "brand.600" : "gray.300"}
        border="none"
        borderRadius="xl"
        boxSizing="border-box"
        alignItems="center"
        justifyContent="space-between"
        fontWeight="normal"
        onClick={() => onClick?.(document)}
        {...props}
      >
        <DocumentDisplay
          document={document}
          isError={isError}
          isUploading={isUploading || isMoving}
          to={to}
          newExtractedFieldCount={newExtractedFieldCount}
          onRetryExtraction={document instanceof File ? undefined : () => onRetryExtraction(document)}
        />

        {!(document instanceof File) && !isReadOnly && !isUploading && !isMoving && (
          <DocumentActions
            isRetryable={isRetryable}
            legalAgreementId={legalAgreement?.id}
            onRetryExtraction={() => onRetryExtraction(document)}
            onRemoveDocument={() => onRemoveDocument(document)}
            onMoveDocument={
              onMoveDocument
                ? async (moveAction) => {
                    setIsMoving(true)
                    await onMoveDocument(moveAction)
                    setIsMoving(false)
                  }
                : undefined
            }
          />
        )}
      </Flex>
      {moveDocumentToSor &&
        onMoveDocument &&
        legalAgreement &&
        "object_type" in document &&
        document.document_classification &&
        (isToolDocumentClassification(document.document_classification) && tool ? (
          <>
            <HStack color="warning.700">
              <Icon as={InfoIcon} />
              <Text as="span">
                <FormattedMessage
                  id="documentUpload.moveDocument.toolDocumentDescription"
                  description="Description when a tool document is uploaded as a legal agreement document"
                  defaultMessage="{document_name} appears to be a tool document. We recommend storing it on the tool page for easier access and organization."
                  values={{ document_name: document.file_name }}
                />
              </Text>
            </HStack>
            <Button
              variant="link"
              width="fit-content"
              onClick={async () => {
                setIsMoving(true)
                await onMoveDocument({ action: "add_to_tool", tool_id: tool.id })
                setIsMoving(false)
              }}
            >
              <FormattedMessage
                id="documentUpload.moveDocument.buttonText.toolDocument"
                description="Description for the tool document move action"
                defaultMessage="Move to tool page"
              />
            </Button>
          </>
        ) : isVendorDocumentClassification(document.document_classification) && vendor ? (
          <>
            <HStack color="warning.700">
              <Icon as={InfoIcon} />
              <Text as="span">
                <FormattedMessage
                  id="documentUpload.moveDocument.vendorDocumentDescription"
                  description="Description when a vendor document is uploaded as a legal agreement document"
                  defaultMessage="{document_name} appears to be a vendor document. We recommend storing it on the vendor page for easier access and organization."
                  values={{ document_name: document.file_name }}
                />
              </Text>
            </HStack>
            <Button
              color="brand.600"
              variant="link"
              width="fit-content"
              onClick={async () => {
                setIsMoving(true)
                await onMoveDocument({ action: "add_to_vendor", vendor_id: vendor.id })
                setIsMoving(false)
              }}
            >
              <FormattedMessage
                id="documentUpload.moveDocument.buttonText.vendorDocument"
                description="Description for the vendor document move action"
                defaultMessage="Move to vendor page"
              />
            </Button>
          </>
        ) : null)}
    </Stack>
  )
}
