import type {
  EmployeeGroup,
  FieldCategory,
  PersonPicker,
  Tool,
  ToolOption,
  UserPicker,
  Vendor,
  VendorOption,
  WorkflowKind,
} from "@brm/schema-types/types.js"
import { Temporal } from "@js-temporal/polyfill"
import type { IntlShape } from "react-intl"
import { getProgress } from "../../../../util/progress.js"
import type { LegalAgreementOption } from "../../../legal/LegalAgreementPicker.js"
import { displayFieldCategory, displayWorkflowKind } from "../../util.js"

export type WorkflowObjectType = Tool["object_type"] | Vendor["object_type"]
interface LinkInviteRecipient {
  first_name: string
  last_name: string
  email: string
}

export interface StartWorkflowFormState {
  tool: ToolOption | null
  vendor: VendorOption | null
  tool_or_vendor_owner: PersonPicker | null
  display_name: string
  workflow_owner: UserPicker | null
  departments: EmployeeGroup[]
  target_date: string | null
  legal_agreement: LegalAgreementOption | null
  new_legal_agreement_name: string | null
  new_tool_or_vendor_url: string | null
  new_tool_or_vendor_display_name: string | null
  recipients: LinkInviteRecipient[]
  message: string | null
  // The due date the buyer wants to communicate to the seller for when to submit link by
  seller_due_date: string | null
  skipped_invites: boolean
  category: FieldCategory | null
}

export type ModalScreens =
  | "loadingLegalAgreement"
  | "workflowSelection"
  | "objectTypeSelection"
  | "vendorSelection"
  | "toolSelection"
  | "legalAgreement"
  | "workflow"
  | "confirmation"
  | "inviteNewUsers"

export const generateWorkflowDisplayName = (
  toolName: string,
  workflowKind: WorkflowKind,
  intl: IntlShape,
  category?: FieldCategory
): string => {
  const formattedDateParts = intl.formatDateToParts(Temporal.Now.instant().toString(), {
    year: "numeric",
    month: "short",
  })
  const month = formattedDateParts.find((d) => d.type === "month")?.value
  const year = formattedDateParts.find((d) => d.type === "year")?.value
  const workflowKindDisplay = displayWorkflowKind(workflowKind)

  if (month && year) {
    if (workflowKind === "gather_data" && category) {
      return `${toolName} ${displayFieldCategory(category)} ${month} ${year}`
    }
    return `${toolName} ${workflowKindDisplay} ${month} ${year}`
  }
  if (workflowKind === "gather_data" && category) {
    return `${toolName} ${displayFieldCategory(category)}`
  }
  return `${toolName} ${workflowKindDisplay}`
}

export const StartPurchaseWorkflowFields: Array<keyof StartWorkflowFormState> = [
  "display_name",
  "departments",
  "tool",
  "workflow_owner",
  "target_date",
]
export const StartRenewalWorkflowFields: Array<keyof StartWorkflowFormState> = [
  "display_name",
  "departments",
  "legal_agreement",
  "workflow_owner",
  "target_date",
  "new_legal_agreement_name",
]

export type WorkflowLegalAgreementSectionFormState = Pick<
  StartWorkflowFormState,
  "legal_agreement" | "display_name" | "tool" | "vendor" | "tool_or_vendor_owner" | "new_legal_agreement_name"
>

export const WorkflowLegalAgreementSectionFields: Array<keyof StartWorkflowFormState> = [
  "legal_agreement",
  "new_legal_agreement_name",
  "tool_or_vendor_owner",
  "tool",
  "vendor",
]

export type WorkflowSectionFormState = Pick<
  StartWorkflowFormState,
  "display_name" | "departments" | "workflow_owner" | "target_date"
>

export const WorkflowSectionFields: Array<keyof StartWorkflowFormState> = [
  "display_name",
  "departments",
  "workflow_owner",
  "target_date",
]

export type WorkflowToolSectionFormState = Pick<
  StartWorkflowFormState,
  "tool" | "tool_or_vendor_owner" | "display_name" | "new_tool_or_vendor_url" | "new_tool_or_vendor_display_name"
>

export const WorkflowToolSectionFields: Array<keyof StartWorkflowFormState> = ["tool", "tool_or_vendor_owner"]

export type WorkflowVendorSectionFormState = Pick<
  StartWorkflowFormState,
  "vendor" | "tool_or_vendor_owner" | "new_tool_or_vendor_url" | "new_tool_or_vendor_display_name"
>

export const WorkflowVendorSectionFields: Array<keyof StartWorkflowFormState> = ["vendor", "tool_or_vendor_owner"]

export type WorkflowInviteSectionFormState = Pick<
  StartWorkflowFormState,
  "recipients" | "message" | "seller_due_date" | "skipped_invites"
>

export const WorkflowInviteSectionFields: Array<keyof StartWorkflowFormState> = [
  "recipients",
  "message",
  "seller_due_date",
]

const GATHER_DATA_STEP_COUNT = 4
const STEP_COUNT = 5
export const getWorkflowProgressPercentage = (
  currentScreen: ModalScreens,
  workflowKind: WorkflowKind | null
): number => {
  if (!workflowKind) {
    return 0
  }
  switch (currentScreen) {
    case "workflowSelection":
      return getProgress(0, 1)
    case "objectTypeSelection":
      return getProgress(1, STEP_COUNT)
    case "vendorSelection":
    case "toolSelection":
      if (workflowKind === "gather_data") {
        return getProgress(1, GATHER_DATA_STEP_COUNT)
      }
      return getProgress(2, STEP_COUNT)
    case "legalAgreement":
      return getProgress(2, STEP_COUNT)
    case "workflow":
      if (workflowKind === "gather_data") {
        return getProgress(2, GATHER_DATA_STEP_COUNT)
      }
      return getProgress(3, STEP_COUNT)
    case "confirmation":
      if (workflowKind === "gather_data") {
        return getProgress(3, GATHER_DATA_STEP_COUNT)
      }
      return getProgress(4, STEP_COUNT)
    case "inviteNewUsers":
      return 100 // Final step for all workflow kinds
    default:
      return 0
  }
}
