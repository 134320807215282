import { Button, HStack, Icon, Stack, Tag, TagLabel, TagLeftIcon, useDisclosure } from "@chakra-ui/react"
import { FormattedMessage, FormattedPlural } from "react-intl"
import { usePostUserV1OrganizationQuery } from "../../app/services/generated-api.js"
import { DotIcon, PlusIcon } from "../../components/icons/icons.js"
import InviteOrUpdateUserModal from "../organization/invites/InviteOrUpdateUserModal.js"
import { SettingsContainer } from "./SettingsContainer.js"
import { SettingsHeader } from "./SettingsHeader.js"
import { UserList } from "./UserList.js"

export default function UsersSettings() {
  const inviteModal = useDisclosure()
  const { data: userList } = usePostUserV1OrganizationQuery({
    body: {
      filter: [
        [
          {
            column: "status",
            fields: {
              comparator: "any",
              values: ["active", "pending"],
            },
          },
        ],
      ],
    },
  })
  const activeCount = userList?.users.filter((u) => u.status === "active").length ?? 0
  const inviteCount = userList?.users.filter((u) => u.status === "pending").length ?? 0

  return (
    <SettingsContainer>
      <SettingsHeader>
        <FormattedMessage
          id="organization.view.users.heading"
          description="Users table heading on organization view page"
          defaultMessage="Users"
        />
      </SettingsHeader>
      <Stack spacing={4}>
        <HStack spacing={8} justify="space-between">
          <HStack>
            <Tag variant="outline">
              <TagLeftIcon boxSize={3} as={DotIcon} color="green.500" width="0.5em" />
              <TagLabel>
                <FormattedPlural
                  value={activeCount}
                  one={
                    <FormattedMessage
                      id="organization.view.users.active.one"
                      description="Number of active users in organization"
                      defaultMessage="{count} active"
                      values={{ count: activeCount }}
                    />
                  }
                  other={
                    <FormattedMessage
                      id="organization.view.users.active.other"
                      description="Number of active users in organization"
                      defaultMessage="{count} active"
                      values={{ count: activeCount }}
                    />
                  }
                />
              </TagLabel>
            </Tag>
            <Tag variant="outlineGrayBg">
              <TagLabel>
                <FormattedPlural
                  value={inviteCount}
                  one={
                    <FormattedMessage
                      id="organization.view.users.active.one"
                      description="Number of active users in organization"
                      defaultMessage="{count} pending invitation"
                      values={{ count: inviteCount }}
                    />
                  }
                  other={
                    <FormattedMessage
                      id="organization.view.users.active.other"
                      description="Number of active users in organization"
                      defaultMessage="{count} pending invitations"
                      values={{ count: inviteCount }}
                    />
                  }
                />
              </TagLabel>
            </Tag>
          </HStack>
          <Button colorScheme="brand" leftIcon={<Icon as={PlusIcon} />} onClick={inviteModal.onOpen}>
            <FormattedMessage
              id="organization.view.invite.button"
              description="button text for inviting a new user to organization"
              defaultMessage="Add User"
            />
          </Button>
        </HStack>
        <UserList userList={userList} />
      </Stack>
      {inviteModal.isOpen && <InviteOrUpdateUserModal {...inviteModal} />}
    </SettingsContainer>
  )
}
