import type { TimelineEvent, WorkflowRun } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { Box, Card, CardBody, Divider, HStack, Stack, Text } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { isNotUndefined } from "typed-assert"
import { useGetUserV1WhoamiQuery, useGetWorkflowV1LinksByCodeQuery } from "../../../app/services/generated-api.js"
import VendorCell from "../../../components/DataTable/CellRenderer/VendorCell.js"
import { EmptySymbol } from "../../../components/EmptySymbol.js"
import FieldTimeline from "../../../components/Timeline/FieldTimeline.js"
import { TimelineCommentInput } from "../../../components/Timeline/TimelineCommentInput.js"
import { Truncate } from "../../../components/Truncate.js"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import PickableEntityDisplay from "../../organization/PickableEntityDisplay.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "./utils.js"

interface Props {
  workflowRun: WorkflowRun
  timelineEvents: Array<TimelineEvent>
}

export default function WorkflowRunContextSummaryExternal({
  workflowRun,
  timelineEvents,
  getLogoToShowByOrganizationId,
  getOrganizationActorWhenActorMissing,
}: Props & GetLogoForOrganizationProps & GetOrganizationActorProps) {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { code } = useParams<{ code: string }>()
  const vendor = workflowRun.vendor
  const [tool] = workflowRun.tools

  isNotUndefined(tool)
  isNotUndefined(vendor)
  isNotUndefined(code, "WorkflowRunContextSummaryExternal: Missing code param")

  const { data: link } = useGetWorkflowV1LinksByCodeQuery({ code })

  if (!link) {
    return null
  }

  return (
    <Stack gap={8}>
      <Card variant="outline">
        <CardBody>
          <Box fontSize="sm">
            <PickableEntityDisplay
              displayText={displayPersonName(link.sender, intl)}
              image={getPublicImageGcsUrl(link.sender.profile_image?.gcs_file_name)}
              subText={link.sender.email}
            />
          </Box>
          <Truncate noOfLines={3}>{link.body ? <Text marginTop={2}>{link.body}</Text> : <EmptySymbol />}</Truncate>
        </CardBody>
      </Card>
      <Card variant="outline">
        <CardBody>
          <Text as="span" fontSize="sm" fontWeight="medium">
            <FormattedMessage
              id="requests.context.tool_description.label"
              description="Label on context section of requests page that displays the description of the tool on the request"
              defaultMessage="Tool description"
            />
          </Text>
          <Truncate noOfLines={3}>
            {tool.description ? <Text marginTop={2}>{tool.description}</Text> : <EmptySymbol />}
          </Truncate>
          <Divider my={4} />
          <HStack>
            <Stack flex={1}>
              <Text fontWeight="medium">
                <FormattedMessage
                  id="requests.context.vendor.label"
                  description="Label on context section of requests page that displays the vendor of the tool on the request"
                  defaultMessage="Vendor"
                />
              </Text>
              {vendor ? <VendorCell size="sm" vendor={vendor} /> : <EmptySymbol />}
            </Stack>
          </HStack>
        </CardBody>
      </Card>
      {whoami && (
        <Box minWidth={495}>
          {timelineEvents.length > 0 && (
            <FieldTimeline
              timelineEvents={timelineEvents}
              getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
              getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            />
          )}
          <TimelineCommentInput
            workflowRunId={workflowRun.id}
            showPrivacyControls={true}
            objectType="WorkflowRun"
            objectId={workflowRun.id}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            label="context"
          />
        </Box>
      )}
    </Stack>
  )
}
