import { hasPermission } from "@brm/schema-helpers/role.js"
import { type IntegrationProvider } from "@brm/schema-types/types.js"
import type { SimpleGridProps } from "@chakra-ui/react"
import {
  Button,
  Card,
  HStack,
  Icon,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react"
import { useMergeLink } from "@mergeapi/react-merge-link"
import { useFlags } from "launchdarkly-react-client-sdk"
import type { FunctionComponent } from "react"
import { Fragment, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkStableEvent,
} from "react-plaid-link"
import { useLocation, useNavigate } from "react-router-dom"
import { isNotUndefined, isNotVoid } from "typed-assert"
import {
  useGetUserV1WhoamiQuery,
  useLazyGetIntegrationV1AccountingBySourceLinkTokenQuery,
  useLazyGetIntegrationV1HrisBySourceLinkTokenQuery,
  useLazyGetPlaidV1LinkTokenQuery,
  usePostIntegrationV1CreateMutation,
} from "../../app/services/generated-api.js"
import { IntegrationProviderIcon } from "../../components/icons/IntegrationProviderIcon.js"
import { PlusIcon } from "../../components/icons/icons.js"
import { log } from "../../util/logger.js"
import { getTabIndexFromLocation } from "../../util/tabs.js"
import LaunchLink from "./LaunchPlaidLink.js"
import { integrationCardProps } from "./const.js"
import type { IntegrationConnectOption } from "./constants.js"
import { getIntegrationConnectTabs } from "./constants.js"

interface Props {
  openIntegrationModal: (modalKey: IntegrationProvider) => void
}

const getIntegrationConnectKey = (integration: IntegrationConnectOption) =>
  `${integration.provider}${integration.mergeHrisSource}${integration.mergeAccountingSource}`

export default function ConnectIntegration({ openIntegrationModal }: Props) {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const [createIntegration] = usePostIntegrationV1CreateMutation()
  const [getHRISLinkToken, hrisLinkTokenResponse] = useLazyGetIntegrationV1HrisBySourceLinkTokenQuery()
  const [hrisDataSource, setHrisDataSource] = useState<IntegrationConnectOption | null>(null)

  const [getPlaidLinkToken, plaidLinkTokenResponse] = useLazyGetPlaidV1LinkTokenQuery()

  const [getAccountingLinkToken, accountingLinkTokenResponse] =
    useLazyGetIntegrationV1AccountingBySourceLinkTokenQuery()
  const [accountingDataSource, setAccountingDataSource] = useState<IntegrationConnectOption | null>(null)

  const canCreateIntegration = hasPermission(whoami?.roles, "integration:create")

  const onExit = () => {
    // Reset the hris data source so that the modal doesn't open again on unrelated rerenders
    setHrisDataSource(null)
    // Reset the accounting data source so that the modal doesn't open again on unrelated rerenders
    setAccountingDataSource(null)
    // Merge react sdk sets the body style to overflow hidden to prevent scrolling while the modal is open
    // We need to remove these styles to allow page scrolling again
    window.document.body.style.removeProperty("overflow")
    window.document.body.style.removeProperty("height")
  }

  const { open: openHris } = useMergeLink({
    linkToken: hrisLinkTokenResponse.data?.token,
    onSuccess: async (publicToken) => {
      isNotVoid(hrisDataSource?.mergeHrisSource, "Expected hrisDataSource to be set")
      isNotUndefined(hrisLinkTokenResponse.data)
      await createIntegration({
        createIntegration: {
          auth_type: "hris_link_token",
          public_token: publicToken,
          display_name: hrisDataSource.name,
          provider: "merge_hris_link_token",
          merge_hris_type: hrisDataSource.mergeHrisSource,
          // link tokens generate the access token on the backend
          access_token: null,
          origin_id: hrisLinkTokenResponse.data.originId,
        },
      })
    },
    onExit,
  })

  const { open: openAccounting } = useMergeLink({
    linkToken: accountingLinkTokenResponse.data?.token,
    onSuccess: async (publicToken) => {
      isNotVoid(accountingDataSource?.mergeAccountingSource, "Expected accountingDataSource to be set")
      isNotUndefined(accountingLinkTokenResponse.data)
      await createIntegration({
        createIntegration: {
          auth_type: "accounting_link_token",
          public_token: publicToken,
          display_name: accountingDataSource.name,
          provider: "merge_accounting_link_token",
          merge_accounting_type: accountingDataSource.mergeAccountingSource,
          // link tokens generate the access token on the backend
          access_token: null,
          origin_id: accountingLinkTokenResponse.data.originId,
        },
      })
    },
    onExit,
  })

  const onPlaidSuccess = async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
    isNotVoid(metadata.institution?.name, "Expected an institution name to be set")
    await createIntegration({
      createIntegration: {
        auth_type: "plaid_access_token",
        display_name: metadata.institution.name,
        provider: "plaid_access_token",
        public_token: publicToken,
        institution_id: metadata.institution.institution_id,
        institution_name: metadata.institution.name,
        accounts: metadata.accounts,
        link_session_id: metadata.link_session_id,
      },
    })
  }

  const onPlaidExit = (err: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
    log.info("Plaid Link exited", { metadata })
    if (err) {
      log.error("Plaid Link exited with error", err)
    }
  }

  const onPlaidEvent = async (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {
    log.info("Plaid Link emitted event", { eventName, metadata })
  }

  useEffect(() => {
    if (hrisDataSource && hrisLinkTokenResponse.isSuccess && hrisLinkTokenResponse.data?.token) {
      openHris()
    }
  }, [hrisLinkTokenResponse, openHris, hrisDataSource])

  useEffect(() => {
    if (accountingDataSource && accountingLinkTokenResponse.isSuccess && accountingLinkTokenResponse.data?.token) {
      openAccounting()
    }
  }, [accountingLinkTokenResponse, openAccounting, accountingDataSource])

  const openMergeHrisIntegrationModal = async (integration: IntegrationConnectOption) => {
    isNotUndefined(integration.mergeHrisSource)
    await getHRISLinkToken({ source: integration.mergeHrisSource })
    setHrisDataSource(integration)
  }

  const openMergeAccountingIntegrationModal = async (integration: IntegrationConnectOption) => {
    isNotUndefined(integration.mergeAccountingSource)
    await getAccountingLinkToken({ source: integration.mergeAccountingSource })
    setAccountingDataSource(integration)
  }

  const openPlaidIntegrationModal = async () => {
    await getPlaidLinkToken()
  }

  const onIntegrationConnect = async (integration: IntegrationConnectOption) => {
    if (integration.mergeHrisSource) {
      await openMergeHrisIntegrationModal(integration)
    } else if (integration.mergeAccountingSource) {
      await openMergeAccountingIntegrationModal(integration)
    } else if (integration.provider === "plaid_access_token") {
      await openPlaidIntegrationModal()
    } else {
      openIntegrationModal(integration.provider)
    }
  }

  const simpleGridProps: SimpleGridProps = {
    templateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gap: 6,
  }
  const { plaidEnabled } = useFlags()
  const tabPanels = useMemo(() => getIntegrationConnectTabs(intl, plaidEnabled), [intl, plaidEnabled])
  const [tabIndex, setTabIndex] = useState(getTabIndexFromLocation(location, tabPanels))
  const handleChangeTab = (index: number) => {
    const newTab = tabPanels[index]
    isNotUndefined(newTab)
    const hashParams = new URLSearchParams(location.hash.slice(1))
    if (newTab.locationHash) {
      hashParams.set("tab", newTab.locationHash)
    } else {
      hashParams.delete("tab")
    }
    navigate({ hash: `#${hashParams}` }, { replace: true })
    setTabIndex(index)
  }

  return (
    <Tabs
      variant="soft-rounded"
      size="md"
      orientation="vertical"
      colorScheme="gray"
      isLazy
      onChange={handleChangeTab}
      defaultIndex={tabIndex}
    >
      <TabList>
        {tabPanels.map((tab) => (
          <Tab justifyContent="start" key={tab.locationHash}>
            {tab.label}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabPanels.map((tab) => (
          <TabPanel paddingY={0} key={tab.locationHash}>
            <SimpleGrid {...simpleGridProps}>
              {tab.integrationOptions.map((integrationConnect) => (
                <Fragment key={getIntegrationConnectKey(integrationConnect)}>
                  {integrationConnect.provider === "plaid_access_token" &&
                    whoami?.id &&
                    plaidLinkTokenResponse.data?.link_token && (
                      <LaunchLink
                        token={plaidLinkTokenResponse.data.link_token}
                        userId={whoami.id}
                        onSuccess={onPlaidSuccess}
                        onExit={onPlaidExit}
                        onEvent={onPlaidEvent}
                      />
                    )}
                  <IntegrationCard
                    integration={integrationConnect}
                    onClick={async () => await onIntegrationConnect(integrationConnect)}
                    isDisabled={!canCreateIntegration}
                  />
                </Fragment>
              ))}
              <RequestNewIntegrationCard />
            </SimpleGrid>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

const IntegrationCard: FunctionComponent<{
  integration: IntegrationConnectOption
  onClick: () => Promise<void>
  isDisabled?: boolean
}> = ({ integration, onClick, isDisabled }) => {
  return (
    <Card {...integrationCardProps}>
      <Stack>
        <HStack justify="space-between">
          <HStack>
            <IntegrationProviderIcon
              integration={{
                provider: integration.provider,
                merge_hris_type: integration.mergeHrisSource,
                merge_accounting_type: integration.mergeAccountingSource,
              }}
              boxSize={6}
            />
            <Text fontWeight="bold" fontSize="md">
              {integration.name}
            </Text>
          </HStack>
          <Button isDisabled={isDisabled} onClick={onClick} colorScheme="brand" flexShrink={0}>
            <FormattedMessage
              id="integrations.page.connect.button"
              description="Button text of integration page connect integration option"
              defaultMessage="Connect"
            />
          </Button>
        </HStack>
        <Text color="gray.600" fontSize="sm">
          {integration.description}
        </Text>
      </Stack>
    </Card>
  )
}

const RequestNewIntegrationCard: FunctionComponent = () => {
  return (
    <Card {...integrationCardProps}>
      <HStack justify="space-between">
        <HStack>
          <Text fontWeight="bold">
            <FormattedMessage
              id="integrations.page.connect.request"
              description="Request integration header"
              defaultMessage="Request Integration"
            />
          </Text>
        </HStack>
        <Button
          onClick={() => {
            window.location.assign("mailto:support@brm.ai?subject=Integration Request")
          }}
          colorScheme="brand"
        >
          <Icon as={PlusIcon} />
        </Button>
      </HStack>
      <Text color="gray.600" fontSize="sm">
        <FormattedMessage
          id="integrations.page.connect.description"
          description="text description for integration request"
          defaultMessage="Let BRM know what integrations you think are missing"
        />
      </Text>
    </Card>
  )
}
