import type {
  FieldMetadata,
  FieldMetadataWithSuggestions,
  VendorOption,
  VendorOptionWithTools,
} from "@brm/schema-types/types.js"
import { Box, HStack, Icon, Text, chakra } from "@chakra-ui/react"
import type { ControlProps, CreatableProps, GroupBase, OptionProps, Props, SingleValueProps } from "chakra-react-select"
import { CreatableSelect, Select, chakraComponents } from "chakra-react-select"
import { forwardRef, useImperativeHandle, useMemo, useRef, useState, type Ref } from "react"
import type { RefCallBack } from "react-hook-form"
import { useIntl } from "react-intl"
import { useGetVendorV1PickerOptionsQuery } from "../../app/services/generated-api.js"
import { isNewOption } from "../../util/form.js"
import { pathForVendor } from "../../util/json-schema.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import OptionWithFieldSource from "../DynamicForm/OptionWithFieldSource.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "../DynamicForm/types.js"
import { IconButtonWithTooltip } from "../IconButtonWithTooltip.js"
import { VendorLogo } from "../icons/Logo.js"
import { PlusIcon, ShareIcon } from "../icons/icons.js"

type VendorOptionPickerProps = {
  onChange?: (value: VendorOptionWithTools | null, fieldSource?: FieldMetadata) => void
  suggestions?: ValueWithSource<VendorOptionWithTools>[]
  value: VendorOption | null
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: DynamicFormFieldApproval
  allowNull?: boolean
} & Omit<Props<ValueWithSource<VendorOptionWithTools | null>, false>, "onChange" | "value">

const Option = ({ children, ...props }: OptionProps<ValueWithSource<VendorOptionWithTools | null>, false>) => {
  const intl = useIntl()
  const fieldSources = props.data.field_sources

  if (!props.data.value) {
    return <chakraComponents.Option {...props}>{children}</chakraComponents.Option>
  }

  return props.data.value?.vendor_listing_id !== "new" ? (
    <chakraComponents.Option {...props}>
      <VendorLogo boxSize={6} logo={getPublicImageGcsUrl(props.data.value?.image_asset?.gcs_file_name)} />
      <OptionWithFieldSource fieldSources={fieldSources}>
        <HStack flexGrow={1} minWidth={0} justifyContent="space-between">
          <Text flexShrink={1} minWidth={0} textOverflow="ellipsis" overflow="hidden">
            {children}
          </Text>
          {!props.data.value?.id && (
            <chakra.span color="gray.600" fontSize="xs" marginTop="auto" flexShrink={0}>
              {intl.formatMessage({
                defaultMessage: "Add to your organization",
                description: "Support text to indicate a net-new vendor",
                id: "Option.span",
              })}
            </chakra.span>
          )}
        </HStack>
      </OptionWithFieldSource>
    </chakraComponents.Option>
  ) : (
    <chakraComponents.Option {...props}>
      <Icon boxSize={5} as={PlusIcon} /> {children}
    </chakraComponents.Option>
  )
}

const SingleValue = ({
  children,
  ...props
}: SingleValueProps<ValueWithSource<VendorOptionWithTools | null>, false>) => {
  const intl = useIntl()
  const value = props.getValue()[0]?.value
  const vendorId = value?.id
  return (
    <chakraComponents.SingleValue {...props}>
      {children}
      {vendorId && (
        <chakra.span float="right" position="absolute" right={0} verticalAlign="middle" marginRight={1}>
          <IconButtonWithTooltip
            color="gray.600"
            variant="ghost"
            size="sm"
            onClick={(e) => {
              window.open(pathForVendor(vendorId), "_blank")
              e.stopPropagation()
            }}
            icon={<Icon boxSize={4} as={ShareIcon} />}
            label={intl.formatMessage({
              defaultMessage: "View Vendor",
              description: "Tooltip for viewing a vendor",
              id: "Form.VendorOptionPicker.ViewVendor",
            })}
          />
        </chakra.span>
      )}
    </chakraComponents.SingleValue>
  )
}

const Control = ({ children, ...props }: ControlProps<ValueWithSource<VendorOptionWithTools | null>, false>) => {
  const { inputValue } = props.selectProps
  const { hasValue } = props
  const hasInput = inputValue.length > 0
  const data = props.getValue()
  return (
    <chakraComponents.Control {...props}>
      <chakra.span ml={3}>
        {hasValue && hasInput ? <VendorLogo boxSize={6} /> : null}
        {hasValue && data[0] && !hasInput ? (
          <VendorLogo boxSize={6} logo={getPublicImageGcsUrl(data[0].value?.image_asset?.gcs_file_name)} />
        ) : null}
      </chakra.span>
      {children}
    </chakraComponents.Control>
  )
}

export const VendorOptionPicker = forwardRef(function VendorOptionPicker(
  props: VendorOptionPickerProps,
  ref: Ref<HTMLInputElement | null>
) {
  const { onChange, value, suggestions, fieldMetadata, fieldApproval, allowNull, ...rest } = props

  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  const [query, setQuery] = useState("")

  const { data, isLoading } = useGetVendorV1PickerOptionsQuery({ search: query })
  const vendorList = useMemo(() => {
    return (
      data
        // Filter out fetched vendor if it is already in suggestions
        ?.filter(
          (vendor) =>
            !suggestions?.some(
              (suggestedVendor) => suggestedVendor.value.vendor_listing_id === vendor.vendor_listing_id
            )
        )
        .map((vendor) => ({
          value: vendor,
          fieldSources: [],
        })) || []
    )
  }, [data, suggestions]) as ValueWithSource<VendorOption>[]

  const options = useMemo(
    () => [...(allowNull ? [{ value: null, field_sources: [] }] : []), ...(suggestions ?? []), ...vendorList],
    [allowNull, suggestions, vendorList]
  )

  return (
    <Box flexGrow={1}>
      <Select<ValueWithSource<VendorOptionWithTools | null>>
        chakraStyles={{
          singleValue: (styles) => ({ ...styles, display: "flex", alignItems: "center" }),
          valueContainer: (styles) => ({ ...styles, paddingLeft: "4px" }),
          option: (styles, { data }) => {
            const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
            return {
              ...styles,
              display: "flex",
              gap: "0.5rem",
              ...(isNew && { backgroundColor: `${colorScheme}.50` }),
            }
          },
        }}
        styles={{
          menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
        }}
        openMenuOnFocus={true}
        menuPortalTarget={document.body}
        options={options}
        isLoading={isLoading}
        getOptionLabel={(option) => option.value?.display_name || ""}
        getOptionValue={(option) => option.value?.vendor_listing_id || ""}
        onChange={(option) => onChange?.(option?.value ?? null, option?.field_sources?.[0])}
        value={value && { value }}
        inputValue={query}
        onInputChange={(val) => setQuery(val)}
        components={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Option,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Control,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          SingleValue,
        }}
        ref={(select) => {
          inputRef.current = select?.inputRef ?? null
        }}
        {...rest}
      />
    </Box>
  )
})

export type CreatableVendorOptionPickerProps = {
  allowCreate: boolean
  suggestions?: ValueWithSource<VendorOptionWithTools>[]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: DynamicFormFieldApproval
  inputRef?: RefCallBack
} & CreatableProps<
  ValueWithSource<VendorOptionWithTools | null>,
  false,
  GroupBase<ValueWithSource<VendorOptionWithTools | null>>
>

export function VendorOptionPickerCreatable(props: CreatableVendorOptionPickerProps) {
  const { value, suggestions, fieldMetadata, fieldApproval, inputRef } = props

  const [search, setSearch] = useState("")
  const { data, isLoading } = useGetVendorV1PickerOptionsQuery({ search })

  const vendorList: ValueWithSource<VendorOptionWithTools>[] =
    data
      ?.filter(
        (vendor) =>
          !suggestions?.some((suggestedVendor) => suggestedVendor.value.vendor_listing_id === vendor.vendor_listing_id)
      )
      .map((vendor) => ({
        value: vendor,
        fieldSources: [],
      })) || []

  return (
    <Box flexGrow={1}>
      <CreatableSelect<ValueWithSource<VendorOptionWithTools | null>>
        options={[...(suggestions ?? []), ...vendorList]}
        isValidNewOption={(newVendorName: string) => {
          if (newVendorName === "") {
            return false
          }

          return vendorList.every((vendorListing) =>
            vendorListing.value.display_name.toLowerCase().includes(newVendorName.toLowerCase())
          )
        }}
        ref={(select) => {
          inputRef?.(select?.inputRef ?? null)
        }}
        openMenuOnFocus={true}
        isLoading={isLoading}
        components={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Control,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Option,
        }}
        chakraStyles={{
          valueContainer: (styles) => ({ ...styles, paddingLeft: "4px" }),
          option: (styles, { data }) => {
            const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
            return {
              ...styles,
              display: "flex",
              gap: "0.5rem",
              ...(isNew && {
                backgroundColor: `${colorScheme}.50`,
              }),
            }
          },
        }}
        menuPortalTarget={document.body}
        getOptionLabel={({ value }) => value?.display_name ?? ""}
        getOptionValue={({ value }) => value?.vendor_listing_id || ""}
        getNewOptionData={(inputValue) => {
          return {
            value: {
              display_name: inputValue,
              vendor_listing_id: "new",
              description: null,
              website: null,
              image_asset: null,
              id: null,
              object_type: "Vendor",
              tools: [],
            },
          }
        }}
        value={value}
        inputValue={search}
        onInputChange={setSearch}
        {...props}
      />
    </Box>
  )
}
