import type { InviteUser } from "@brm/schema-types/types.js"
import { Card, Flex, Stack, chakra, useToast } from "@chakra-ui/react"
import { useCallback, useEffect, type FunctionComponent } from "react"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useSearchParams } from "react-router-dom"
import {
  useGetOrganizationV1CurrentQuery,
  useGetUserV1WhoamiQuery,
  usePutUserV1ByIdMutation,
  type Organization,
  type UserWithOrganization,
} from "../../app/services/generated-api.js"
import { LogOutButton } from "../../components/Nav/LogOutButton.js"
import { getAPIErrorMessage } from "../../util/error.js"
import { initializeReactHookFromState } from "../../util/form.js"
import { log } from "../../util/logger.js"
import InviteOrUpdateUserFormContent from "../organization/invites/InviteOrUpdateUserFormContent.js"
import AuthenticationSettings from "./AuthenticationSettings.js"
import { SettingsContainer } from "./SettingsContainer.js"
import { SettingsHeader } from "./SettingsHeader.js"

export default function UserProfileSettings() {
  const { data: user } = useGetUserV1WhoamiQuery()
  const { data: organization } = useGetOrganizationV1CurrentQuery()

  if (!user || !organization) {
    return null
  }

  return <UserProfileSettingsPage user={user} organization={organization} />
}

// This is a separate component so that it can receive the fetched `user`
const UserProfileSettingsPage: FunctionComponent<{
  user: UserWithOrganization
  organization: Organization
}> = ({ user, organization }) => {
  const intl = useIntl()
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams()
  const [updateUser] = usePutUserV1ByIdMutation()

  useEffect(() => {
    const error = searchParams.get("error")
    if (error) {
      toast({
        description: error,
        status: "error",
        position: "top",
        onCloseComplete: () => setSearchParams(),
      })
    }
  }, [searchParams, intl, toast, setSearchParams])

  const form = useForm<InviteUser>({ defaultValues: user })
  initializeReactHookFromState(form)

  const submit = useCallback(
    async (values: InviteUser) => {
      try {
        await updateUser({
          id: user.id,
          updateUser: { first_name: values.first_name, last_name: values.last_name },
        }).unwrap()
        toast({
          status: "success",
          description: (
            <FormattedMessage
              defaultMessage="Profile updated successfully"
              description="The toast description for a successful profile update"
              id="settings.profile.toast.success"
            />
          ),
        })
      } catch (err) {
        log.error("Error saving user", err, { values })
        toast({
          status: "error",
          description: getAPIErrorMessage(err) ?? (
            <FormattedMessage
              defaultMessage="An error occurred saving the profile"
              description="The toast description for an error saving the profile"
              id="settings.profile.toast.error"
            />
          ),
        })
      }
    },
    [toast, updateUser, user]
  )

  return (
    <SettingsContainer>
      <SettingsHeader>
        <FormattedMessage
          defaultMessage="Profile"
          description="The heading for the user profile settings page"
          id="settings.profile.heading"
        />
      </SettingsHeader>
      <Stack spacing={8} maxWidth="2xl">
        <Card variant="outline" padding={5}>
          <chakra.form
            // Submit on blur if changed
            onBlur={(event) => {
              if (form.formState.isDirty) {
                event.currentTarget.requestSubmit()
              }
            }}
            onSubmit={form.handleSubmit(submit)}
            noValidate={true} // ensure react-hook-form validation is used
          >
            <InviteOrUpdateUserFormContent form={form} userToUpdateId={user.id} hideRoleEditing={true} />
          </chakra.form>
        </Card>
        {organization && !organization.sso_only && <AuthenticationSettings />}
        <Flex>
          <LogOutButton />
        </Flex>
      </Stack>
    </SettingsContainer>
  )
}
