import type { InboxNotification, WorkflowStepStandardType } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { isObject } from "@brm/util/type-guard.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Avatar,
  AvatarBadge,
  Flex,
  HStack,
  Icon,
  Kbd,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useToast,
  type MenuButtonProps,
  type MenuListProps,
  type MenuProps,
} from "@chakra-ui/react"
import { chakra } from "@chakra-ui/system"
import { Temporal } from "@js-temporal/polyfill"
import type { FC, FunctionComponent, ReactNode } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { FormattedMessage, useIntl, type IntlShape } from "react-intl"
import type { Except } from "type-fest"
import { usePutNotificationV1ByIdMutation } from "../../../app/services/generated-api.js"
import { useContextMenu } from "../../../components/ContextMenu/context-menu.js"
import {
  ComplianceIcon,
  FinanceIcon,
  ITIcon,
  LegalIcon,
  ReadUnreadIcon,
  RequestCloseIcon,
  RequestDetailsIcon,
  ToolIcon,
  TrashIcon,
  UserIcon,
  VendorIcon,
} from "../../../components/icons/icons.js"
import { Link } from "../../../components/Link.js"
import { Timestamp } from "../../../components/Timestamp.js"
import { log } from "../../../util/logger.js"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import {
  getNotificationBodyText,
  getNotificationHeaderText,
  getNotificationKeyboardShortcutsDisplayText,
  getNotificationSrcUrl,
  NotificationItemFallbackIcons,
  NotificationKeyboardShortcuts,
} from "./helpers.js"

type NotificationItemBadge =
  | {
      type: "step"
      stepType: WorkflowStepStandardType
    }
  | {
      type: "vendor"
      avatarSrc?: string
    }

const getAvatarBadgeFromNotification = (
  notification: InboxNotification,
  usersOrganizationId: string
): undefined | NotificationItemBadge => {
  switch (notification.type) {
    case "workflow_run_step.approved":
    case "workflow_run_step.approval_requested":
    case "workflow_run_step_field.reopened":
    case "workflow_run_step.unapproved":
    case "workflow_run_step.changes_requested":
      return {
        type: "step",
        stepType: notification.workflow_run_step.type,
      }
    case "mention":
    case "workflow_run.timeline_comment":
      if (
        isObject(notification.timeline_event.actor) &&
        notification.timeline_event.actor.organization_id !== usersOrganizationId
      ) {
        return {
          type: "vendor",
          avatarSrc: notification.vendor_logo_asset
            ? getPublicImageGcsUrl(notification.vendor_logo_asset.gcs_file_name)
            : undefined,
        }
      }
      return undefined
    case "workflow_run.completed":
    case "workflow_run.started":
    case "workflow_run.input_needed":
    case "renewal_reminder.30d":
    case "renewal_reminder.60d":
    case "renewal_reminder.90d":
    case "opt_out_reminder.30d":
    case "opt_out_reminder.60d":
    case "opt_out_reminder.90d":
    case "tool_owned_opt_out_reminder.30d":
    case "tool_owned_opt_out_reminder.60d":
    case "tool_owned_opt_out_reminder.90d":
    case "vendor_owned_opt_out_reminder.30d":
    case "vendor_owned_opt_out_reminder.60d":
    case "vendor_owned_opt_out_reminder.90d":
    case "vendor_owned_renewal_reminder.30d":
    case "vendor_owned_renewal_reminder.60d":
    case "vendor_owned_renewal_reminder.90d":
    case "tool_owned_renewal_reminder.30d":
    case "tool_owned_renewal_reminder.60d":
    case "tool_owned_renewal_reminder.90d":
    case "tool.timeline_comment":
    case "vendor.timeline_comment":
    case "tool_owned.timeline_comment":
    case "vendor_owned.timeline_comment":
      return undefined
    default:
      unreachable(notification)
  }
}

const getAvatarFromNotification = (
  notification: InboxNotification,
  intl: IntlShape
): { avatarSrc: string | undefined; avatarName: string; avatarFallbackIcon?: NotificationItemFallbackIcons } => {
  switch (notification.type) {
    case "workflow_run_step.approved":
    case "workflow_run.completed":
    case "workflow_run.started":
    case "workflow_run.input_needed":
    case "workflow_run_step.approval_requested":
    case "workflow_run_step_field.reopened":
    case "workflow_run_step.unapproved":
    case "workflow_run_step.changes_requested":
      return {
        avatarSrc: notification.tool_image?.gcs_file_name
          ? getPublicImageGcsUrl(notification.tool_image?.gcs_file_name)
          : undefined,
        avatarName: notification.tool_display_name,
        avatarFallbackIcon: NotificationItemFallbackIcons.Tool,
      }
    case "renewal_reminder.30d":
    case "renewal_reminder.60d":
    case "renewal_reminder.90d":
    case "opt_out_reminder.30d":
    case "opt_out_reminder.60d":
    case "opt_out_reminder.90d":
    case "tool_owned_opt_out_reminder.30d":
    case "tool_owned_opt_out_reminder.60d":
    case "tool_owned_opt_out_reminder.90d":
    case "vendor_owned_opt_out_reminder.30d":
    case "vendor_owned_opt_out_reminder.60d":
    case "vendor_owned_opt_out_reminder.90d":
    case "vendor_owned_renewal_reminder.30d":
    case "vendor_owned_renewal_reminder.60d":
    case "vendor_owned_renewal_reminder.90d":
    case "tool_owned_renewal_reminder.30d":
    case "tool_owned_renewal_reminder.60d":
    case "tool_owned_renewal_reminder.90d":
      return {
        avatarSrc: notification.vendor_image?.gcs_file_name
          ? getPublicImageGcsUrl(notification.vendor_image?.gcs_file_name)
          : undefined,
        avatarName: notification.vendor_display_name,
        avatarFallbackIcon: NotificationItemFallbackIcons.Vendor,
      }
    case "mention":
    case "workflow_run.timeline_comment":
    case "tool.timeline_comment":
    case "vendor.timeline_comment":
    case "tool_owned.timeline_comment":
    case "vendor_owned.timeline_comment":
      if (!notification.sender) {
        log.error("Notification is missing sender", notification)
      }
      return {
        avatarSrc: notification.sender?.profile_image?.gcs_file_name
          ? getPublicImageGcsUrl(notification.sender.profile_image?.gcs_file_name)
          : undefined,
        avatarName: notification.sender ? displayPersonName(notification.sender, intl) : "user",
      }
    default:
      unreachable(notification)
  }
}

export const NotificationItem: FunctionComponent<{
  notification: InboxNotification
  selectedNotificationId: string | undefined
  organizationId: string
  onSelectNextNotification?: () => void
  onSelectPreviousNotification?: () => void
}> = ({
  notification,
  selectedNotificationId,
  organizationId,
  onSelectNextNotification,
  onSelectPreviousNotification,
}) => {
  const intl = useIntl()
  const [updateNotification] = usePutNotificationV1ByIdMutation()
  const toast = useToast()

  const avatarBadge = getAvatarBadgeFromNotification(notification, organizationId)
  const { avatarSrc, avatarName, avatarFallbackIcon } = getAvatarFromNotification(notification, intl)

  return (
    <NotificationItemCore
      isRead={notification.read_at ? true : false}
      isSelected={selectedNotificationId === notification.id}
      timeStamp={notification.created_at}
      avatarSrc={avatarSrc}
      avatarName={avatarName}
      avatarFallbackIcon={avatarFallbackIcon}
      badge={avatarBadge}
      headingText={getNotificationHeaderText(notification, intl)}
      bodyText={getNotificationBodyText(notification, intl)}
      linkTo={getNotificationSrcUrl(notification, organizationId)}
      onToggleRead={async () => {
        try {
          await updateNotification({
            id: notification.id,
            notificationUpdate: {
              read_at: notification.read_at ? null : Temporal.Now.instant().toString(),
            },
          })
        } catch (err) {
          log.error("Failed to toggle read status of notification", err)
          toast({
            description: (
              <FormattedMessage
                defaultMessage="Failed to toggle read status of notification"
                description="Error message when marking a notification as read"
                id="inbox.notification.mark-as-read-error"
              />
            ),
            status: "error",
          })
        }
      }}
      onDeleteNotification={async () => {
        try {
          await updateNotification({
            id: notification.id,
            notificationUpdate: {
              archived_at: Temporal.Now.instant().toString(),
            },
          })
        } catch (err) {
          log.error("Failed to delete notification", err)
          toast({
            description: (
              <FormattedMessage
                defaultMessage="Failed to delete notification"
                description="Error message when deleting a notification"
                id="inbox.notification.delete-error"
              />
            ),
            status: "error",
          })
        }
      }}
      onSelectNextNotification={onSelectNextNotification}
      onSelectPreviousNotification={onSelectPreviousNotification}
    />
  )
}

export const NotificationItemCore: FC<{
  isRead: boolean
  isSelected: boolean
  timeStamp: string
  avatarSrc?: string
  avatarName: string
  avatarFallbackIcon?: NotificationItemFallbackIcons
  headingText?: string
  bodyText?: string
  linkTo: string
  badge?: NotificationItemBadge
  onToggleRead?: () => void
  onDeleteNotification?: () => void
  onSelectNextNotification?: () => void
  onSelectPreviousNotification?: () => void
}> = ({
  isRead,
  isSelected,
  avatarSrc,
  avatarName,
  avatarFallbackIcon,
  timeStamp,
  headingText,
  bodyText,
  badge,
  linkTo,
  onToggleRead,
  onDeleteNotification,
  onSelectNextNotification,
  onSelectPreviousNotification,
}) => {
  const { menuListProps, menuProps, subjectProps, menuButtonProps } = useContextMenu<HTMLLIElement>()
  const { buttonProps, baseProps } = subjectProps
  useHotkeys(
    [
      NotificationKeyboardShortcuts.TOGGLE_READ,
      NotificationKeyboardShortcuts.BACKSPACE,
      NotificationKeyboardShortcuts.DELETE,
      NotificationKeyboardShortcuts.UP,
      NotificationKeyboardShortcuts.DOWN,
      NotificationKeyboardShortcuts.VI_DOWN_KEY,
      NotificationKeyboardShortcuts.VI_UP_KEY,
      NotificationKeyboardShortcuts.GMAIL_ARCHIVE_KEY,
    ],
    (e, handler) => {
      if (isSelected) {
        switch (handler.keys?.join("")) {
          case NotificationKeyboardShortcuts.TOGGLE_READ:
            onToggleRead?.()
            break
          case NotificationKeyboardShortcuts.BACKSPACE:
          case NotificationKeyboardShortcuts.DELETE:
          case NotificationKeyboardShortcuts.GMAIL_ARCHIVE_KEY:
            onDeleteNotification?.()
            break
          case NotificationKeyboardShortcuts.UP:
          case NotificationKeyboardShortcuts.VI_UP_KEY:
            onSelectPreviousNotification?.()
            break
          case NotificationKeyboardShortcuts.DOWN:
          case NotificationKeyboardShortcuts.VI_DOWN_KEY:
            onSelectNextNotification?.()
            break
          default:
            break
        }
        e.preventDefault()
      }
    },
    { enabled: isSelected }
  )
  return (
    <ListItem
      display="flex"
      opacity={isRead && !isSelected ? 0.7 : 1}
      transition="opacity 0.3s ease-in-out"
      {...buttonProps}
      {...baseProps}
      px="4"
    >
      <Link
        to={linkTo}
        display="flex"
        _hover={{ textDecoration: "none" }}
        background={isSelected ? "gray.50" : undefined}
        width="100%"
        gap={3}
        overflow="hidden"
      >
        <Flex alignItems="center" paddingTop={2} paddingBottom={2}>
          <AvatarWithFallback avatarSrc={avatarSrc} avatarName={avatarName} fallbackIcon={avatarFallbackIcon}>
            {badge?.type === "step" && <StepBadge stepType={badge.stepType} />}
            {badge?.type === "vendor" &&
              (badge.avatarSrc ? (
                <AvatarBadge as={Avatar} boxSize={4} src={badge.avatarSrc} />
              ) : (
                <AvatarBadge as={VendorIcon} boxSize={4} color="gray.400" backgroundColor="gray.100" />
              ))}
          </AvatarWithFallback>
        </Flex>
        <Flex
          borderBottomWidth={1}
          alignItems="center"
          flexGrow={1}
          overflow="hidden"
          display="block"
          paddingTop={2}
          paddingBottom={2}
        >
          <Flex gap={2} overflow="hidden" alignItems="center">
            <Text
              size="sm"
              fontWeight="medium"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              flexShrink={1}
            >
              <IsReadDot isRead={isRead} />
              {headingText}
            </Text>
            <Flex color="gray.600" flexGrow={1} justifyContent="flex-end">
              <Timestamp dateTime={timeStamp} style="narrow" numeric="auto" thresholds={{ second: 0 }} />
            </Flex>
          </Flex>
          <chakra.div overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {bodyText}
          </chakra.div>
        </Flex>
      </Link>
      {menuProps.isOpen && (
        <RightClickMenu
          menuProps={menuProps}
          menuListProps={menuListProps}
          menuButtonProps={menuButtonProps}
          onDeleteNotificationClicked={onDeleteNotification}
          onToggleReadClicked={onToggleRead}
          isRead={isRead}
        />
      )}
    </ListItem>
  )
}

export default NotificationItem

interface RightClickMenuProps {
  menuProps: Except<MenuProps, "children">
  menuListProps: MenuListProps
  menuButtonProps: MenuButtonProps
  onToggleReadClicked?: () => void
  onDeleteNotificationClicked?: () => void
  isRead: boolean
}

const RightClickMenu: React.FC<RightClickMenuProps> = ({
  menuProps,
  menuListProps,
  menuButtonProps,
  onToggleReadClicked,
  onDeleteNotificationClicked,
  isRead,
}) => {
  return (
    <Portal>
      <Menu {...menuProps}>
        <MenuButton {...menuButtonProps} />
        <MenuList {...menuListProps}>
          <MenuItem icon={<Icon as={ReadUnreadIcon} />} onClick={onToggleReadClicked}>
            <HStack justifyContent="space-between">
              {isRead ? (
                <FormattedMessage
                  defaultMessage="Mark as unread"
                  description="Label for the menu item to mark a notification as unread"
                  id="inbox.notification.mark-as-unread"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Mark as read"
                  description="Label for the menu item to mark a notification as read"
                  id="inbox.notification.mark-as-read"
                />
              )}
              <Kbd>{getNotificationKeyboardShortcutsDisplayText(NotificationKeyboardShortcuts.TOGGLE_READ)}</Kbd>
            </HStack>
          </MenuItem>
          <MenuItem icon={<Icon as={TrashIcon} />} onClick={onDeleteNotificationClicked}>
            <HStack justifyContent="space-between">
              <FormattedMessage
                defaultMessage="Delete"
                description="Delete notification menu item"
                id="inbox.notification.delete"
              />
              <Text>{getNotificationKeyboardShortcutsDisplayText(NotificationKeyboardShortcuts.DELETE)}</Text>
            </HStack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Portal>
  )
}

const IsReadDot: FC<{ isRead: boolean }> = ({ isRead }) => {
  return (
    <chakra.span paddingRight=".5">
      <chakra.span
        height={isRead ? 0 : 2}
        width={isRead ? 0 : 2}
        opacity={isRead ? 0 : 1}
        backgroundColor="brand.400"
        borderRadius="50%"
        display="inline-block"
        padding={isRead ? 0 : 1}
        marginRight={isRead ? 0 : 1}
        transition="all 0.3s ease-in-out"
      />
    </chakra.span>
  )
}

const StepBadge: FC<{ stepType: WorkflowStepStandardType }> = ({ stepType }) => {
  const badgeBoxSize = 4
  switch (stepType) {
    case "compliance":
      return <AvatarBadge boxSize={badgeBoxSize} as={ComplianceIcon} color="purple.700" backgroundColor="purple.100" />
    case "finance":
      return <AvatarBadge boxSize={badgeBoxSize} as={FinanceIcon} color="green.700" backgroundColor="green.100" />
    case "legal":
      return <AvatarBadge boxSize={badgeBoxSize} as={LegalIcon} color="blue.700" backgroundColor="blue.100" />
    case "it":
      return <AvatarBadge boxSize={badgeBoxSize} as={ITIcon} color="orange.700" backgroundColor="orange.100" />
    case "close":
      return <AvatarBadge boxSize={badgeBoxSize} as={RequestCloseIcon} color="brand.700" backgroundColor="brand.100" />
    case "details":
    case "custom":
      return <AvatarBadge boxSize={badgeBoxSize} as={RequestDetailsIcon} color="gray.400" backgroundColor="gray.100" />
    default:
      unreachable(stepType)
  }
}

const getFallbackIcon = (type: NotificationItemFallbackIcons) => {
  switch (type) {
    case NotificationItemFallbackIcons.Tool:
      return ToolIcon
    case NotificationItemFallbackIcons.User:
      return UserIcon
    case NotificationItemFallbackIcons.Vendor:
      return VendorIcon
    default:
      unreachable(type)
  }
}

const AvatarWithFallback: FC<{
  avatarSrc?: string
  avatarName: string
  fallbackIcon?: NotificationItemFallbackIcons
  children: ReactNode
}> = ({ avatarSrc, avatarName, children, fallbackIcon }) => {
  if (!avatarSrc && fallbackIcon !== undefined) {
    return (
      <Avatar icon={<Icon as={getFallbackIcon(fallbackIcon)} />} size="md" color="gray.400">
        {children}
      </Avatar>
    )
  }

  return (
    <Avatar src={avatarSrc} name={avatarName} size="md">
      {children}
    </Avatar>
  )
}
