import type { LegalAgreementListItem } from "@brm/schema-types/types.js"
import { Button, Popover, PopoverBody, PopoverContent, PopoverTrigger, type ButtonProps } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import AgreementCalendarDisplay from "./AgreementCalendarDisplay.js"

const buttonStyle: ButtonProps = {
  variant: "unstyled",
  color: "brand.600",
  _hover: { backgroundColor: "gray.100" },
  h: "unset",
  p: 1,
  isTruncated: true,
  textAlign: "start",
}

const AgreementCalendarListItem: React.FC<{
  agreement: LegalAgreementListItem
  onClick?: () => void
  autoFocus?: boolean
}> = ({ agreement, onClick, autoFocus }) => {
  if (onClick) {
    return (
      <Button onClick={onClick} autoFocus={autoFocus} {...buttonStyle}>
        <FormattedMessage
          id="renewalCalendar.agreementLink"
          defaultMessage="{agreementName}"
          description="Displays the name of the agreement"
          values={{ agreementName: agreement.display_name }}
        />
      </Button>
    )
  }
  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Button autoFocus={autoFocus} {...buttonStyle}>
          <FormattedMessage
            id="renewalCalendar.agreementLink"
            defaultMessage="{agreementName}"
            description="Displays the name of the agreement"
            values={{ agreementName: agreement.display_name }}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <AgreementCalendarDisplay agreement={agreement} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default AgreementCalendarListItem
