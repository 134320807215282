import type { LegalAgreementWithRelations } from "@brm/schema-types/types.js"
import { default as debounce } from "awesome-debounce-promise"
import { excludeKeys } from "filter-obj"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useState } from "react"
import { useAsyncAbortable } from "react-async-hook"
import useConstant from "use-constant"
import { askBetsy } from "../../features/betsy/ask-betsy.js"
import type { WorkflowRunWithExternalFlag } from "../../features/workflows/run/utils.js"

/**
 * Uses Betsy to evaluate the deal in `total_contract_value` fields of renewal requests.
 * The returned string is a text to display in an info alert under the field.
 */
export const useBetsyDealEval = (
  workflowRun: WorkflowRunWithExternalFlag | undefined,
  getValue: (path: string) => unknown,
  path: (string | number)[]
): string => {
  const [betsyResponse, setBetsyResponse] = useState<string>("")
  const { dealEvalAssistEnabled } = useFlags()
  const fieldName = path.at(-1)
  const value = getValue(path.join("."))
  const activeLegalAgreement =
    workflowRun?.active_legal_agreement &&
    excludeKeys(workflowRun?.active_legal_agreement, ["tools", "vendor", "fields_metadata"])
  const vendorName = workflowRun?.vendor?.display_name
  const toolName = workflowRun?.tools[0]?.display_name
  const document = getValue(path.slice(0, -1).join("."))
  const documentJSON = document ? JSON.stringify(document, null, 2) : null

  const fetchAskBetsy = async (
    signal: AbortSignal,
    documentJson: string | null,
    activeLegalAgreement?: Omit<LegalAgreementWithRelations, "tools" | "vendor" | "fields_metadata"> | null,
    toolName?: string
  ) => {
    setBetsyResponse("")
    return await askBetsy(
      {
        query: [
          {
            type: "paragraph",
            children: [
              {
                text: `We are in the process of renewing our contract for the tool "${toolName}". Is this total contract value of the renewal a good deal?
Consider how many active tool users "${toolName}" has and the cost per user per month, the contract duration, invoice interval, contract clauses, and any other information you have available.

Pay attention to any differences in invoice intervals between the old and the new contract.
If the invoice interval is bigger than before, the contract value should be lower to be a good deal because a higher upfront payment is required.
If the invoice interval is shorter than before, the contract is STILL a good deal EVEN IF the contract value is HIGHER because smaller individual payments are better for the buyer. A monthly invoice interval can be a BENEFIT worth paying more for.

Keep your answer short.
Speak confidently.
Start your response with your final judgement.

Here are the full details of the order form for the new contract:
${documentJson}

Here are the full details of our old contract:
${JSON.stringify(activeLegalAgreement, null, 2)}
`,
              },
            ],
          },
        ],
        conversation_id: crypto.randomUUID(),
        messages: [],
        log_message: false,
      },
      (message) => {
        setBetsyResponse((prev) => prev + message)
      },
      signal
    )
  }

  const debouncedFetchAskBetsy = useConstant(() => debounce(fetchAskBetsy, 1000))
  useAsyncAbortable(
    async (signal, fieldName, value) => {
      if (
        !(
          dealEvalAssistEnabled &&
          workflowRun?.kind === "renewal" &&
          !workflowRun.is_external &&
          document &&
          fieldName === "total_contract_value" &&
          value
        )
      ) {
        return
      }
      await debouncedFetchAskBetsy(signal, documentJSON, activeLegalAgreement, toolName)
    },
    [fieldName, value, vendorName, documentJSON] as const
  )
  return betsyResponse
}
