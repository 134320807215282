import type { FieldCategory, FormFieldConfig, ObjectType, WorkflowStepStandardType } from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { getTitle } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import { Badge, Button, Flex, HStack, Icon, Stack, Text, chakra } from "@chakra-ui/react"
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DraggableProvided,
  type DraggableStateSnapshot,
  type DropResult,
} from "@hello-pangea/dnd"
import type { JSONSchema, JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { useCallback } from "react"
import { FormattedMessage } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import CardWithHoverActions from "../../../components/CardWithHoverActions.js"
import { DragAndDropIcon, PlusIcon } from "../../../components/icons/icons.js"
import DeleteIconButton from "../../../components/icons/system/DeleteIconButton.js"
import { useObjectSchema } from "../../../util/use-schema.js"
import { enabledStandardObjectsForStepTypes } from "./utils.js"

export default function FieldConfigList({
  stepType,
  setSelectedFieldIndex,
  selectedFieldIndex,
  fields,
  appendField,
  removeField,
  moveField,
}: {
  stepType: WorkflowStepStandardType
  setSelectedFieldIndex: (index: number | null) => void
  selectedFieldIndex: number | null
  fields: FormFieldConfig[]
  appendField: (field: FormFieldConfig) => void
  removeField: (index: number) => void
  moveField: (from: number, to: number) => void
}) {
  const enabledStandardObjects = enabledStandardObjectsForStepTypes[stepType]
  const onDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (!destination) {
        // Dropped outside the list, do nothing
        return
      }
      moveField(source.index, destination.index)
      if (typeof selectedFieldIndex === "number") {
        if (source.index < selectedFieldIndex && destination.index >= selectedFieldIndex) {
          setSelectedFieldIndex(selectedFieldIndex - 1)
        } else if (source.index > selectedFieldIndex && destination.index <= selectedFieldIndex) {
          setSelectedFieldIndex(selectedFieldIndex + 1)
        }
        if (source.index === selectedFieldIndex) {
          setSelectedFieldIndex(destination.index)
        }
      }
    },
    [selectedFieldIndex, setSelectedFieldIndex, moveField]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Stack>
        <Droppable droppableId="step-fields">
          {(droppableProvided) => (
            <Stack paddingTop={2} {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
              {fields.map((field, idx) => (
                <FieldConfigItem
                  key={`${field.object_type}-${field.field_name}-${field.is_custom}`}
                  field={field}
                  removeField={() => removeField(idx)}
                  onClick={() => setSelectedFieldIndex(idx)}
                  selected={selectedFieldIndex === idx}
                  fieldIndex={idx}
                />
              ))}
              {droppableProvided.placeholder}
            </Stack>
          )}
        </Droppable>

        {enabledStandardObjects.map((objectType) => (
          <MissingStandardObjectFields
            key={objectType}
            objectType={objectType}
            stepType={stepType}
            enabledFields={fields}
            addField={appendField}
          />
        ))}
      </Stack>
    </DragDropContext>
  )
}

function FieldConfigItem({
  field,
  removeField,
  onClick,
  selected,
  fieldIndex,
}: {
  field: FormFieldConfig
  removeField: () => void
  onClick: () => void
  selected: boolean
  fieldIndex: number
}) {
  const objectSchema = useObjectSchema(field.object_type)
  const fieldSchema = field.is_custom
    ? objectSchema?.properties?.custom?.properties[field.field_name]
    : (objectSchema?.properties?.[field.field_name] as JSONSchemaObject | undefined)

  if (!fieldSchema) {
    return null
  }
  const categorySchema = FieldCategorySchema.anyOf.find((category) => category.const === fieldSchema.category)

  return (
    <Draggable
      draggableId={`${field.object_type}-${field.field_name}-${field.is_custom}`}
      index={fieldIndex}
      disableInteractiveElementBlocking
    >
      {(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => (
        <CardWithHoverActions
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          cardBodyProps={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          rightAction={
            !fieldSchema.requestConfigReadOnly ? <DeleteIconButton variant="unstyled" onClick={removeField} /> : null
          }
          colorScheme={field.is_custom ? "blue" : undefined}
          selected={selected}
          onClick={onClick}
          ref={draggableProvided.innerRef}
        >
          <chakra.span fontWeight="medium" flexShrink={1} minW={0} isTruncated>
            {getTitle(field.field_name, fieldSchema)}
          </chakra.span>
          <HStack>
            {field.is_custom && (
              <Badge colorScheme="blue" variant="subtleOutlined">
                <FormattedMessage
                  defaultMessage="Custom"
                  description="Badge for custom criterion"
                  id="settings.criteria.badge.custom"
                />
              </Badge>
            )}
            <Badge colorScheme={categorySchema?.colorScheme} variant="subtleOutlined">
              {categorySchema
                ? getTitle(fieldSchema.category, categorySchema)
                : getTitle(field.object_type, objectSchema)}
            </Badge>
            <Icon as={DragAndDropIcon} color={draggableSnapshot.isDragging ? "brand.500" : "gray.500"} />
          </HStack>
        </CardWithHoverActions>
      )}
    </Draggable>
  )
}

function MissingStandardObjectFields({
  objectType,
  stepType,
  enabledFields,
  addField,
}: {
  objectType: ObjectType
  stepType: WorkflowStepStandardType
  enabledFields: FormFieldConfig[]
  addField: (field: FormFieldConfig) => void
}) {
  const objectSchema = useObjectSchema(objectType)

  const missingFields = (Object.entries(objectSchema?.properties ?? {}) as [string, ReadonlyDeep<JSONSchema>][]).filter(
    ([prop, propSchema]) =>
      !isObject(propSchema) ||
      (!propSchema.readOnly &&
        !propSchema.requestConfigDisabled &&
        propSchema.displayable !== false &&
        prop !== "custom" &&
        propSchema.format !== "uuid" &&
        (!propSchema.category ||
          stepType === "close" ||
          (propSchema.category as FieldCategory) === stepType ||
          // TODO: This is a temporary fix to add requested_spend and requested_spend_interval to the details step
          // We could consider being less strict about the category check here and allow all tool fields in all tool-related steps
          (propSchema.category === ("finance" satisfies FieldCategory) && stepType === "details")) &&
        !enabledFields.some(
          (field) => field.object_type === objectType && field.field_name === prop && field.is_custom === false
        ))
  )

  const customProperties: JSONSchemaObject | undefined = objectSchema?.properties?.custom?.properties
  const missingCustomProperties = Object.entries(customProperties ?? {}).filter(
    ([prop, propSchema]) =>
      !enabledFields.some(
        (field) => field.object_type === objectType && field.field_name === prop && field.is_custom === true
      ) &&
      (!propSchema.category || (propSchema.category as FieldCategory) === stepType)
  )

  if (missingFields.length === 0 && missingCustomProperties.length === 0) {
    return null
  }

  return (
    <>
      <Text fontWeight="semibold">
        <FormattedMessage
          id="workflow-definition-create.missing-fields"
          defaultMessage="Add {objectName} fields"
          description="Label for the list of missing fields for a standard object"
          values={{ objectName: getTitle(objectType, objectSchema) }}
        />
      </Text>
      <Flex flexWrap="wrap" className="missing-props" gap={1} mb={1}>
        {missingFields.map(([missingProp, propSchema]) => (
          <Button
            key={missingProp}
            variant="outline"
            size="sm"
            leftIcon={<Icon as={PlusIcon} />}
            justifyContent="start"
            onClick={() =>
              addField({
                object_type: objectType,
                field_name: missingProp,
                is_custom: false,
                is_required: false,
              })
            }
          >
            <chakra.span isTruncated>{getTitle(missingProp, propSchema)}</chakra.span>
          </Button>
        ))}
        {missingCustomProperties.map(([customProp, fieldSchema]) => (
          <Button
            key={customProp}
            variant="subtleOutlined"
            size="sm"
            colorScheme="blue"
            justifyContent="start"
            leftIcon={<Icon as={PlusIcon} />}
            onClick={() =>
              addField({
                object_type: objectType,
                field_name: customProp,
                is_custom: true,
                is_required: false,
              })
            }
          >
            <chakra.span isTruncated>{getTitle(customProp, fieldSchema)}</chakra.span>
          </Button>
        ))}
      </Flex>
    </>
  )
}
