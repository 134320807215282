import type {
  ListQueryStringParams,
  PersonListItem,
  PersonStatus,
  SavedView,
  ToolDetails,
} from "@brm/schema-types/types.js"
import { Stack, Text } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import type { Paths } from "type-fest"
import { isNotUndefined } from "typed-assert"
import {
  usePostToolV1ByIdPersonsCsvMutation,
  usePostToolV1ByIdPersonsListQuery,
} from "../../app/services/generated-api.js"
import DataTable from "../../components/DataTable/DataTable.js"
import EmptyTableState from "../../components/DataTable/EmptyTableState.js"
import TableFilters from "../../components/DataTable/SchemaFilter/TablePageHeader.js"
import { useSchemaColumns } from "../../components/DataTable/use-schema-columns.js"
import {
  useLocalStorageTableParamsSync,
  useUrlTableParams,
} from "../../components/DataTable/use-schema-table-params.js"
import { Link } from "../../components/Link.js"
import { TABLE_INITIAL_PAGE, packageSortFilterOptionsForAPI } from "../../util/schema-table.js"
import type { FilterTabData } from "../../util/tabs.js"
import { getTabIndexFromLocation } from "../../util/tabs.js"
import { useObjectSchema } from "../../util/use-schema.js"
import { TOOL_USERS_TABLE_DEFAULT_PARAMS, TOOL_USERS_TABLE_ID } from "./constants.js"

interface Props {
  tool: ToolDetails
}

const primaryColumn = ""
const primarySearchColumn: Paths<PersonListItem> = "display_name"
// Update to dynamic once we have selectable columns
const defaultColumns: Paths<PersonListItem>[] = [
  "status",
  "hris_groups.department",
  "last_login.authorized_at",
  "last_login.event_type",
]

const columnPinning = {
  left: [primaryColumn],
}

const savedViews: SavedView[] = []

export default function ToolUsers({ tool }: Props) {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()

  const personSchema = useObjectSchema("Person")

  const [exportCsv, exportCsvResult] = usePostToolV1ByIdPersonsCsvMutation()

  const columns = useSchemaColumns<PersonListItem>({
    objectSchema: personSchema,
    shownColumns: defaultColumns,
    primaryColumn,
  })

  useLocalStorageTableParamsSync(TOOL_USERS_TABLE_ID)
  const { tableParams, updateTableParams, getUpdatedSerializedParams } = useUrlTableParams<string>({
    defaultParams: TOOL_USERS_TABLE_DEFAULT_PARAMS,
    objectSchema: personSchema,
    primarySearchColumn,
    savedViews,
  })

  const apiParams = useMemo(
    () => tableParams && personSchema && packageSortFilterOptionsForAPI<string>(tableParams, personSchema, intl),
    [intl, tableParams, personSchema]
  )

  const tabConfig = useMemo(
    () =>
      [
        {
          locationHash: "",
          label: (
            <FormattedMessage
              id="tool.user.list.tab.all"
              description="Tab label to show tool users"
              defaultMessage="All Users"
            />
          ),
        },
        {
          locationHash: "current",
          label: (
            <FormattedMessage
              id="tool.user.list.tab.current"
              description="Tab label to show all current tool users"
              defaultMessage="Current"
            />
          ),
          filters: [
            [
              {
                column: "status",
                fields: {
                  comparator: "any",
                  values: ["active_employee", "active_user"] satisfies PersonStatus[],
                },
              },
            ],
          ],
        },
        {
          locationHash: "former",
          label: (
            <FormattedMessage
              id="tool.user.list.tab.former"
              description="Tab label to show all former employee tool users"
              defaultMessage="Former"
            />
          ),
          filters: [
            [
              {
                column: "status",
                fields: {
                  comparator: "any",
                  values: ["inactive_employee", "inactive_user"] satisfies PersonStatus[],
                },
              },
            ],
          ],
        },
      ] as const satisfies FilterTabData<Paths<PersonListItem>>[],
    []
  )

  const [tabIndex, setTabIndex] = useState(getTabIndexFromLocation(location, tabConfig))

  const apiParamsForTabs =
    apiParams &&
    ([
      apiParams,
      { ...apiParams, filter: [...(apiParams?.filter ?? []), ...(tabConfig[1]?.filters ?? [])] },
      { ...apiParams, filter: [...(apiParams?.filter ?? []), ...(tabConfig[2]?.filters ?? [])] },
    ] satisfies [ListQueryStringParams, ListQueryStringParams, ListQueryStringParams])

  const allUsersQuery = usePostToolV1ByIdPersonsListQuery(
    apiParamsForTabs ? { id: tool.id, listQueryStringParams: apiParamsForTabs[0] } : skipToken
  )
  const currentEmployeesQuery = usePostToolV1ByIdPersonsListQuery(
    apiParamsForTabs ? { id: tool.id, listQueryStringParams: apiParamsForTabs[1] } : skipToken
  )
  const formerEmployeesQuery = usePostToolV1ByIdPersonsListQuery(
    apiParamsForTabs ? { id: tool.id, listQueryStringParams: apiParamsForTabs[2] } : skipToken
  )

  if (!getUpdatedSerializedParams || !columns || !personSchema || !apiParamsForTabs) {
    return null
  }

  const handleChangeTab = (index: number) => {
    const newTab = tabConfig[index]
    isNotUndefined(newTab)
    const hashParams = new URLSearchParams(location.hash.slice(1))
    if (newTab.locationHash) {
      hashParams.set("tab", newTab.locationHash)
    } else {
      hashParams.delete("tab")
    }
    navigate(
      { search: getUpdatedSerializedParams({ page: TABLE_INITIAL_PAGE }).toString(), hash: `#${hashParams}` },
      { replace: true }
    )
    setTabIndex(index)
  }

  const tabData = [allUsersQuery.data, currentEmployeesQuery.data, formerEmployeesQuery.data]
  const data = tabData[tabIndex]
  const isFetching = [allUsersQuery.isFetching, currentEmployeesQuery.isFetching, formerEmployeesQuery.isFetching][
    tabIndex
  ]

  return (
    <Stack width="full" gap={4}>
      <Text color="gray.800">
        <FormattedMessage
          id="tool.users.description"
          description="Description of the tool users page"
          defaultMessage="Users of a tool are derived from Google or Okta sign-in data. <integrationsLink>Manage your integrations</integrationsLink>"
          values={{
            integrationsLink: (parts) => (
              <Link color="brand.500" to="/settings/integrations">
                {parts}
              </Link>
            ),
          }}
        />
      </Text>
      <TableFilters
        tableId={TOOL_USERS_TABLE_ID}
        tabProps={{
          data: tabConfig.map((tab, idx) => ({ ...tab, total: tabData[idx]?.total })),
          handleChangeTab,
          selectedIndex: tabIndex,
        }}
        objectSchema={personSchema}
        selectedColumns={defaultColumns}
        primarySearch={{
          column: primarySearchColumn,
          currentPrimaryFilter: tableParams.primaryFilter?.fields,
          placeholder: intl.formatMessage({
            id: "tool.list.search.name",
            description: "text showing the option to search tools by the name column",
            defaultMessage: "Search users by name",
          }),
          onPrimaryFilterChange: (fields) =>
            updateTableParams({ primaryFilter: fields && { column: primarySearchColumn, fields } }),
        }}
        hideShowAllFilters
        filterMap={tableParams.filterMap}
        onChangeFilters={(filterMap) => updateTableParams({ filterMap })}
        isExporting={exportCsvResult.isLoading}
        exportCsv={async () => {
          const result = await exportCsv({
            id: tool.id,
            body: { ...apiParamsForTabs[tabIndex], columns: defaultColumns },
          }).unwrap()
          return result.url
        }}
      />
      {data ? (
        <DataTable
          borderWidth="1px"
          overflow="auto"
          data={data.persons}
          columns={columns}
          columnPinning={columnPinning}
          sorting={tableParams.sorting}
          onSortingChange={(sorting) =>
            sorting instanceof Function
              ? updateTableParams({ sorting: sorting(tableParams.sorting) })
              : updateTableParams({ sorting })
          }
          paginationProps={{
            page: tableParams.page,
            pageSize: tableParams.pageSize,
            onPageChange: (page) => updateTableParams({ page }),
            onPageSizeChange: (pageSize) => updateTableParams({ pageSize }),
            totalListElements: data.total,
          }}
        />
      ) : (
        !isFetching &&
        tableParams.filterMap.size === 0 && (
          <EmptyTableState
            emptyState={intl.formatMessage({
              id: "tool.users.emptyState",
              description: "Text to display instead of the users table when there are no users for the tool",
              defaultMessage:
                "No user data found. This tool is not accessed using single sign-on or your single sign-on provider is not integrated with BRM",
            })}
          />
        )
      )}
    </Stack>
  )
}
