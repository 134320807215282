import type {
  FieldCategory,
  LinkWorkflowSellerInvite,
  PersonPicker,
  UserPicker,
  WorkflowKind,
  WorkflowRunInput,
} from "@brm/schema-types/types.js"
import { WorkflowRenewalKindSchema } from "@brm/schemas"
import { displayPersonName } from "@brm/util/names.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Text,
  useToast,
  type ModalProps,
  type UseModalProps,
} from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import type { ReactElement } from "react"
import { useEffect, useMemo, useState } from "react"
import { useForm, type Control } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { isNotNull } from "typed-assert"
import avatarGroup from "../../../../../assets/avatarGroup.png"
import {
  useGetToolV1ByIdQuery,
  useGetUserV1WhoamiQuery,
  useGetVendorV1ByIdQuery,
  useLazyGetLegalV1AgreementsByIdQuery,
  usePostToolV1Mutation,
  usePostVendorV1Mutation,
  usePostWorkflowV1RunsMutation,
} from "../../../../app/services/generated-api.js"
import { CancelButton } from "../../../../components/buttons.js"
import { NextIcon, SendIcon } from "../../../../components/icons/icons.js"
import Spinner from "../../../../components/spinner.js"
import { getAPIErrorMessage } from "../../../../util/error.js"
import type { LegalAgreementOption } from "../../../legal/LegalAgreementPicker.js"
import { displayWorkflowKind } from "../../util.js"
import type {
  ModalScreens,
  WorkflowInviteSectionFormState,
  WorkflowLegalAgreementSectionFormState,
  WorkflowObjectType,
  WorkflowSectionFormState,
  WorkflowToolSectionFormState,
  WorkflowVendorSectionFormState,
} from "./util.js"
import {
  WorkflowLegalAgreementSectionFields,
  WorkflowSectionFields,
  WorkflowToolSectionFields,
  WorkflowVendorSectionFields,
  generateWorkflowDisplayName,
  getWorkflowProgressPercentage,
  type StartWorkflowFormState,
} from "./util.js"
import WorkflowConfirmSection from "./WorkflowConfirmSection.js"
import WorkflowInviteSection from "./WorkflowInviteSection.js"
import WorkflowKindSection from "./WorkflowKindSection.js"
import WorkflowLegalAgreementSection from "./WorkflowLegalAgreementSection.js"
import WorkflowObjectTypeSection from "./WorkflowObjectTypeSection.js"
import WorkflowSection from "./WorkflowSection.js"
import WorkflowToolSection from "./WorkflowToolSection.js"
import WorkflowVendorSection from "./WorkflowVendorSection.js"

type Props = UseModalProps &
  Pick<ModalProps, "returnFocusOnClose"> & {
    initialLegalAgreementId?: string
    kind?: WorkflowKind
    toolId?: string
    category?: FieldCategory
  }

export default function StartWorkflowModal({ initialLegalAgreementId, ...props }: Props) {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const [getLegalAgreement, legalAgreementResponse] = useLazyGetLegalV1AgreementsByIdQuery()

  useEffect(() => {
    if (initialLegalAgreementId && props.isOpen) {
      void getLegalAgreement({ id: initialLegalAgreementId })
    }
  }, [initialLegalAgreementId, getLegalAgreement, props.isOpen])

  if (!whoami) {
    return null
  }

  return (
    <StartWorkflowModalInternal
      initialUser={{
        id: whoami.id,
        first_name: whoami.first_name,
        last_name: whoami.last_name,
        display_name: displayPersonName(whoami, intl),
        email: whoami.email,
        object_type: "User",
        organization_id: whoami.organization_id,
        image_asset: whoami.profile_image,
        roles: whoami.roles,
        type: "user",
      }}
      initialLegalAgreement={legalAgreementResponse.data}
      initialLegalAgreementLoading={
        initialLegalAgreementId ? legalAgreementResponse.isLoading || !legalAgreementResponse.data : false
      }
      {...props}
    />
  )
}

function StartWorkflowModalInternal({
  initialLegalAgreement,
  initialLegalAgreementLoading,
  isOpen,
  onClose,
  kind,
  toolId,
  returnFocusOnClose,
  initialUser,
  category,
}: Props & {
  initialUser: UserPicker
  initialLegalAgreement?: LegalAgreementOption
  initialLegalAgreementLoading?: boolean
}) {
  const toast = useToast()
  const intl = useIntl()
  const navigate = useNavigate()

  const [createWorkflowRun, { isLoading }] = usePostWorkflowV1RunsMutation()
  const [createNewToolListing, { isLoading: newToolCreateIsLoading }] = usePostToolV1Mutation()
  const [createNewVendorListing, { isLoading: newVendorCreateIsLoading }] = usePostVendorV1Mutation()

  const legalAgreementFirstTool = initialLegalAgreement?.tools[0]
  const legalAgreementFirstVendor = initialLegalAgreement?.vendor
  const preselectedRenewalTool = useMemo(
    () =>
      legalAgreementFirstTool && legalAgreementFirstVendor
        ? { ...legalAgreementFirstTool, vendor: legalAgreementFirstVendor }
        : undefined,
    [legalAgreementFirstTool, legalAgreementFirstVendor]
  )

  const isPreselectedRenewal =
    initialLegalAgreementLoading ||
    kind === WorkflowRenewalKindSchema.const ||
    Boolean(preselectedRenewalTool) ||
    Boolean(legalAgreementFirstVendor)

  const [workflowObjectType, setWorkflowObjectType] = useState<WorkflowObjectType | null>(
    isPreselectedRenewal ? (legalAgreementFirstTool ? "Tool" : "Vendor") : null
  )

  const [workflowKind, setWorkflowKind] = useState<WorkflowKind | null>(
    isPreselectedRenewal ? WorkflowRenewalKindSchema.const : kind || null
  )

  const form = useForm<StartWorkflowFormState>({
    defaultValues: {
      tool: isPreselectedRenewal && preselectedRenewalTool ? preselectedRenewalTool : null,
      vendor: isPreselectedRenewal && legalAgreementFirstVendor ? legalAgreementFirstVendor : null,
      tool_or_vendor_owner: null,
      display_name:
        preselectedRenewalTool && workflowKind
          ? generateWorkflowDisplayName(preselectedRenewalTool.display_name, workflowKind, intl, category)
          : "",
      workflow_owner: initialUser,
      departments: [],
      target_date: null,
      legal_agreement: isPreselectedRenewal && initialLegalAgreement ? initialLegalAgreement : null,
      new_legal_agreement_name: null,
      new_tool_or_vendor_url: null,
      new_tool_or_vendor_display_name: null,
      recipients: [{ first_name: "", last_name: "", email: "" }],
      message: null,
      seller_due_date: null,
      skipped_invites: false,
      category: category ?? null,
    } satisfies StartWorkflowFormState,
  })

  const onSubmit = form.handleSubmit(async (values: StartWorkflowFormState) => {
    isNotNull(workflowKind)
    isNotNull(workflowObjectType)
    isNotNull(values.workflow_owner)
    isNotNull(values.target_date)

    const workflowLinkInvites: LinkWorkflowSellerInvite | null = values.skipped_invites
      ? null
      : {
          recipients: values.recipients,
          message: values.message,
          due_date: values.seller_due_date,
        }

    let workflowRunInput: WorkflowRunInput
    if (workflowKind === "purchase") {
      if (workflowObjectType === "Tool") {
        isNotNull(values.tool)
        workflowRunInput = {
          kind: "purchase",
          tools: [values.tool],
          tool_owner_id: values.tool_or_vendor_owner?.id,
          display_name: values.display_name,
          owner_id: values.workflow_owner.id,
          target_date: values.target_date,
          employee_group_ids: values.departments.map((department) => department.id),
          link_invite: workflowLinkInvites,
        }
      } else {
        isNotNull(values.vendor)
        workflowRunInput = {
          kind: "purchase",
          vendor: values.vendor,
          vendor_owner_id: values.tool_or_vendor_owner?.id,
          display_name: values.display_name,
          owner_id: values.workflow_owner.id,
          target_date: values.target_date,
          employee_group_ids: values.departments.map((department) => department.id),
          link_invite: workflowLinkInvites,
        }
      }
    } else if (workflowKind === "renewal") {
      if (values.legal_agreement) {
        workflowRunInput = {
          kind: "renewal",
          active_legal_agreement_id: values.legal_agreement.id,
          tool_owner_id: values.tool_or_vendor_owner?.id,
          vendor_owner_id: values.tool_or_vendor_owner?.id,
          display_name: values.display_name,
          owner_id: values.workflow_owner.id,
          target_date: values.target_date,
          employee_group_ids: values.departments.map((department) => department.id),
          link_invite: workflowLinkInvites,
        }
      } else {
        workflowRunInput = {
          kind: "renewal",
          tools: values.tool ? [values.tool] : undefined,
          vendor: values.vendor ?? undefined,
          new_legal_agreement_name: values.new_legal_agreement_name || undefined,
          tool_owner_id: values.tool_or_vendor_owner?.id,
          vendor_owner_id: values.tool_or_vendor_owner?.id,
          display_name: values.display_name,
          owner_id: values.workflow_owner.id,
          target_date: values.target_date,
          employee_group_ids: values.departments.map((department) => department.id),
          link_invite: workflowLinkInvites,
        }
      }
    } else if (workflowKind === "gather_data") {
      isNotNull(values.tool, "Expected tool to be selected for data gathering")
      isNotNull(values.category, "Expected category to be selected for data gathering")
      workflowRunInput = {
        kind: "gather_data",
        tool: values.tool,
        tool_owner_id: values.tool_or_vendor_owner?.id,
        display_name: values.display_name,
        owner_id: values.workflow_owner.id,
        target_date: values.target_date,
        employee_group_ids: values.departments.map((department) => department.id),
        link_invite: workflowLinkInvites,
        category: values.category,
      }
    } else {
      unreachable(workflowKind)
    }

    try {
      const newWorkflow = await createWorkflowRun({ workflowRunInput }).unwrap()
      const detailStep = newWorkflow.steps.find((step) => step.type === "details")
      onClose()
      // navigate to the details step of the request
      const pathname = `/requests/${newWorkflow.id}`
      if (detailStep) {
        navigate({
          pathname,
          search: new URLSearchParams({ step: detailStep.id }).toString(),
        })
      } else {
        navigate(pathname)
      }
    } catch (err: unknown) {
      const errorDescription = getAPIErrorMessage(err)
      toast({
        title: intl.formatMessage(
          {
            id: "requests.start.error.toast",
            description: "Start request error toast title",
            defaultMessage: "There was an issue creating this {workflowKind} request",
          },
          { workflowKind: displayWorkflowKind(workflowKind) }
        ),
        description: errorDescription,
        status: "error",
      })
      // The invite screen doesn't have a back button so we revert to a previous screen to let the user potentially resolve the error.
      setModalState("confirmation")
    }
  })

  const selectedToolId = form.watch("tool")?.id || toolId
  const selectedVendorId = form.watch("vendor")?.id

  const { data: vendorDetails, isFetching: vendorIsFetching } = useGetVendorV1ByIdQuery(
    selectedVendorId ? { id: selectedVendorId } : skipToken
  )

  const { data: fetchedTool, isFetching: toolIsFetching } = useGetToolV1ByIdQuery(
    selectedToolId ? { id: selectedToolId } : skipToken
  )
  const selectedToolWithDetails = selectedToolId ? fetchedTool : undefined

  const [modalState, setModalState] = useState<ModalScreens>(
    initialLegalAgreementLoading
      ? "loadingLegalAgreement"
      : isPreselectedRenewal
        ? "legalAgreement"
        : kind === "purchase"
          ? "toolSelection"
          : kind === "gather_data"
            ? "workflow"
            : "workflowSelection"
  )

  // Resolve the loading of the legal agreement
  useEffect(() => {
    if (!initialLegalAgreementLoading) {
      setModalState(
        isPreselectedRenewal
          ? "legalAgreement"
          : kind === "purchase"
            ? "toolSelection"
            : kind === "gather_data"
              ? "workflow"
              : "workflowSelection"
      )
      form.setValue("legal_agreement", isPreselectedRenewal && initialLegalAgreement ? initialLegalAgreement : null)
      form.setValue("tool", isPreselectedRenewal && preselectedRenewalTool ? preselectedRenewalTool : null)
      form.setValue("vendor", isPreselectedRenewal && legalAgreementFirstVendor ? legalAgreementFirstVendor : null)
    }
  }, [
    initialLegalAgreementLoading,
    isPreselectedRenewal,
    kind,
    form,
    initialLegalAgreement,
    legalAgreementFirstVendor,
    preselectedRenewalTool,
  ])

  useEffect(() => {
    let owner: PersonPicker | null = null
    if (selectedToolWithDetails?.owner) {
      owner = {
        type: "person",
        image_asset: selectedToolWithDetails.owner.profile_image,
        ...selectedToolWithDetails.owner,
      }
    } else if (vendorDetails?.owner) {
      owner = {
        type: "person",
        image_asset: vendorDetails.owner.profile_image,
        ...vendorDetails.owner,
      }
    }
    form.setValue("tool_or_vendor_owner", owner)
  }, [selectedToolWithDetails?.owner, vendorDetails?.owner, form])

  useEffect(() => {
    if (!form.watch("tool") && selectedToolWithDetails) {
      form.setValue("tool", selectedToolWithDetails)
      if (workflowKind) {
        form.setValue(
          "display_name",
          generateWorkflowDisplayName(selectedToolWithDetails.display_name, workflowKind, intl, category)
        )
      }
    }
  }, [selectedToolWithDetails, workflowKind, form, intl, category])

  useEffect(() => {
    if (vendorDetails) {
      if (workflowKind) {
        form.setValue(
          "display_name",
          generateWorkflowDisplayName(vendorDetails.display_name, workflowKind, intl, category)
        )
      }
    }
  }, [vendorDetails, workflowKind, form, intl, category])

  const progressBar = <Progress value={getWorkflowProgressPercentage(modalState, workflowKind)} size="sm" w={200} />

  const screens: Record<ModalScreens, ReactElement> = {
    loadingLegalAgreement: (
      <ModalBody>
        <Center h="100%">
          <Spinner />
        </Center>
      </ModalBody>
    ),
    workflowSelection: (
      <>
        <ModalBody>
          <WorkflowKindSection
            startWorkflow={async (workflowKind: WorkflowKind) => {
              setWorkflowKind(workflowKind)
              if (workflowKind === "gather_data") {
                form.setValue("category", "compliance")
                setWorkflowObjectType("Tool")
                setModalState("toolSelection")
              } else {
                setModalState("objectTypeSelection")
              }
            }}
            closeModal={onClose}
          />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          {progressBar}
          <CancelButton onClick={onClose} />
        </ModalFooter>
      </>
    ),
    objectTypeSelection: (
      <>
        <ModalBody>
          <WorkflowObjectTypeSection
            workflowKind={workflowKind}
            onSelectObjectType={(objectType: WorkflowObjectType) => {
              isNotNull(workflowKind, "Expected workflow kind to be set when selecting object type")
              setWorkflowObjectType(objectType)
              if (workflowKind === "purchase") {
                if (objectType === "Tool") {
                  setModalState("toolSelection")
                } else if (objectType === "Vendor") {
                  setModalState("vendorSelection")
                } else {
                  unreachable(objectType)
                }
              } else if (workflowKind === "renewal") {
                setModalState("legalAgreement")
              } else if (workflowKind === "gather_data") {
                throw new Error(
                  "Gather data requests currently only support tools. Selecting that option should take the user straight to the tool selection screen."
                )
              } else {
                unreachable(workflowKind)
              }
            }}
          />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          {progressBar}
          <Button onClick={() => setModalState("workflowSelection")}>
            <FormattedMessage
              id="requests.start.modal.back"
              defaultMessage="Back"
              description="Request start modal back button text"
            />
          </Button>
        </ModalFooter>
      </>
    ),
    vendorSelection: (
      <>
        <ModalBody>
          <WorkflowVendorSection
            control={form.control as unknown as Control<WorkflowVendorSectionFormState>}
            vendorDetails={vendorDetails}
            workflowKind={workflowKind}
            setValue={form.setValue}
            selectedVendor={form.watch("vendor")}
            newVendorCreateIsLoading={newVendorCreateIsLoading}
          />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          {progressBar}
          <Flex gap={2}>
            <Button
              isDisabled={newVendorCreateIsLoading}
              variant="ghost"
              onClick={() => {
                form.setValue("tool_or_vendor_owner", null)
                setModalState("objectTypeSelection")
              }}
            >
              <FormattedMessage
                id="requests.start.modal.back"
                defaultMessage="Back"
                description="Request start modal back button text"
              />
            </Button>
            <Button
              isLoading={vendorIsFetching || newVendorCreateIsLoading}
              colorScheme="brand"
              onClick={async () => {
                const formValues = form.getValues()
                if (formValues.new_tool_or_vendor_display_name) {
                  // If a new tool display name is set, user is in the flow to create a new tool
                  const isValidUrl = await form.trigger(["new_tool_or_vendor_display_name", "new_tool_or_vendor_url"])
                  if (!isValidUrl) return
                  isNotNull(formValues.new_tool_or_vendor_url, "Expected new vendor URL to be set")
                  isNotNull(formValues.new_tool_or_vendor_display_name, "Expected new vendor display name to be set")
                  try {
                    const newVendorListing = await createNewVendorListing({
                      body: {
                        user_provided_url: formValues.new_tool_or_vendor_url,
                        user_provided_display_name: formValues.new_tool_or_vendor_display_name,
                      },
                    }).unwrap()

                    form.setValue("new_tool_or_vendor_display_name", null)
                    form.setValue("new_tool_or_vendor_url", null)
                    form.setValue("vendor", newVendorListing)
                    if (workflowKind) {
                      form.setValue(
                        "display_name",
                        generateWorkflowDisplayName(newVendorListing.display_name, workflowKind, intl)
                      )
                    }
                  } catch (err) {
                    const errorDescription = getAPIErrorMessage(err)
                    toast({
                      title: intl.formatMessage(
                        {
                          id: "requests.start.error.toast",
                          description: "Start request error toast title",
                          defaultMessage:
                            "There was an issue finding information about {toolName} from {url}. Please try again with a different url or contact support.",
                        },
                        { toolName: formValues.new_tool_or_vendor_display_name, url: formValues.new_tool_or_vendor_url }
                      ),
                      description: errorDescription,
                      status: "error",
                      duration: null,
                    })
                  }

                  return
                }
                const isValid = await form.trigger(WorkflowVendorSectionFields)
                if (!isValid) return
                setModalState("workflow")
              }}
              rightIcon={<Icon as={NextIcon} />}
            >
              <FormattedMessage
                id="requests.start.modal.continue"
                defaultMessage="Continue"
                description="Request start modal continue button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
    toolSelection: (
      <>
        <ModalBody>
          <WorkflowToolSection
            control={form.control as unknown as Control<WorkflowToolSectionFormState>}
            selectedToolWithDetails={selectedToolWithDetails}
            selectedToolOption={form.watch("tool")}
            workflowKind={workflowKind}
            isPreselectedTool={Boolean(toolId)}
            setValue={form.setValue}
            newToolCreateIsLoading={newToolCreateIsLoading}
          />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          {progressBar}
          <Flex gap={2}>
            <Button
              isDisabled={toolIsFetching || newToolCreateIsLoading}
              variant="ghost"
              onClick={() => {
                form.setValue("tool_or_vendor_owner", null)
                if (workflowKind === "gather_data") {
                  // The gather data workflow auto selects the object type so we reset it
                  setWorkflowObjectType(null)
                  setModalState("workflowSelection")
                } else {
                  setModalState("objectTypeSelection")
                }
              }}
            >
              <FormattedMessage
                id="requests.start.modal.back"
                defaultMessage="Back"
                description="Request start modal back button text"
              />
            </Button>
            <Button
              isLoading={toolIsFetching || newToolCreateIsLoading}
              colorScheme="brand"
              onClick={async () => {
                const formValues = form.getValues()
                if (formValues.new_tool_or_vendor_display_name) {
                  // If a new tool display name is set, user is in the flow to create a new tool
                  const isValidUrl = await form.trigger(["new_tool_or_vendor_display_name", "new_tool_or_vendor_url"])
                  if (!isValidUrl) return
                  isNotNull(formValues.new_tool_or_vendor_url, "Expected new tool URL to be set")
                  isNotNull(formValues.new_tool_or_vendor_display_name, "Expected new tool display name to be set")
                  try {
                    const newToolListing = await createNewToolListing({
                      body: {
                        user_provided_url: formValues.new_tool_or_vendor_url,
                        user_provided_display_name: formValues.new_tool_or_vendor_display_name,
                      },
                    }).unwrap()

                    form.setValue("new_tool_or_vendor_display_name", null)
                    form.setValue("new_tool_or_vendor_url", null)
                    form.setValue("tool", newToolListing)
                    if (workflowKind) {
                      form.setValue(
                        "display_name",
                        generateWorkflowDisplayName(newToolListing.display_name, workflowKind, intl)
                      )
                    }
                  } catch (err) {
                    const errorDescription = getAPIErrorMessage(err)
                    toast({
                      title: intl.formatMessage(
                        {
                          id: "requests.start.error.toast",
                          description: "Start request error toast title",
                          defaultMessage:
                            "There was an issue finding information about {toolName} from {url}. Please try again with a different url or contact support.",
                        },
                        { toolName: formValues.new_tool_or_vendor_display_name, url: formValues.new_tool_or_vendor_url }
                      ),
                      description: errorDescription,
                      status: "error",
                      duration: null,
                    })
                  }

                  return
                }

                // trigger form validation
                const isValid = await form.trigger(WorkflowToolSectionFields)
                if (!isValid) return
                setModalState("workflow")
              }}
              rightIcon={<Icon as={NextIcon} />}
            >
              <FormattedMessage
                id="requests.start.modal.continue"
                defaultMessage="Continue"
                description="Request start modal continue button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
    legalAgreement: (
      <>
        <ModalBody overflowY="auto">
          <WorkflowLegalAgreementSection
            workflowObjectType={workflowObjectType}
            control={form.control as unknown as Control<WorkflowLegalAgreementSectionFormState>}
            toolDetails={selectedToolWithDetails}
            vendorDetails={vendorDetails}
            selectedLegalAgreement={form.watch("legal_agreement")}
            isPreselectedToolRenewal={isPreselectedRenewal}
            workflowKind={workflowKind}
            setValue={form.setValue}
          />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          {progressBar}
          <Flex gap={2}>
            <Button
              variant="ghost"
              onClick={() => {
                setModalState("workflowSelection")
              }}
            >
              <FormattedMessage
                id="requests.start.modal.back"
                defaultMessage="Back"
                description="Request start modal back button text"
              />
            </Button>
            <Button
              colorScheme="brand"
              onClick={async () => {
                const isValid = await form.trigger(WorkflowLegalAgreementSectionFields)
                if (!isValid) return
                setModalState("workflow")
              }}
              isLoading={toolIsFetching}
              rightIcon={<Icon as={NextIcon} />}
            >
              <FormattedMessage
                id="requests.start.modal.continue"
                defaultMessage="Continue"
                description="Request start modal continue button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
    workflow: (
      <>
        <ModalBody>
          <WorkflowSection
            control={form.control as unknown as Control<WorkflowSectionFormState>}
            workflowKind={workflowKind}
            departments={form.watch("departments")}
          />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          {progressBar}
          <Flex gap={2}>
            {!(workflowKind === "gather_data" && toolId) && (
              <Button
                variant="ghost"
                onClick={() => {
                  isNotNull(workflowObjectType)
                  isNotNull(workflowKind)
                  switch (workflowKind) {
                    case "purchase":
                      if (workflowObjectType === "Tool") {
                        setModalState("toolSelection")
                      } else if (workflowObjectType === "Vendor") {
                        setModalState("vendorSelection")
                      } else {
                        unreachable(workflowObjectType)
                      }
                      break
                    case "renewal":
                      setModalState("legalAgreement")
                      break
                    case "gather_data":
                      setModalState("toolSelection")
                      break
                    default:
                      unreachable(workflowKind)
                  }
                }}
              >
                <FormattedMessage
                  id="requests.start.modal.back"
                  defaultMessage="Back"
                  description="Request start modal back button text"
                />
              </Button>
            )}
            <Button
              colorScheme="brand"
              onClick={async () => {
                // trigger form validation
                const isValid = await form.trigger(WorkflowSectionFields)
                if (!isValid) return
                setModalState("confirmation")
              }}
              rightIcon={<Icon as={NextIcon} />}
            >
              <FormattedMessage
                id="requests.start.modal.continue"
                defaultMessage="Continue"
                description="Request start modal continue button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
    confirmation: (
      <>
        <ModalBody>
          <WorkflowConfirmSection
            workflowObjectType={workflowObjectType}
            control={form.control}
            selectedAgreement={form.watch("legal_agreement")}
            selectedTool={form.watch("tool")}
            toolDetails={selectedToolWithDetails}
            selectedVendor={form.watch("vendor")}
            newLegalAgreementName={form.watch("new_legal_agreement_name")}
            departments={form.watch("departments")}
          />
        </ModalBody>
        <ModalFooter gap={2} justifyContent="space-between">
          {progressBar}
          <Flex gap={2}>
            <Button
              variant="ghost"
              onClick={() => {
                setModalState("workflow")
              }}
            >
              <FormattedMessage
                id="requests.start.modal.back"
                defaultMessage="Back"
                description="Request start modal back button text"
              />
            </Button>
            <Button
              colorScheme="brand"
              onClick={() => {
                setModalState("inviteNewUsers")
              }}
              isLoading={isLoading}
            >
              <FormattedMessage
                id="requests.start.modal.continue"
                defaultMessage="Continue"
                description="Request start modal continue button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
    inviteNewUsers: (
      <>
        <ModalBody>
          <WorkflowInviteSection control={form.control as unknown as Control<WorkflowInviteSectionFormState>} />
        </ModalBody>
        <ModalFooter gap={2} justifyContent="space-between">
          {progressBar}
          <Flex gap={2}>
            <Button
              variant="ghost"
              onClick={async (event) => {
                form.setValue("skipped_invites", true)
                await onSubmit(event)
              }}
              isDisabled={isLoading}
            >
              <FormattedMessage
                id="requests.start.modal.invite.skip"
                defaultMessage="Invite seller later"
                description="Invite seller later button text"
              />
            </Button>
            <Button colorScheme="brand" onClick={onSubmit} isLoading={isLoading} rightIcon={<Icon as={SendIcon} />}>
              <FormattedMessage
                id="requests.start.modal.inviteSellers"
                defaultMessage="Invite seller"
                description="Request start modal invite seller button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
  }

  return (
    <Modal
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}
      returnFocusOnClose={returnFocusOnClose}
    >
      <ModalOverlay />
      <ModalContent height={680}>
        <StartWorkflowModalHeader modalState={modalState} workflowKind={workflowKind} />
        {screens[modalState]}
      </ModalContent>
    </Modal>
  )
}

interface StartWorkflowModalHeaderProps {
  modalState: ModalScreens
  vendorName?: string
  workflowKind: WorkflowKind | null
}

const StartWorkflowModalHeader: React.FC<StartWorkflowModalHeaderProps> = (props) => {
  switch (props.modalState) {
    case "objectTypeSelection":
    case "workflowSelection":
    case "vendorSelection":
    case "toolSelection":
    case "legalAgreement":
    case "workflow":
    case "confirmation":
    case "loadingLegalAgreement":
      return (
        <ModalHeader>
          {props.workflowKind ? (
            <FormattedMessage
              id="requests.start.modal.title.withWorkflowKind"
              description="Title of the start request modal"
              defaultMessage="Create {workflowKind} Request"
              values={{ workflowKind: displayWorkflowKind(props.workflowKind) }}
            />
          ) : (
            <FormattedMessage
              id="requests.start.modal.title.default"
              description="Title of the start request modal"
              defaultMessage="Create New Request"
            />
          )}
          <ModalCloseButton />
        </ModalHeader>
      )
    case "inviteNewUsers":
      return (
        <ModalHeader>
          <Stack alignItems="center">
            <Image src={avatarGroup} alt="Invite new users" padding={4} width="30%" />
            <Text>
              <FormattedMessage
                id="requests.start.modal.inviteNewUsers.header"
                defaultMessage="Send Link to Seller"
                description="Request start modal invite new users text"
              />
            </Text>
            <Text textAlign="center" fontSize="sm" fontWeight="normal">
              <FormattedMessage
                id="requests.start.modal.inviteNewUsers.subheading"
                defaultMessage="Invite members of the sales team to help you complete the request. You will be able to view their inputs and make changes before you submit the step for approval. You may invite others to collaborate at any time."
                description="Request start modal invite new users text"
              />
            </Text>
          </Stack>
        </ModalHeader>
      )
    default:
      unreachable(props.modalState)
  }
}
