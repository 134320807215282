import { Button, Card, CardBody, CardFooter, Divider, Flex, HStack, Spacer, Text } from "@chakra-ui/react"
import type { ReactElement, ReactNode } from "react"
import { Link } from "../../components/Link.js"

interface Props {
  icon: ReactElement
  title: string
  headerAction?: ReactElement
  footer?: {
    link: string
    text: string
  }
  children?: ReactNode
}

export default function SummaryCard({ icon, title, children, headerAction, footer }: Props) {
  return (
    <Card variant="outline" height="full">
      <CardBody p={4}>
        <HStack fontWeight="semibold">
          <Card variant="outline" padding={2}>
            {icon}
          </Card>
          <Text as="span" fontSize="lg">
            {title}
          </Text>
          <Spacer />
          {headerAction}
        </HStack>
        {children}
      </CardBody>
      {footer && (
        <CardFooter p={0} display="flex" flexDirection="column">
          <Divider borderColor="gray.300" />
          <Flex p={4}>
            <Spacer />
            <Button as={Link} fontSize="sm" variant="link" color="brand.500" to={footer.link}>
              {footer.text}
            </Button>
          </Flex>
        </CardFooter>
      )}
    </Card>
  )
}
