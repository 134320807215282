import type { CreditCardListItem, CreditCardStatus } from "@brm/schema-types/types.js"
import { CreditCardStatusSchema } from "@brm/schemas"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import type { EnumTypeSchema } from "@brm/util/schema.js"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AvatarGroup,
  Button,
  Center,
  Card as ChakraCard,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import {
  usePostCreditCardV1ListQuery,
  usePostCreditCardV1TerminateByIdMutation,
  type PostCreditCardV1ListApiArg,
} from "../app/services/generated-api.js"
import EmptyTableState from "../components/DataTable/EmptyTableState.js"
import { useLocalStorageTableParamsSync } from "../components/DataTable/use-schema-table-params.js"
import {
  packageSortFilterOptionsForAPI,
  shownColumnsForTableParamState,
  TABLE_DEFAULT_PARAMS,
  type TableParamsState,
} from "../util/schema-table.js"
import { getPublicImageGcsUrl } from "../util/url.js"
import { useObjectSchema } from "../util/use-schema.js"
import { CREDIT_CARDS_TABLE_ID } from "./constants.js"
import TablePageHeader from "./DataTable/SchemaFilter/TablePageHeader.js"
import { EnumBadge } from "./EnumBadge.js"
import { FormattedCurrency } from "./FormattedCurrency.js"
import { IconButtonWithTooltip } from "./IconButtonWithTooltip.js"
import { FilterFunnelIcon, MoreMenuIcon, XSquareIcon } from "./icons/icons.js"
import { VendorLogo } from "./icons/Logo.js"
import { Link } from "./Link.js"
import OverflownText from "./OverflownText.js"
import { Timestamp } from "./Timestamp.js"

const defaultColumns: BRMPaths<CreditCardListItem>[] = ["display_name", "last_four"]

export default function CardList(
  props: Pick<PostCreditCardV1ListApiArg, "toolId" | "vendorId"> & {
    filterTransactions: (cardId: string) => void
  }
) {
  const intl = useIntl()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const creditCardSchema = useObjectSchema("CreditCard")
  const [deleteCreditCard] = usePostCreditCardV1TerminateByIdMutation()

  useLocalStorageTableParamsSync(CREDIT_CARDS_TABLE_ID)
  const [tableParams, updateTableParams] = useState<TableParamsState<string>>({
    ...TABLE_DEFAULT_PARAMS,
    sorting: [{ id: "display_name", desc: true }],
  })
  const shownColumns = useMemo(
    () => tableParams && shownColumnsForTableParamState(tableParams, defaultColumns),
    [tableParams]
  )

  const apiParams = useMemo(
    () =>
      creditCardSchema && tableParams && packageSortFilterOptionsForAPI<string>(tableParams, creditCardSchema, intl),
    [intl, tableParams, creditCardSchema]
  )

  const { data, isFetching } = usePostCreditCardV1ListQuery(
    apiParams
      ? {
          toolId: props.toolId,
          vendorId: props.vendorId,
          listQueryStringParams: apiParams,
        }
      : skipToken
  )

  if (!tableParams || !creditCardSchema || !shownColumns) {
    return null
  }

  return (
    <Stack width="full" gap={4}>
      <Heading size="xs">
        {data ? (
          <FormattedMessage
            id="creditCard.tab.heading.withCount"
            description="Heading for credit cards overview section"
            defaultMessage="Credit Cards ({total})"
            values={{ total: data.total }}
          />
        ) : (
          <FormattedMessage
            id="transaction.tab.heading"
            description="Heading for credit cards overview section"
            defaultMessage="Credit Cards"
          />
        )}
      </Heading>
      <>
        <TablePageHeader
          tableId={CREDIT_CARDS_TABLE_ID}
          filterMap={tableParams.filterMap}
          onChangeFilters={(filterMap) => updateTableParams({ ...tableParams, filterMap })}
          objectSchema={creditCardSchema}
          selectedColumns={shownColumns}
        />
        <HStack overflowX="auto">
          {isFetching ? (
            <Center width="full">
              <Spinner />
            </Center>
          ) : (
            data?.cards.map((card) => (
              <Card
                key={card.id}
                {...card}
                onTerminate={(id) => deleteCreditCard({ id })}
                filterTransactions={(cardId) => props.filterTransactions(cardId)}
                selected={queryParams.get("card.id") === card.id}
              />
            ))
          )}
        </HStack>
      </>
      {!isFetching && tableParams.filterMap.size === 0 && !data && (
        <EmptyTableState
          emptyState={intl.formatMessage({
            id: "creditCard.tab.emptyState",
            description:
              "Text to display instead of the credit cards table when there are no credit cards for the tool",
            defaultMessage:
              "No credit cards found. This tool is free or your payment method is not integrated with BRM",
          })}
        />
      )}
    </Stack>
  )
}

const Card = ({
  spend_limit,
  spend_limit_interval,
  display_name,
  owner,
  last_four,
  key,
  status,
  last_transaction,
  onTerminate,
  id,
  filterTransactions,
  selected = false,
  vendors,
}: CreditCardListItem & {
  key: string
  onTerminate: (id: string) => void
  filterTransactions: (cardId: string) => void
  selected?: boolean
}) => {
  const intl = useIntl()
  const backgroundColor = status === "active" ? "brand.700" : "gray.500"
  const terminateCardDisclosure = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  const handleTerminate = () => {
    onTerminate(id)
    terminateCardDisclosure.onClose()
  }

  return (
    <>
      <ChakraCard
        key={key}
        borderRadius="3xl"
        width="280px"
        gap={2}
        backgroundColor={backgroundColor}
        textColor="white"
        variant="unstyled"
        height="100%"
        margin={0}
        flexShrink={0}
        _hover={{
          backgroundColor,
        }}
      >
        <Stack padding={4} pb={1}>
          <HStack gap={1}>
            <OverflownText fontWeight="semibold" fontSize="lg">
              {display_name}
            </OverflownText>
            <Spacer />
            <EnumBadge
              value={status?.toString() ?? undefined}
              schema={CreditCardStatusSchema as unknown as EnumTypeSchema<CreditCardStatus>}
            />
            <IconButtonWithTooltip
              variant="unstyled"
              color="white"
              size="sm"
              icon={<Icon as={FilterFunnelIcon} fill={selected ? "white" : undefined} />}
              label={intl.formatMessage({
                id: "creditCard.table.action.filterTransactions.label",
                description: "The ARIA label for the filter transactions button in the credit card table",
                defaultMessage: "Filter transactions",
              })}
              onClick={() => filterTransactions(id)}
            />
            <Menu>
              <MenuButton
                size="sm"
                color="white"
                variant="unstyled"
                as={IconButton}
                aria-label={intl.formatMessage({
                  id: "creditCard.table.action.actions.label",
                  description: "The ARIA label for the actions dropdown button in the credit card table",
                  defaultMessage: "Actions",
                })}
                icon={<Icon as={MoreMenuIcon} boxSize={5} />}
              />
              <Portal>
                <MenuList color="gray.700">
                  <MenuItem
                    isDisabled={status === "terminated"}
                    icon={<Icon as={XSquareIcon} color="gray.500" />}
                    onClick={terminateCardDisclosure.onOpen}
                  >
                    <FormattedMessage
                      defaultMessage="Terminate"
                      description="The terminate label in card menulist"
                      id="creditCard.table.action.terminate"
                    />
                  </MenuItem>
                  {/* TODO: Add update spend limit action functionality */}
                  {/* <MenuItem icon={<Icon as={RefreshIcon} color="gray.500" />}>
                    <FormattedMessage
                      defaultMessage="Update spend limit"
                      description="The update spend limit label in card menulist"
                      id="creditCard.table.action.updateSpendLimit"
                    />
                  </MenuItem> */}
                </MenuList>
              </Portal>
            </Menu>
          </HStack>
          <HStack>
            <Text>
              {status === "terminated" ? last_four : `**** ${last_four}`}
              {owner?.display_name && ` • ${owner.display_name}`}
            </Text>
          </HStack>

          <HStack>
            {spend_limit && spend_limit_interval ? (
              <FormattedMessage
                id="creditCard.table.creditCardSpendLimit"
                defaultMessage="{spendLimit} limit/{spendInterval}"
                description="Spend limit information for the assigned credit card in the workflow run step header"
                values={{
                  spendLimit: (
                    <Text fontSize="lg">
                      <FormattedCurrency currencyAmount={spend_limit} />
                    </Text>
                  ),
                  spendInterval: spend_limit_interval,
                }}
              />
            ) : (
              <FormattedMessage
                id="creditCard.table.noSpendLimit"
                defaultMessage="No spend limit"
                description="Description of no spend limit on credit card"
              />
            )}
          </HStack>
        </Stack>
        <Divider color="white" />
        <HStack padding={4} pt={1} fontSize="xs" width="full">
          {last_transaction?.transacted_at ? (
            <FormattedMessage
              id="creditCard.table.lastCharged"
              defaultMessage="last charge: {timestamp}"
              description="Description of most recent credit card charge"
              values={{
                timestamp: <Timestamp dateTime={last_transaction?.transacted_at} />,
              }}
            />
          ) : (
            <FormattedMessage
              id="creditCard.table.noCharge"
              defaultMessage="No charge posted"
              description="Description of no charges posted on credit card"
            />
          )}
          <Spacer />
          {vendors && vendors.length > 0 && (
            <Tooltip
              label={intl.formatMessage(
                {
                  id: "creditCard.table.chargedBy",
                  defaultMessage: "{count} {count, plural, one {Vendor} other {Vendors}} detected",
                  description: "Tooltip label for the charged by section in the credit card table",
                },
                { count: vendors.length }
              )}
            >
              <AvatarGroup>
                {vendors.slice(0, 3).map((vendor) => {
                  const vendorLogo = getPublicImageGcsUrl(vendor.image_asset?.gcs_file_name)
                  return <VendorLogo key={vendor.id} logo={vendorLogo} />
                })}
              </AvatarGroup>
            </Tooltip>
          )}
        </HStack>
      </ChakraCard>

      <AlertDialog
        isOpen={terminateCardDisclosure.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={terminateCardDisclosure.onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <FormattedMessage
                id="creditCard.terminate.confirmHeader"
                description="Header for credit card termination confirmation dialog"
                defaultMessage="Terminate Credit Card"
              />
            </AlertDialogHeader>

            <AlertDialogBody>
              {vendors && vendors.length > 0 && (
                <Stack spacing={2} mb={4}>
                  <FormattedMessage
                    id="creditCard.terminate.vendorCharges"
                    description="Message showing number of vendor charges found on credit card"
                    defaultMessage="{count} {count, plural, one {Vendor charge} other {Vendor charges}} found on this card:"
                    values={{ count: vendors.length }}
                  />
                  <Stack paddingY={2}>
                    {vendors.slice(0, 5).map((vendor) => (
                      <Link target="_blank" key={vendor.id} to={`/vendors/${vendor.id}`}>
                        <HStack>
                          <VendorLogo logo={getPublicImageGcsUrl(vendor.image_asset?.gcs_file_name)} />
                          <Text>{vendor.display_name}</Text>
                        </HStack>
                      </Link>
                    ))}
                  </Stack>
                  {vendors.length > 5 && (
                    <FormattedMessage
                      id="creditCard.terminate.moreVendorCharges"
                      description="Message showing more vendor charges found on credit card"
                      defaultMessage="And {count} more..."
                      values={{ count: vendors.length - 5 }}
                    />
                  )}
                </Stack>
              )}
              <FormattedMessage
                id="creditCard.terminate.confirmMessage"
                description="Confirmation message for credit card termination"
                defaultMessage="Are you sure you want to terminate this credit card? This action cannot be undone."
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={terminateCardDisclosure.onClose}>
                <FormattedMessage
                  id="creditCard.terminate.cancel"
                  description="Cancel button text for credit card termination"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button colorScheme="red" onClick={handleTerminate} ml={3}>
                <FormattedMessage
                  id="creditCard.terminate.confirm"
                  description="Confirm button text for credit card termination"
                  defaultMessage="Terminate"
                />
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
