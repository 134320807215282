import type { FieldMetadata, FieldMetadataWithSuggestions } from "@brm/schema-types/types.js"
import { formatDuration } from "@brm/util/format-date-time.js"
import type { FormControlProps, StyleProps } from "@chakra-ui/react"
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { Select, type GroupBase } from "chakra-react-select"
import { forwardRef, useImperativeHandle, useMemo, useRef, useState, type ReactNode, type Ref } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { isNewOption } from "../../util/form.js"
import OptionWithFieldSource from "../DynamicForm/OptionWithFieldSource.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "../DynamicForm/types.js"
import { IconButtonWithTooltip } from "../IconButtonWithTooltip.js"
import { XIcon } from "../icons/icons.js"
import type { DurationUnit } from "./duration-unit.js"

const unitDisplay = "long"

type DurationFormControlProps = FormControlProps &
  DurationInputGroupProps & {
    legend: ReactNode
    legendProps?: StyleProps
    inputGroupProps?: StyleProps
    errorMessage?: ReactNode
  }

/**
 * A simple input field for ISO8601 durations, allowing to input one value and selecting one unit.
 * All other units will be zero.
 * Customize the units that are allowed by passing a constant from `duration-unit.ts` as the `units` prop.
 */

export const DurationFormControl = forwardRef<HTMLInputElement, DurationFormControlProps>(function DurationFormControl(
  {
    legend,
    legendProps,
    inputGroupProps,
    errorMessage,
    isReadOnly = false,
    value,
    onChange,
    units,
    ...formControlProps
  }: DurationFormControlProps,
  ref
) {
  return (
    <FormControl as="fieldset" isReadOnly={isReadOnly} width="auto" {...formControlProps}>
      <FormLabel as="legend" {...legendProps}>
        {legend}
      </FormLabel>
      <DurationInputGroup
        ref={ref}
        value={value}
        onChange={onChange}
        isReadOnly={isReadOnly}
        units={units}
        {...inputGroupProps}
      />
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  )
})

export type DurationInputOption = ValueWithSource<Temporal.Duration | null | undefined> & {
  label?: string
}

// Internal type for the "Other" option that opens the custom duration input but does not provide a value by itself
interface DurationInputOtherOption {
  label: string
}

export interface DurationInputGroupProps extends StyleProps {
  value: Temporal.DurationLike | string | null | undefined
  units: DurationUnit[]
  onChange: (value: Temporal.Duration | null, fieldSource?: FieldMetadata) => void
  isReadOnly?: boolean
  suggestions?: DurationInputOption[]
  isDisabled?: boolean
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: DynamicFormFieldApproval
  durationShortcuts?: DurationInputOption[]
}

/** Arbitrary limit to avoid overflowing into Infinity/MAX_SAFE_INTEGER or trigger exponential notation */
const MAX = 1000
const MIN = 0

const isEqual = (duration1: Temporal.Duration | null | undefined, duration2: Temporal.Duration | null | undefined) => {
  if (!duration1 || !duration2) {
    return duration1 === duration2
  }
  return Temporal.Duration.compare(duration1, duration2, { relativeTo: Temporal.Now.plainDateISO() }) === 0
}

/**
 * A simple input field for ISO8601 durations, allowing to input one value and selecting one unit.
 * All other units will be zero.
 * Customize the units that are allowed by passing a constant from `duration-unit.ts` as the `units` prop.
 *
 * In most cases you should use {@link DurationFormControl} to render the label correctly as a legend.
 */
export const DurationInputGroup = forwardRef(function DurationInputGroup(
  props: DurationInputGroupProps,
  ref: Ref<HTMLInputElement | null>
) {
  const intl = useIntl()
  const {
    value,
    onChange,
    isReadOnly = false,
    suggestions,
    isDisabled,
    fieldMetadata,
    fieldApproval,
    durationShortcuts,
    ...rest
  } = props

  const duration = value ? Temporal.Duration.from(value) : value === null ? value : undefined
  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  const selectedOption =
    suggestions?.find((option) => isEqual(option.value, duration)) ??
    durationShortcuts?.find((option) => isEqual(option.value, duration))
  const selectedValue = {
    value: duration,
    label:
      (selectedOption?.label ||
        (duration &&
          formatDuration(intl, duration, {
            unitDisplay,
          }))) ??
      "",
  }
  // A frequency value is set but it is not one of the preset options
  const isCustomValue = Boolean(duration && !selectedOption)
  const [showCustomInputs, setShowCustomInputs] = useState(false)

  const options: (DurationInputOption | GroupBase<DurationInputOption> | DurationInputOtherOption)[] = useMemo(() => {
    const extractedOptions = suggestions ?? []
    const standardOptions = [
      ...(durationShortcuts?.filter(
        (option) =>
          !suggestions?.some((suggestedOption) => option.value?.toString() === suggestedOption.value?.toString())
      ) || []),
      {
        label: intl.formatMessage({
          defaultMessage: "Other",
          description: "Label for the other option",
          id: "components.Form.DurationInput.other",
        }),
      },
    ]

    // If there's only the standard options section, return flat array without groups
    if (extractedOptions.length === 0) {
      return standardOptions
    }

    // Otherwise return grouped options
    return [
      {
        options: extractedOptions,
        label: intl.formatMessage({
          defaultMessage: "Extracted Options",
          description: "Label for extracted options",
          id: "components.Form.DurationInput.optgroup.extracted",
        }),
      },
      {
        label: intl.formatMessage({
          defaultMessage: "Standard Options",
          description: "Label for predefined options",
          id: "components.Form.DurationInput.optgroup.standard",
        }),
        options: standardOptions,
      },
    ]
  }, [intl, durationShortcuts, suggestions])

  // If there are no shortcuts & suggested options, only use custom duration input
  if ((!suggestions || suggestions.length === 0) && (!durationShortcuts || durationShortcuts.length === 0)) {
    return <CustomDurationInput duration={duration} {...props} />
  }

  return (
    <Stack>
      <Flex alignItems="center" gap={1} flexBasis="min-content" flexGrow={0} {...rest}>
        <Select<DurationInputOption | DurationInputOtherOption>
          ref={(select) => {
            inputRef.current = select?.inputRef ?? null
          }}
          openMenuOnFocus={true}
          value={selectedValue}
          options={options}
          isDisabled={isDisabled}
          placeholder={intl.formatMessage({
            id: "form.select.placeholder",
            defaultMessage: "Select an option...",
            description: "Placeholder for selection input",
          })}
          formatOptionLabel={(option, { context }) => {
            if (context === "menu" && "field_sources" in option) {
              return <OptionWithFieldSource fieldSources={option.field_sources}>{option.label}</OptionWithFieldSource>
            }
            return option.label
          }}
          isOptionSelected={(option, [selectedOption]) =>
            selectedOption && "value" in selectedOption && "value" in option
              ? isEqual(option.value, selectedOption.value)
              : isCustomValue
          }
          isReadOnly={isReadOnly}
          isSearchable={false}
          onChange={(option) => {
            if (option && "value" in option) {
              const optionFieldSource = option.field_sources?.[0]
              onChange(option?.value ?? null, optionFieldSource)
              setShowCustomInputs(false)
            } else {
              setShowCustomInputs(true)
            }
          }}
          styles={{
            menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
          }}
          chakraStyles={{
            container: (styles) => ({ ...styles, flexGrow: 1, minWidth: "7em" }),
            option: (provided, { data }) => {
              const newOption =
                "field_sources" in data ? isNewOption(data.field_sources, fieldMetadata, fieldApproval) : undefined
              return {
                ...provided,
                ...(newOption?.isNew &&
                  newOption?.colorScheme && {
                    backgroundColor: `${newOption.colorScheme}.50`,
                  }),
              }
            },
          }}
          menuPortalTarget={document.body}
        />
      </Flex>
      {showCustomInputs && (
        <Stack gap={2}>
          <Text color="gray.700" fontSize="sm" fontWeight="medium">
            <FormattedMessage
              defaultMessage="Other (please specify)"
              description="Label for a custom duration input field"
              id="components.Form.DurationInput.other"
            />
          </Text>
          <CustomDurationInput duration={duration} {...props} />
        </Stack>
      )}
    </Stack>
  )
})

interface DurationNumberInputProps {
  value: { year: number; month: number; day: number } | null
  onChange: (value: Temporal.Duration | null, fieldSource?: FieldMetadata) => void
  isDisabled?: boolean
  isReadOnly?: boolean
  unit: "year" | "month" | "day"
  suggestions?: Array<{
    value: Temporal.Duration | null | undefined
    field_sources?: FieldMetadata[]
  }>
  label: string
}

const DurationNumberInput: React.FC<DurationNumberInputProps> = ({
  value,
  onChange,
  isDisabled = false,
  isReadOnly = false,
  unit,
  suggestions,
  label,
}) => {
  const max = unit === "year" ? MAX : unit === "month" ? 120 : 365

  const handleChange = (_: string, partialInput: number) => {
    const valueAsNumber = isNaN(partialInput) ? 0 : partialInput
    if (valueAsNumber < MIN || valueAsNumber > max) {
      return
    }
    const newVal = Temporal.Duration.from({
      months: value?.month ?? 0,
      days: value?.day ?? 0,
      years: value?.year ?? 0,
      [`${unit}s`]: valueAsNumber,
    })
    const matchingSuggestion = suggestions?.find(
      (suggestion) => suggestion.value && Temporal.Duration.compare(suggestion.value, newVal) === 0
    )
    onChange(newVal, matchingSuggestion?.field_sources?.[0])
  }

  return (
    <Stack gap={0} flex={1}>
      <FormLabel>{label}</FormLabel>
      <NumberInput
        isDisabled={isDisabled}
        value={value?.[unit] ?? ""}
        onChange={handleChange}
        isReadOnly={isReadOnly}
        step={1}
        min={MIN}
        max={max}
        flexGrow={0}
      >
        <NumberInputField
          p={1}
          textAlign="right"
          pr={8}
          inputMode="numeric"
          pattern="[0-9]*"
          minWidth="calc(2ch + var(--number-input-input-padding) + 0.5rem)"
          placeholder="0"
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Stack>
  )
}

export const CustomDurationInput = (
  props: Omit<DurationInputGroupProps, "value"> & {
    duration: Temporal.Duration | null | undefined
    suggestions?: DurationInputOption[]
  }
) => {
  const intl = useIntl()
  const {
    duration,
    onChange,
    isReadOnly = false,
    isDisabled = false,
    suggestions,
    fieldMetadata: _fieldMetadata,
    fieldApproval: _fieldApproval,
    ...rest
  } = props
  const valueInParts = duration ? { year: duration.years, month: duration.months, day: duration.days } : null

  return (
    <InputGroup alignItems="end" gap={1} flexGrow={1} {...rest}>
      <DurationNumberInput
        value={valueInParts}
        onChange={onChange}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        unit="year"
        suggestions={suggestions}
        label={intl.formatMessage({
          defaultMessage: "Years",
          description: "Label for a duration input field",
          id: "components.Form.DurationInput.years",
        })}
      />
      <DurationNumberInput
        value={valueInParts}
        onChange={onChange}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        unit="month"
        suggestions={suggestions}
        label={intl.formatMessage({
          defaultMessage: "Months",
          description: "Label for a duration input field",
          id: "components.Form.DurationInput.months",
        })}
      />
      <DurationNumberInput
        value={valueInParts}
        onChange={onChange}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        unit="day"
        suggestions={suggestions}
        label={intl.formatMessage({
          defaultMessage: "Days",
          description: "Label for a duration input field",
          id: "components.Form.DurationInput.days",
        })}
      />
      <IconButtonWithTooltip
        variant="ghost"
        onClick={() => onChange(null)}
        icon={<Icon as={XIcon} />}
        label={intl.formatMessage({
          defaultMessage: "Clear",
          description: "Label for the clear button",
          id: "components.Form.DurationInput.clear",
        })}
      />
    </InputGroup>
  )
}
